body {
  font-family: "Open Sans",sans-serif;
  position: relative;
  background: #fff;
  font-size: 14px;
  overflow-x: hidden;
}

h1 {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
}

h1 span {
  font-size: 107px;
  font-weight: 700;
  color: #309345;
}

h2 {
  font-size: 36px;
  color: #222;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  letter-spacing: 0.03em;
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #222;
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  line-height: 24px;
}

header ul,
footer ul {
  padding-left: 0;
  margin-bottom: 0;
}

header li,
footer li {
  display: inline-block;
}

p {
  color: #333;
}

a {
  transition: 0.5s ease;
  color: #309345;
}

a:hover {
  color: #309345;
  text-decoration: none;
  transition: 0.5s ease;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button:focus {
  outline: none;
}

.white {
  background-color: #fff;
  border: 1px solid #aaa;
}

.blue {
  background-color: #4695b1;
}

.pink {
  background-color: pink;
}

.yellow {
  background-color: #fdf791;
}

.red {
  background-color: #b65d60;
}

.gray {
  background-color: #bababa;
}

.green {
  background-color: #7ee768;
}

.orange {
  background-color: rgba(230,184,71,0.92);
}

.black {
  background-color: #1b1b1b;
}

.section-t-space {
  padding-top: 70px;
}

.section-b-space {
  padding-bottom: 70px;
}

.p-t-0 {
  padding-top: 0;
}

hr.style1 {
  width: 75px;
  height: 3px;
  margin-top: 13px;
  background-color: #309345;
  text-align: center;
}

.no-arrow .slick-next,
.no-arrow .slick-prev {
  display: none !important;
}

.icon-angle-left:before {
  content: "\E64A";
}

.form-control {
  border-radius: 0;
}

.small-section {
  padding-top: 35px;
  padding-bottom: 35px;
}

.banner-padding {
  padding-top: 30px;
}

.border-section {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.border-b {
  border-bottom: 1px solid #38352f;
}

.border-bottom-grey {
  border-bottom: 1px solid #efefef;
}

.border-top-grey {
  border-top: 1px solid #efefef;
}

.darken-layout {
  background-color: #393230;
}

.dark-layout {
  background-color: #2d2a25;
}

.light-layout {
  background-color: #f9f9f9;
}

.white-layout {
  background-color: #fff;
}

.bg-light0 {
  background-color: #d0edff;
}

.bg-light1 {
  background-color: #f1e7e6;
}

.bg-light2 {
  background-color: #bfbfbf;
}

.bg-blog {
  background-color: #eee;
}

.Toastify__toast {
  min-height: 36px;
}

.input-range__track--active {
  background: #309345 !important;
}

.input-range__slider {
  background: #309345 !important;
  border: 1px solid #309345 !important;
}

.block-price-content .custom-control {
  padding-right: 1.5rem;
  padding-bottom: 18px;
}

del {
  font-size: 14px;
  color: #aaa;
  font-weight: 400;
}

.sidenav .sidebar-back {
  display: flex;
  align-items: center;
}

.srv-validation-message {
  color: red;
}

.sticky.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 300ms ease;
  background: #fff;
  box-shadow: 0px 0px 8px #ddd;
  -webkit-animation: smoothScroll 1s forwards;
          animation: smoothScroll 1s forwards;
}

.sticky.fixed .brand-logo {
  padding-top: 15px;
  padding-bottom: 15px;
}

.sticky.fixed .onhover-div .show-div {
  top: 66px;
}

.sticky.fixed .top-header {
  display: none;
}

.sticky.fixed .cart-qty-cls {
  top: 13%;
  right: -11px;
}

@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.shopping-cart::-webkit-scrollbar {
  width: 3px;
}

.shopping-cart::-webkit-scrollbar-track {
  background: #ddd;
}

.shopping-cart::-webkit-scrollbar-thumb {
  background: #309345;
  border-radius: 10px;
}

.shopping-cart::-webkit-scrollbar-thumb:hover {
  background: #555;
}

[data-notify="progressbar"] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
}

.progress-bar {
  background-color: #19a340;
}

.progress-bar-info {
  background-color: #00829a;
}

.ratio_40 .bg-size:before {
  padding-top: 40%;
  content: "";
  display: block;
}

.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: "";
  display: block;
}

.ratio2_1 .bg-size:before {
  padding-top: 50%;
  content: "";
  display: block;
}

.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: "";
  display: block;
}

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: "";
  display: block;
}

.ratio_landscape .bg-size:before {
  padding-top: 75%;
  content: "";
  display: block;
}

.ratio_square .bg-size:before {
  padding-top: 100%;
  content: "";
  display: block;
}

.ratio_asos .bg-size:before {
  padding-top: 127.7777778%;
  content: "";
  display: block;
}

.ratio_portrait .bg-size:before {
  padding-top: 150%;
  content: "";
  display: block;
}

.ratio1_2 .bg-size:before {
  padding-top: 200%;
  content: "";
  display: block;
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b_size_content {
  background-size: contain !important;
  background-repeat: no-repeat;
}

button {
  cursor: pointer;
}

.btn {
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
  transition: .3s ease-in-out;
}

.btn:hover {
  transition: .3s ease-in-out;
}

.btn:focus {
  box-shadow: none;
}

.btn-solid {
  padding: 13px 29px;
  color: #fff !important;
  letter-spacing: 0.05em;
  border: 2px solid #309345;
  background-image: linear-gradient(30deg, #309345 50%, transparent 50%);
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: 0;
  transition: background 300ms ease-in-out;
}

.btn-solid:hover {
  background-position: 100%;
  color: #000 !important;
  background-color: #fff;
}

.btn-solid.black-btn {
  background-image: linear-gradient(30deg, #222 50%, transparent 50%);
  border: 2px solid #222;
}

.btn-solid:focus {
  color: #fff;
}

.btn-outline {
  display: inline-block;
  padding: 13px 29px;
  letter-spacing: 0.05em;
  border: 2px solid #309345;
  position: relative;
  color: #000;
}

.btn-outline:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #309345;
  z-index: -2;
}

.btn-outline:hover {
  color: #fff;
}

.btn-outline:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.btn-outline:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

button.btn.btn-solid:active,
button.btn.btn-outline:active {
  background-image: linear-gradient(30deg, #309345 50%, transparent 50%);
  color: #fff;
  background: #309345;
}

.btn-classic:hover {
  background-color: #309345;
}

.btn-theme {
  background-color: #309345;
  color: #fff;
}

.title1 {
  text-align: center;
}

.title1 h4 {
  color: #309345;
  padding-bottom: 10px;
  margin-bottom: 0;
  margin-top: -3px;
}

.title1 .title-inner1 {
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative;
}

.title1 .title-inner1:after {
  position: absolute;
  height: 5px;
  width: 70px;
  background-color: #309345;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}

.title1.title5 .title-inner1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.title1.title5 .title-inner1:after {
  display: none;
}

.title1.title5 hr[role="tournament6"] {
  border: 0px solid;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(48,147,69,0.75), rgba(0,0,0,0));
  display: block;
  width: 25%;
  margin: 15px auto 30px auto;
}

.title1.title5 hr[role="tournament6"]::before {
  position: absolute;
  background-color: #efefef;
  border: 1px solid;
  border-color: #309345;
  padding: 5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin: -6px 0px 0px 0;
  content: "";
}

.title2 {
  text-align: center;
}

.title2 h4 {
  color: #309345;
  padding-bottom: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: -3px;
}

.title2 .title-inner2 {
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
  padding: 0 25px;
}

.title2 .title-inner2:after {
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #3db957;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 3px;
  z-index: -1;
}

.title3 {
  text-align: center;
}

.title3 h4 {
  color: #309345;
  padding-bottom: 10px;
  margin-bottom: 5px;
  margin-top: -3px;
}

.title3 .title-inner3 {
  margin-bottom: 20px;
  position: relative;
  margin-top: -5px;
}

.title3 .line {
  position: relative;
  height: 5px;
  width: 45px;
  background-color: #309345;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  margin-bottom: 50px;
}

.title3 .line:after,
.title3 .line:before {
  position: absolute;
  height: 5px;
  width: 10px;
  content: "";
  background-color: #309345;
}

.title3 .line:after {
  right: 50px;
}

.title3 .line:before {
  left: 50px;
}

.title4 {
  text-align: center;
}

.title4 .title-inner4 {
  padding-bottom: 25px;
  margin-bottom: 0;
  position: relative;
  margin-top: -5px;
}

.title4 .line {
  position: relative;
  height: 1px;
  width: 180px;
  background-color: #309345;
  margin: 0 auto;
  margin-bottom: 55px;
  bottom: 0;
  top: 0;
}

.title4 .line:after,
.title4 .line:before {
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  background-color: #309345;
  top: -4px;
}

.title4 .line:after {
  left: 78px;
}

.title4 .line:before {
  left: 64px;
}

.title4 .line span:after,
.title4 .line span:before {
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  background-color: #309345;
  top: -4px;
}

.title4 .line span:after {
  right: 78px;
}

.title4 .line span:before {
  right: 64px;
}

.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px;
}

.logo-block a:focus {
  outline: none;
}

.logo-block img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.5;
  transition: .5s ease;
  margin: 0 auto;
}

.logo-block img:focus {
  outline: none;
}

.logo-block img:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
  transition: .5s ease;
}

header {
  background-color: #fff;
}

.onhover-dropdown {
  position: relative;
  font-size: 14px;
}

.onhover-dropdown:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 3px;
}

.onhover-dropdown .onhover-show-div {
  top: 50px;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  transition: all linear 0.3s;
  min-width: 160px;
  text-align: left;
  box-shadow: 0 0 1px 0 #ebebeb;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 15px 20px;
  border-radius: 0 !important;
}

.onhover-dropdown .onhover-show-div.product-page-full {
  z-index: 99999;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  visibility: visible;
}

.onhover-div {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Open Sans",sans-serif;
}

.onhover-div img,
.onhover-div i {
  cursor: pointer;
}

.onhover-div .show-div {
  top: 103px;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  transition: all linear 0.3s;
  min-width: 236px;
  text-align: left;
  box-shadow: 0 0 1px 0 #ebebeb;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 0;
  border-radius: 0 !important;
}

.onhover-div:hover .show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  visibility: visible;
}

.search-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #fff;
}

.search-overlay>div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-overlay>div .overlay-content {
  width: 100%;
}

.search-overlay>div .overlay-content .form-control:focus {
  box-shadow: none;
}

.search-overlay>div .overlay-content input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #777;
  border-radius: 0;
  padding: 20px 0;
}

.search-overlay>div .overlay-content input::-webkit-input-placeholder {
  color: #777;
  font-size: 18px;
}

.search-overlay>div .overlay-content input::-moz-placeholder {
  color: #777;
  font-size: 18px;
}

.search-overlay>div .overlay-content input:-ms-input-placeholder {
  color: #777;
  font-size: 18px;
}

.search-overlay>div .overlay-content input:-moz-placeholder {
  color: #777;
  font-size: 18px;
}

.search-overlay>div .overlay-content button {
  position: absolute;
  top: 10px;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  color: #777;
  right: 10px;
}

.search-overlay>div .closebtn {
  position: fixed;
  top: 10px;
  right: 25px;
  color: #777;
  font-size: 40px;
  cursor: pointer;
}

.top-header {
  background-color: #f8f8f8;
}

.top-header .header-contact {
  padding: 15px 0;
}

.top-header .header-contact li {
  color: #999;
  font-size: 14px;
  padding-right: 25px;
}

.top-header .header-contact li i {
  color: #309345;
  padding-right: 10px;
}

.top-header .header-dropdown li {
  cursor: pointer;
  color: #333;
  padding: 15px 25px;
  padding-right: 20px;
  font-size: 14px;
}

.top-header .header-dropdown li:first-child {
  padding-right: 0;
}

r .top-header .header-dropdown li:nth-child(2) {
  padding-right: 25px;
}

.top-header .header-dropdown li a {
  text-transform: capitalize;
  color: #333;
}

.top-header .header-dropdown li i {
  padding-right: 7px;
  transition: all 0.5s ease;
}

.top-header .header-dropdown li ul li {
  padding-top: 0;
  padding-bottom: 0;
}

.top-header .header-dropdown li a i {
  color: #333;
  transition: all 0.5s ease;
}

.top-header .header-dropdown li a:hover i {
  color: #309345;
  transition: all 0.5s ease;
}

.top-header .header-dropdown li:hover i {
  color: #309345;
  transition: all 0.5s ease;
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li {
  padding-left: 0;
  display: flex;
  font-size: 15px;
  padding-bottom: 5px;
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li:last-child {
  padding-bottom: 0;
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #333;
}

.top-header.top-header-dark {
  background-color: #222;
}

.top-header.top-header-dark .header-contact li {
  color: #777;
}

.top-header.top-header-dark .header-contact li i {
  color: #777;
}

.top-header.top-header-dark .header-dropdown li {
  color: #777;
}

.top-header.top-header-dark .header-dropdown li a {
  color: #777;
}

.top-header.top-header-dark .header-dropdown li a i {
  color: #777;
}

.top-header.top-header-dark .header-dropdown li a:hover i {
  color: #309345;
}

.top-header.top-header-dark2 {
  background-color: #2d2a25;
}

.top-header.top-header-dark2 .header-contact li {
  color: #7f786d;
}

.top-header.top-header-dark2 .header-contact li i {
  color: #7f786d;
}

.top-header.top-header-dark2 .header-dropdown li {
  color: #7f786d;
}

.top-header.top-header-dark2 .header-dropdown li a {
  color: #7f786d;
}

.top-header.top-header-dark2 .header-dropdown li a i {
  color: #7f786d;
}

.top-header.top-header-dark2 .header-dropdown li a:hover i {
  color: #309345;
}

.top-header.top-header-dark3 {
  background-color: #393230;
}

.top-header.top-header-dark3 .header-contact li {
  color: #948e8c;
}

.top-header.top-header-dark3 .header-contact li i {
  color: #948e8c;
}

.top-header.top-header-dark3 .header-dropdown li {
  color: #948e8c;
}

.top-header.top-header-dark3 .header-dropdown li a {
  color: #948e8c;
}

.top-header.top-header-dark3 .header-dropdown li a i {
  color: #948e8c;
}

.top-header.top-header-dark3 .header-dropdown li a:hover i {
  color: #309345;
}

.layout3-menu .main-menu .menu-left .navbar i {
  font-size: 22px;
}

.main-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
}

.main-menu.product-full-page {
  z-index: 9999;
}

.main-menu .brand-logo {
  display: inline-block;
  padding-top: 35px;
  padding-bottom: 35px;
}

.main-menu .menu-left {
  display: flex;
  align-items: center;
}

.main-menu .menu-left .navbar {
  display: inline-block;
  padding: 40px 45px 40px 0;
}

.main-menu .menu-left .navbar i {
  font-size: 28px;
  color: #222;
  cursor: pointer;
}

.main-menu .menu-left .navbar.white-navbar i {
  color: #fff;
}

.main-menu .menu-left.around-border .navbar>a {
  border: 2px solid #98dda7;
  padding: 11px 10px 6px;
}

.main-menu .menu-left.category-nav-right .navbar {
  padding-right: 0;
  padding-left: 45px;
}

.main-menu .menu-left.category-nav-right .navbar>a {
  padding: 0;
}

.main-menu .menu-left.category-nav-right .navbar i {
  color: #938d8c;
}

.main-menu .menu-right {
  display: flex;
  float: right;
}

.main-menu .menu-right .icon-nav {
  display: inline-block;
}

.main-menu .menu-right .icon-nav li {
  padding-left: 20px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart {
  padding: 15px 15px;
  min-width: 270px;
  left: unset;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li {
  position: relative;
  padding-bottom: 10px;
  width: 100%;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li:last-child {
  padding-bottom: 0;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img {
  height: 90px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body {
  align-self: center;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #333;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
  color: #777;
  font-size: 16px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
  position: absolute;
  top: 0;
  right: 0;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i {
  color: #a1a1a1;
  transition: all 0.3s ease;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #000;
  transition: all 0.3s ease;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-top: 1px solid #f1f5f4;
  border-bottom: 1px solid #f1f5f4;
  padding-top: 10px;
  padding-bottom: 10px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #999;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 span {
  float: right;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  font-size: 16px;
  color: #333;
  text-transform: capitalize;
  font-weight: 700;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
  color: #309345;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons .checkout {
  float: right;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
  padding: 15px 20px 20px;
  min-width: 175px;
  right: 0;
  left: unset;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  font-size: 16px;
  text-transform: capitalize;
  color: #333;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6:first-child {
  margin-top: 0;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li {
  display: flex;
  padding-bottom: 0;
  padding-left: 17px;
  padding-top: 5px;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li {
  padding: 0;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control {
  border-radius: 0;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li .search-btn {
  padding: 8px 12px;
  background-color: #309345;
  color: #fff;
}

.main-menu .menu-right .icon-nav .onhover-div .show-div ul li a {
  cursor: pointer;
}

.main-menu .menu-right .icon-nav.white-icon .onhover-div img {
  -webkit-filter: brightness(5);
          filter: brightness(5);
}

.main-menu .menu-right .icon-nav.white-icon .onhover-div .show-div img {
  -webkit-filter: none;
          filter: none;
}

.nav-cat li {
  position: relative;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
}

.nav-cat li .round-cat {
  height: 50px;
  width: 50px;
  border: 1px solid #dddddd;
  padding: 12px;
  border-radius: 100%;
  margin-right: 10px;
}

.nav-cat li a {
  color: #222;
  font-size: 16px;
  text-transform: uppercase;
}

.Toastify__toast--info {
  background: #007bff;
}

.Toastify__toast--success {
  background: #22af47;
}

.Toastify__toast--warning {
  background: #ff850d;
}

.Toastify__toast--error {
  background: #ff2046;
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.home-slider .slick-prev,
.home-slider .slick-next,
.center-slider .slick-prev,
.center-slider .slick-next {
  opacity: 0;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.5s ease;
  margin-top: -10px;
}

.home-slider .slick-prev:before,
.home-slider .slick-next:before,
.center-slider .slick-prev:before,
.center-slider .slick-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  opacity: 1;
  color: black;
  background-color: rgba(255,255,255,0.5);
  border-radius: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-slider .slick-next,
.center-slider .slick-next {
  right: 1px;
}

.home-slider .slick-next:before,
.center-slider .slick-next:before {
  content: "\f105";
}

.home-slider .slick-prev,
.center-slider .slick-prev {
  left: 1px;
  z-index: 1;
}

.home-slider .slick-prev:before,
.center-slider .slick-prev:before {
  content: "\f104";
}

.home-slider .home,
.center-slider .home {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 75vh;
}

.home-slider .home.home1,
.center-slider .home.home1 {
  background-image: url("/assets/images/home-banner/1.jpg");
}

.home-slider .home.home2,
.center-slider .home.home2 {
  background-image: url("/assets/images/home-banner/2.jpg");
}

.home-slider .home.home3,
.center-slider .home.home3 {
  background-image: url("/assets/images/home-banner/3.jpg");
}

.home-slider .home.home4,
.center-slider .home.home4 {
  background-image: url("/assets/images/home-banner/4.jpg");
}

.home-slider .home.home5,
.center-slider .home.home5 {
  background-image: url("/assets/images/home-banner/5.jpg");
}

.home-slider .home.home6,
.center-slider .home.home6 {
  background-image: url("/assets/images/home-banner/6.jpg");
}

.home-slider .home.home7,
.center-slider .home.home7 {
  background-image: url("/assets/images/home-banner/7.jpg");
}

.home-slider .home.home8,
.center-slider .home.home8 {
  background-image: url("/assets/images/home-banner/8.jpg");
}

.home-slider .home.home9,
.center-slider .home.home9 {
  background-image: url("/assets/images/home-banner/9.jpg");
}

.home-slider .home.home10,
.center-slider .home.home10 {
  background-image: url("/assets/images/home-banner/10.jpg");
}

.home-slider .home.home11,
.center-slider .home.home11 {
  background-image: url("/assets/images/home-banner/11.jpg");
}

.home-slider .home.home12,
.center-slider .home.home12 {
  background-image: url("/assets/images/home-banner/12.jpg");
}

.home-slider .home.home13,
.center-slider .home.home13 {
  background-image: url("/assets/images/home-banner/13.jpg");
}

.home-slider .home.home14,
.center-slider .home.home14 {
  background-image: url("/assets/images/home-banner/14.jpg");
}

.home-slider .home.home15,
.center-slider .home.home15 {
  background-image: url("/assets/images/home-banner/15.jpg");
}

.home-slider .home.home16,
.center-slider .home.home16 {
  background-image: url("/assets/images/home-banner/16.jpg");
}

.home-slider .home.home17,
.center-slider .home.home17 {
  background-image: url("/assets/images/home-banner/17.jpg");
}

.home-slider .home.home18,
.center-slider .home.home18 {
  background-image: url("/assets/images/home-banner/18.jpg");
}

.home-slider .home.home19,
.center-slider .home.home19 {
  background-image: url("/assets/images/home-banner/19.jpg");
}

.home-slider .home.home20,
.center-slider .home.home20 {
  background-image: url("/assets/images/home-banner/20.jpg");
}

.home-slider .home.home21,
.center-slider .home.home21 {
  background-image: url("/assets/images/home-banner/21.jpg");
}

.home-slider .home.home22,
.center-slider .home.home22 {
  background-image: url("/assets/images/home-banner/22.jpg");
}

.home-slider .home.home23,
.center-slider .home.home23 {
  background-image: url("/assets/images/home-banner/23.jpg");
}

.home-slider .home.home24,
.center-slider .home.home24 {
  background-image: url("/assets/images/home-banner/24.jpg");
}

.home-slider .home.home25,
.center-slider .home.home25 {
  background-image: url("/assets/images/home-banner/25.jpg");
}

.home-slider .home.home26,
.center-slider .home.home26 {
  background-image: url("/assets/images/home-banner/26.jpg");
}

.home-slider .home.home27,
.center-slider .home.home27 {
  background-image: url("/assets/images/home-banner/27.jpg");
}

.home-slider .home.home28,
.center-slider .home.home28 {
  background-image: url("/assets/images/home-banner/28.jpg");
}

.home-slider .home.home29,
.center-slider .home.home29 {
  background-image: url("/assets/images/home-banner/29.jpg");
}

.home-slider .home.home30,
.center-slider .home.home30 {
  background-image: url("/assets/images/home-banner/30.jpg");
}

.home-slider .home.home31,
.center-slider .home.home31 {
  background-image: url("/assets/images/home-banner/31.jpg");
}

.home-slider .home.home32,
.center-slider .home.home32 {
  background-image: url("/assets/images/home-banner/32.jpg");
}

.home-slider .home.home33,
.center-slider .home.home33 {
  background-image: url("/assets/images/home-banner/33.jpg");
}

.home-slider .home.home34,
.center-slider .home.home34 {
  background-image: url("/assets/images/home-banner/34.jpg");
}

.home-slider .home.home35,
.center-slider .home.home35 {
  background-image: url("/assets/images/home-banner/35.jpg");
}

.home-slider .home.home36,
.center-slider .home.home36 {
  background-image: url("/assets/images/home-banner/36.jpg");
}

.home-slider .home.home37,
.center-slider .home.home37 {
  background-image: url("/assets/images/home-banner/37.jpg");
}

.home-slider .home.home38,
.center-slider .home.home38 {
  background-image: url("/assets/images/home-banner/38.jpg");
}

.home-slider .home.home39,
.center-slider .home.home39 {
  background-image: url("/assets/images/home-banner/39.jpg");
}

.home-slider .home.home40,
.center-slider .home.home40 {
  background-image: url("/assets/images/home-banner/40.jpg");
}

.home-slider .home.home41,
.center-slider .home.home41 {
  background-image: url("/assets/images/home-banner/41.jpg");
}

.home-slider .home.home42,
.center-slider .home.home42 {
  background-image: url("/assets/images/home-banner/42.jpg");
}

.home-slider .home.home43,
.center-slider .home.home43 {
  background-image: url("/assets/images/home-banner/43.jpg");
}

.home-slider .home.home44,
.center-slider .home.home44 {
  background-image: url("/assets/images/home-banner/44.jpg");
}

.home-slider .home.home45,
.center-slider .home.home45 {
  background-image: url("/assets/images/home-banner/45.jpg");
}

.home-slider .home.home46,
.center-slider .home.home46 {
  background-image: url("/assets/images/home-banner/46.jpg");
}

.home-slider .home.home47,
.center-slider .home.home47 {
  background-image: url("/assets/images/home-banner/47.jpg");
}

.home-slider .home.home48,
.center-slider .home.home48 {
  background-image: url("/assets/images/home-banner/48.jpg");
}

.home-slider .home.home49,
.center-slider .home.home49 {
  background-image: url("/assets/images/home-banner/49.jpg");
}

.home-slider .home.home50,
.center-slider .home.home50 {
  background-image: url("/assets/images/home-banner/50.jpg");
}

.home-slider .home.home51,
.center-slider .home.home51 {
  background-image: url("/assets/images/home-banner/51.jpg");
}

.home-slider .home.home52,
.center-slider .home.home52 {
  background-image: url("/assets/images/home-banner/52.jpg");
}

.home-slider .home.home53,
.center-slider .home.home53 {
  background-image: url("/assets/images/home-banner/53.jpg");
}

.home-slider .home.home54,
.center-slider .home.home54 {
  background-image: url("/assets/images/home-banner/54.jpg");
}

.home-slider .home.home55,
.center-slider .home.home55 {
  background-image: url("/assets/images/home-banner/55.jpg");
}

.home-slider .home.home56,
.center-slider .home.home56 {
  background-image: url("/assets/images/home-banner/56.jpg");
}

.home-slider .slider-contain,
.center-slider .slider-contain {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
}

.home-slider .slider-contain h1,
.center-slider .slider-contain h1 {
  margin-bottom: 0;
  margin-top: 10px;
  color: #222;
}

.home-slider .slider-contain h4,
.center-slider .slider-contain h4 {
  font-weight: 700;
  letter-spacing: 0.4em;
  color: #777;
  margin-bottom: 0;
}

.home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline,
.center-slider .slider-contain .btn-solid,
.center-slider .slider-contain .btn-outline {
  margin-top: 20px;
}

.home-slider .p-left .slider-contain,
.center-slider .p-left .slider-contain {
  justify-content: flex-start;
}

.home-slider .p-right .slider-contain,
.center-slider .p-right .slider-contain {
  justify-content: flex-end;
}

.home-slider .p-center .slider-contain,
.center-slider .p-center .slider-contain {
  justify-content: center;
}

.home-slider:hover .slick-prev,
.home-slider:hover .slick-next,
.center-slider:hover .slick-prev,
.center-slider:hover .slick-next {
  opacity: 1;
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
  transition: all 0.5s ease;
}

.home-slider:hover .slick-prev:before,
.home-slider:hover .slick-next:before,
.center-slider:hover .slick-prev:before,
.center-slider:hover .slick-next:before {
  opacity: 1;
}

.home-slider:hover .slick-next,
.center-slider:hover .slick-next {
  right: 100px;
}

.home-slider:hover .slick-prev,
.center-slider:hover .slick-prev {
  left: 100px;
}

.home-slider-container .slider-contain {
  padding: 0 100px;
}

.center-slider {
  border-left: 1px solid #f1f5f4;
  border-right: 1px solid #f1f5f4;
}

.home-slider.text-white .slider-contain h1,
.home-slider.text-white .slider-contain h4 {
  color: #fff;
}

.gym-slider .home-slider .home {
  height: 85vh;
}

.gym-slider .home-slider .home .slider-contain {
  height: 85vh;
}

.gym-slider .home-slider .home .slider-contain h4,
.gym-slider .home-slider .home .slider-contain h1 {
  color: #fff;
}

.metro-slider .home-slider .home {
  background-position-y: 70%;
}

.metro-slider .home-slider .home .slider-contain {
  margin-top: 75px;
}

.metro-slider .home-slider .home .slider-contain h4,
.metro-slider .home-slider .home .slider-contain h1 {
  color: #000;
}

.small-slider .home-slider .home {
  height: 55vh;
}

.small-slider .home-slider .home .slider-contain {
  height: 55vh;
}

.small-slider .home-slider .home .slider-contain h4,
.small-slider .home-slider .home .slider-contain h1 {
  color: #000;
}

.height-100 .home-slider .home {
  height: 100vh;
}

.height-100 .home-slider .slider-contain {
  height: calc(99vh + 80px);
}

.height-85 .home-slider .home {
  height: 85vh;
}

.height-85 .home-slider .slider-contain {
  height: 85vh;
}

.height-85.content_top .slider-contain {
  padding-top: 150px;
  align-items: flex-start;
}

.height-85.content_top .slider-contain h1 {
  font-size: 40px;
  letter-spacing: 0.05em;
}

.height-85.content_top .slider-contain .btn-solid {
  padding: 9px 18px;
  margin-top: 10px;
}

.height-85.content_top .slider-contain h4 {
  letter-spacing: 0.08em;
}

.layout-7 .home {
  height: 60vh;
}

.layout-7 .home .slider-contain {
  height: 60vh;
}

.banner-slider .height-banner {
  height: 100%;
}

.banner-slider .home {
  height: 81vh;
}

.banner-slider .home .slider-contain {
  height: 81vh;
}

.banner-slider .home-banner>div img {
  width: 100%;
}

.banner-slider .home-banner>div:last-child img {
  margin-top: 30px;
}

.absolute-banner {
  margin-top: -105px;
}

.absolute-banner .absolute-bg {
  background-color: white;
  position: relative;
  padding: 25px;
  box-shadow: 0 0 8px 0 #ddd;
}

.instagram-box {
  position: relative;
  background-color: #309345;
  overflow: hidden;
}

.instagram-box img {
  width: 100%;
}

.instagram-box .overlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(48,147,69,0.5);
  top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(3) rotate(90deg);
          transform: scale(3) rotate(90deg);
}

.instagram-box .overlay i {
  font-size: 60px;
  color: #fff;
}

.instagram-box:hover .overlay {
  opacity: 1;
  transition: .5s ease;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
}

.blog .slick-slide {
  line-height: 1;
}

.blog-bg {
  background-color: #f1f5f4;
}

.classic-effect {
  position: relative;
  overflow: hidden;
}

.classic-effect:before,
.classic-effect:after {
  background: #309345;
  width: 25%;
  position: absolute;
  content: '';
  opacity: 0;
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}

.classic-effect:before {
  left: 0;
  transition-delay: 0s;
}

.classic-effect:after {
  left: 25%;
  transition-delay: 0.025s;
}

.classic-effect span:before,
.classic-effect span:after {
  background: #309345;
  width: 25%;
  position: absolute;
  content: '';
  opacity: 0;
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}

.classic-effect span:before {
  left: 50%;
  transition-delay: 0.05s;
}

.classic-effect span:after {
  left: 75%;
  transition-delay: 0.15s;
}

.classic-effect:hover:before,
.classic-effect:hover:after {
  bottom: 0;
  opacity: 0.5;
}

.classic-effect:hover span:before,
.classic-effect:hover span:after {
  bottom: 0;
  opacity: 0.5;
}

.blog-details {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.blog-details h4 {
  color: #309345;
  margin-top: 25px;
  line-height: 1;
  margin-bottom: 0;
}

.blog-details p {
  color: #222;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.03em;
  margin-top: 10px;
  margin-bottom: -5px;
  transition: all 0.5s ease;
}

.blog-details p:hover {
  color: #309345;
  transition: all 0.5s ease;
}

.blog-details h6 {
  line-height: 1;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

.blog-2 .blog-details h6 {
  padding: 5px 0;
}

.service-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-block .media {
  padding-top: 23px;
  padding-bottom: 23px;
  align-items: center;
}

.service-block svg {
  margin-right: 12px;
  width: 60px;
  height: 60px;
}

.service-block svg path {
  fill: #309345;
}

.service-block h4 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.service-block p {
  text-transform: capitalize;
  margin-bottom: 0;
}

.service-block+.service-block {
  border-left: 1px solid #ddd;
}

.service-block:hover svg {
  -webkit-animation: pulse 1000ms ease-in-out;
          animation: pulse 1000ms ease-in-out;
  transition: all 0.3s ease;
}

.service-block:hover h4 {
  color: #309345;
  transition: all 0.3s ease;
}

.service-block1 {
  text-align: center;
}

.service-block1 svg {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
}

.service-block1 svg path {
  fill: #309345;
}

.service-block1 h4 {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.service-block1 p {
  letter-spacing: 0.03em;
  margin-bottom: -3px;
  line-height: 1.5;
}

.service-block1:hover svg {
  -webkit-animation: pulse 1000ms ease-in-out;
          animation: pulse 1000ms ease-in-out;
  transition: all 0.3s ease;
}

.service-block1:hover h4 {
  color: #309345;
  transition: all 0.3s ease;
}

.about-text p {
  line-height: 28px;
  letter-spacing: 0.06em;
  text-align: center;
  margin-bottom: 50px;
}

.banner-timer {
  background-image: url("/assets/images/offer-banner.jpg");
  background-size: cover;
  margin-left: 20px;
  margin-right: 20px;
}

.banner-timer .banner-text {
  padding-top: 45px;
  padding-bottom: 45px;
}

.banner-timer .banner-text h2 {
  margin-bottom: 0;
  text-align: center;
}

.banner-timer .banner-text h2 span {
  color: #309345;
}

.banner-timer .timer-box {
  justify-content: center;
  display: flex;
}

.timer {
  padding-top: 15px;
  padding-bottom: 7px;
  padding-left: 40px;
  margin-top: 30px;
  background-color: #2d2a25;
  display: inline-block;
}

.timer p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
}

.timer span {
  width: 70px;
  display: inline-block;
}

.timer span .timer-cal {
  font-size: 12px;
  color: #777;
}

.timer span .padding-l {
  padding-left: 22px;
  display: inline;
}

.category-block .category-image {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}

.category-block .category-details {
  margin-top: 15px;
  text-align: center;
}

.category-block .category-details h5 {
  font-weight: 700;
  margin: 0 auto;
  transition: 0.5s ease;
  text-transform: uppercase;
}

.category-block:hover .category-image {
  background-color: #309345;
  transition: all 0.5s ease;
}

.category-block:hover .category-image img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  transition: all 0.5s ease;
}

.category-block:hover .category-details h5 {
  color: #309345;
  transition: all 0.5s ease;
}

.category-border {
  background-color: #f1f5f4;
  padding: 20px 10px;
}

.category-border .border-padding {
  padding: 0 10px;
}

.category-border div .category-banner {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.category-border div .category-banner img {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: 0.5s ease;
}

.category-border div .category-banner:hover img {
  -webkit-transform: scale(1.1) translateX(14px);
          transform: scale(1.1) translateX(14px);
  transition: 0.5s ease;
}

.category-border div .category-banner:hover h2 {
  color: #309345;
  transition: 0.5s ease;
}

.category-border div .category-banner .category-box {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-border div .category-banner .category-box h2 {
  background-color: #fff;
  display: inline-block;
  padding: 20px 35px;
  margin-bottom: 0;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.4);
  transition: 0.5s ease;
}

.category-bg {
  background-color: #85d796;
  padding: 40px 50px;
}

.category-bg .image-block img {
  width: 100%;
}

.category-bg .image-block.even:after {
  bottom: 94%;
}

.category-bg .image-block:after {
  content: "";
  position: absolute;
  background-color: #85d796;
  height: 0;
  width: 40px;
  z-index: 1;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  transition: 0.5s ease;
  margin-top: -40px;
}

.category-bg .image-block:hover:after {
  opacity: 1;
  transition: 0.5s ease;
  height: 80px;
}

.category-bg .contain-block {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fff;
}

.category-bg .contain-block.even:after {
  top: 94%;
}

.category-bg .contain-block:after {
  content: "";
  position: absolute;
  background-color: #85d796;
  height: 0;
  width: 40px;
  z-index: 1;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  transition: 0.5s ease;
  bottom: 94%;
}

.category-bg .contain-block:hover h2 {
  color: #309345;
  transition: all 0.5s ease;
}

.category-bg .contain-block:hover h6 span {
  color: #309345;
  transition: all 0.5s ease;
}

.category-bg .contain-block:hover .category-btn {
  background-image: linear-gradient(30deg, #222 50%, transparent 50%);
  border: 2px solid #222;
  transition: all 0.5s ease;
}

.category-bg .contain-block:hover:after {
  opacity: 1;
  transition: all 0.5s ease;
  height: 80px;
}

.category-bg .contain-block h2 {
  margin-bottom: 0;
  margin-top: 15px;
  transition: 0.5s ease;
}

.category-bg .contain-block h6 {
  color: #309345;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  line-height: 1;
  margin-bottom: 0;
}

.category-bg .contain-block h6 span {
  color: #7f786d;
  letter-spacing: 0.03em;
  font-weight: 700;
  transition: all 0.5s ease;
}

.category-bg .contain-block .category-btn {
  letter-spacing: 0.07em;
  margin-bottom: 25px;
  margin-top: 25px;
  transition: all 0.5s ease;
}

.category-m .slick-slide>div {
  margin: 0 20px;
}

.category-m .category-wrapper {
  border: 1px solid #dddada;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.category-m .category-wrapper>div:after,
.category-m .category-wrapper>div:before {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #309345;
  transition-delay: 0s;
}

.category-m .category-wrapper>div:before {
  left: 0;
  top: 0;
}

.category-m .category-wrapper>div:after {
  right: 0;
  bottom: 0;
}

.category-m .category-wrapper:after,
.category-m .category-wrapper:before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  transition: all 0.2s linear;
  background: #309345;
}

.category-m .category-wrapper:before {
  right: 0;
  top: 0;
}

.category-m .category-wrapper:after {
  left: 0;
  bottom: 0;
}

.category-m .category-wrapper:hover {
  transition: all 0.5s ease;
}

.category-m .category-wrapper:hover:after,
.category-m .category-wrapper:hover:before {
  transition-delay: 0s;
  width: 100%;
}

.category-m .category-wrapper:hover>div:after,
.category-m .category-wrapper:hover>div:before {
  transition-delay: 0.2s;
  height: 100%;
}

.category-m .category-wrapper img {
  display: inline;
}

.category-m .category-wrapper h4 {
  text-transform: uppercase;
  color: #2d2a25;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 25px;
  padding-top: 25px;
}

.category-m .category-wrapper .btn {
  margin-top: 20px;
}

.category-m .category-wrapper .category-link li {
  display: block;
  text-transform: capitalize;
  margin-top: 5px;
}

.category-m .category-wrapper .category-link li:first-child {
  margin-top: 0;
}

.category-m .category-wrapper .category-link li a {
  color: #948e8c;
}

.category-m .category-wrapper .category-link li a:hover {
  color: #309345;
}

.background {
  background-color: #f1f5f4;
  padding: 20px 10px;
  margin: 0;
}

.background .contain-bg {
  width: 100%;
  background-color: #fff;
  padding-top: 45px;
  padding-bottom: 45px;
  text-align: center;
  transition: all 0.5s ease;
}

.background .contain-bg h4 {
  color: #222;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  transition: all 0.5s ease;
}

.background .contain-bg:hover {
  background-color: #98dda7;
}

.background .contain-bg:hover h4 {
  color: #309345;
  transition: all 0.5s ease;
}

.absolute-product .theme-tab .tab-title .current a {
  font-weight: 700;
}

.absolute-product .product-box {
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
  border-radius: 5px;
}

.absolute-product .product-box .img-wrapper {
  border-radius: 5px 5px 0 0;
}

.absolute-product .product-box .product-detail {
  text-align: center;
  margin-top: 0;
  padding: 0 15px;
}

.absolute-product .product-box .product-detail .color-variant {
  padding-top: 5px;
}

.absolute-product .product-box .product-detail .color-variant li {
  height: 16px;
  width: 16px;
}

.absolute-product .product-box .product-detail .cart-bottom {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}

.absolute-product .product-box .product-detail .cart-bottom button {
  border: none;
  background-color: transparent;
}

.absolute-product .product-box .product-detail .cart-bottom i {
  color: #828282;
  font-size: 18px;
  padding-right: 7px;
  padding-left: 7px;
  transition: all 0.5s ease;
}

.absolute-product .product-box .product-detail .cart-bottom i:hover {
  color: #309345;
  transition: all 0.5s ease;
}

.absolute-product .product-box .product-detail .rating {
  margin-top: 10px;
}

.absolute-product .slick-slider .product-box {
  margin-bottom: 3px;
}

.absolute-product.digital-product .product-box {
  padding-bottom: 15px;
}

.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper {
  display: flex;
  width: 100%;
}

.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .live-demo,
.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .details_product {
  width: 50%;
  background-color: rgba(48,147,69,0.6);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  position: absolute;
  bottom: 0;
}

.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .live-demo h4,
.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .details_product h4 {
  margin-bottom: 0;
  line-height: 1.2;
}

.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .live-demo {
  left: -50%;
  transition: all 0.5s ease;
}

.absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .details_product {
  right: -50%;
  transition: all 0.5s ease;
}

.absolute-product.digital-product .product-box .product-detail h6 {
  padding-top: 15px;
  color: #000;
  font-weight: 700;
}

.absolute-product.digital-product .product-box .product-detail h4 {
  color: #000;
  font-weight: 100;
}

.absolute-product.digital-product .product-box:hover .img-wrapper .digital-wrapper .live-demo {
  left: 0;
  transition: all 0.5s ease;
}

.absolute-product.digital-product .product-box:hover .img-wrapper .digital-wrapper .details_product {
  right: 0;
  transition: all 0.5s ease;
}

.product-m .slick-slide>div {
  margin: 0 10px;
}

.color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  transition: all 0.1s ease;
  vertical-align: middle;
}

.no-slider .product-box {
  width: 100%;
  flex: 0 0 25%;
  max-width: calc(25% - 30px);
  margin: 0 15px 30px;
}

.no-slider .product-box:nth-last-child(-n+4) {
  margin: 0 15px 0;
}

.product-para p {
  margin-bottom: 0;
  padding-bottom: 25px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-top: -7px;
}

.portfolio-section .article-title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: white;
  transition: all 0.5s ease;
  text-align: center;
  float: left;
  font-size: 18px;
  line-height: 24px;
}

.portfolio-section .article-title a {
  color: white;
}

.portfolio-section .isotopeSelector {
  float: left;
  width: 100%;
  position: relative;
}

.portfolio-section .isotopeSelector .overlay {
  position: relative;
  border: 1px solid #ddd;
}

.portfolio-section .isotopeSelector img {
  width: 100%;
  height: auto;
}

.portfolio-section .isotopeSelector {
  margin-bottom: 30px;
}

.portfolio-section .isotopeSelector img {
  transition: all .4s ease;
}

.portfolio-section .isotopeSelector .overlay-background {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all .4s ease;
}

.portfolio-section .isotopeSelector .overlay-background i {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 10px;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #309345;
  font-size: 22px;
  display: none;
  transition: all 0.4s ease;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.portfolio-section .isotopeSelector:hover img {
  -webkit-transform: scale(1.2) rotate(2deg);
          transform: scale(1.2) rotate(2deg);
  transition: all .4s ease;
}

.portfolio-section .isotopeSelector:hover .overlay-background {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 3px solid white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(48,147,69,0.2);
  transition: all 0.4s ease;
}

.portfolio-section .isotopeSelector:hover .overlay-background i {
  display: flex;
  transition: all 0.4s ease;
}

.portfolio-section .border-portfolio {
  margin: 3px;
  overflow: hidden;
}

.portfolio-section #form1 {
  padding-bottom: 20px;
}

.portfolio-section .filter-button {
  padding: 0 24px;
  font-size: 20px;
  line-height: 35px;
  border: none;
  background-color: white;
}

.portfolio-section .filter-button.active {
  background-color: #309345;
  color: #fff;
}

.portfolio-section.fullwidth-portfolio .isotopeSelector {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 14px;
}

.portfolio-section.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center;
}

.portfolio-section.metro-section .product-box .product-detail h6 {
  color: #525252;
  padding-top: 0;
  margin-top: -2px;
}

.portfolio-section.metro-section .product-box .product-detail h4 {
  font-weight: 400;
  color: #000;
}

.portfolio-section.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1;
}

.portfolio-section.metro-section .product-box .cart-wrap button {
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: rgba(255,255,255,0.8);
  opacity: 1;
}

.portfolio-section.metro-section .product-box .cart-wrap button i {
  padding: 7px;
  color: #333;
}

.portfolio-section.metro-section .product-box .cart-wrap a i {
  border-radius: 100%;
  border: 1px solid #fff;
  margin: 5px 0;
  background-color: rgba(255,255,255,0.8);
  padding: 7px;
  color: #333;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-section.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap button {
  -webkit-animation: none;
          animation: none;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  -webkit-animation: fadeInDown 500ms ease-in-out;
          animation: fadeInDown 500ms ease-in-out;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  -webkit-animation: fadeInDown 700ms ease-in-out;
          animation: fadeInDown 700ms ease-in-out;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  -webkit-animation: fadeInDown 1000ms ease-in-out;
          animation: fadeInDown 1000ms ease-in-out;
}

.product-box,
.product-wrap {
  position: relative;
  transition: all 0.5s ease;
  margin: 20px 10px 10px;
  padding-bottom: 20px;
}

.product-box .img-block,
.product-wrap .img-block {
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

.product-box .img-block .front,
.product-wrap .img-block .front {
  opacity: 1;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}

.product-box .img-block .back,
.product-wrap .img-block .back {
  opacity: 0;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.product-box .img-block .lable-wrapper,
.product-wrap .img-block .lable-wrapper {
  margin: 0 auto;
  top: 40px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  transition: all 0.5s ease;
  z-index: 2;
}

.product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable2 {
  font-size: 14px;
  padding: 10px 14px 10px 20px;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
}

.product-box .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable1 {
  background-color: #309345;
  color: #fff;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable2 {
  background-color: #fff;
  color: #000;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.product-box .img-wrapper,
.product-wrap .img-wrapper {
  position: relative;
  overflow: hidden;
}

.product-box .img-wrapper .front,
.product-wrap .img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}

.product-box .img-wrapper .back,
.product-wrap .img-wrapper .back {
  opacity: 0;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.product-box .img-wrapper .cart-box,
.product-wrap .img-wrapper .cart-box {
  position: absolute;
  margin: 0 auto;
  display: inline-block;
  right: 0;
  left: 0;
  border-radius: 50px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 12px 15px;
  box-shadow: 0 0 12px 0 #ddd;
  bottom: 30px;
  background-color: #fff;
  opacity: 0;
  transition: all 0.2s ease;
}

.product-box .img-wrapper .cart-box button,
.product-wrap .img-wrapper .cart-box button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}

.product-box .img-wrapper .cart-box i,
.product-wrap .img-wrapper .cart-box i {
  color: #bbbbbb;
  font-size: 18px;
  padding-left: 8px;
  padding-right: 8px;
  transition: all 0.2s ease;
  display: inline-block;
}

.product-box .img-wrapper .cart-box i:hover,
.product-wrap .img-wrapper .cart-box i:hover {
  color: #309345;
  transition: all 0.2s ease;
}

.product-box .img-wrapper .lable-block .lable3,
.product-wrap .img-wrapper .lable-block .lable3 {
  border-radius: 100%;
  background-color: #309345;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 12px 6px;
  text-transform: uppercase;
  color: #fff;
  top: 7px;
  left: 7px;
}

.product-box .img-wrapper .lable-block .lable4,
.product-wrap .img-wrapper .lable-block .lable4 {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  top: 7px;
  right: 7px;
  letter-spacing: 0.1em;
}

.product-box .img-wrapper .product-thumb-list,
.product-wrap .img-wrapper .product-thumb-list {
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-box .img-wrapper .product-thumb-list li,
.product-wrap .img-wrapper .product-thumb-list li {
  display: block;
  opacity: 0.3;
}

.product-box .img-wrapper .product-thumb-list li.active,
.product-wrap .img-wrapper .product-thumb-list li.active {
  opacity: 1;
}

.product-box .img-wrapper .product-thumb-list li img,
.product-wrap .img-wrapper .product-thumb-list li img {
  height: 50px;
}

.product-box .cart-info,
.product-box .cart-wrap,
.product-wrap .cart-info,
.product-wrap .cart-wrap {
  position: absolute;
  bottom: 40px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  right: 0;
  left: 0;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease;
}

.product-box .cart-info a [class^="icon-"],
.product-box .cart-info a [class*=" icon-"],
.product-box .cart-wrap a [class^="icon-"],
.product-box .cart-wrap a [class*=" icon-"],
.product-wrap .cart-info a [class^="icon-"],
.product-wrap .cart-info a [class*=" icon-"],
.product-wrap .cart-wrap a [class^="icon-"],
.product-wrap .cart-wrap a [class*=" icon-"] {
  display: inline-block;
}

.product-box .cart-info.cart-wrap,
.product-box .cart-wrap.cart-wrap,
.product-wrap .cart-info.cart-wrap,
.product-wrap .cart-wrap.cart-wrap {
  bottom: 0;
  text-align: right;
  left: unset;
}

.product-box .cart-info.cart-wrap i,
.product-box .cart-wrap.cart-wrap i,
.product-wrap .cart-info.cart-wrap i,
.product-wrap .cart-wrap.cart-wrap i {
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;
}

.product-box .cart-info.cart-wrap.cart-effect-left,
.product-box .cart-wrap.cart-wrap.cart-effect-left,
.product-wrap .cart-info.cart-wrap.cart-effect-left,
.product-wrap .cart-wrap.cart-wrap.cart-effect-left {
  left: 0;
  right: unset;
}

.product-box .cart-info button,
.product-box .cart-wrap button,
.product-wrap .cart-info button,
.product-wrap .cart-wrap button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}

.product-box .cart-info i,
.product-box .cart-wrap i,
.product-wrap .cart-info i,
.product-wrap .cart-wrap i {
  color: #309345;
  font-size: 20px;
  padding-right: 0;
  padding-left: 10px;
}

.product-box .cart-info i.cart-icon,
.product-box .cart-wrap i.cart-icon,
.product-wrap .cart-info i.cart-icon,
.product-wrap .cart-wrap i.cart-icon {
  font-size: 24px;
}

.product-box .cart-info i:hover,
.product-box .cart-wrap i:hover,
.product-wrap .cart-info i:hover,
.product-wrap .cart-wrap i:hover {
  color: #ff4c3b;
}

.product-box .cart-detail,
.product-wrap .cart-detail {
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 0;
}

.product-box .cart-detail i,
.product-wrap .cart-detail i {
  color: #6f6f6f;
  font-size: 18px;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

.product-box .cart-detail button,
.product-wrap .cart-detail button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}

.product-box .product-detail,
.product-box .product-info,
.product-wrap .product-detail,
.product-wrap .product-info {
  padding-left: 5px;
}

.product-box .product-detail .rating,
.product-box .product-info .rating,
.product-wrap .product-detail .rating,
.product-wrap .product-info .rating {
  margin-top: 15px;
}

.product-box .product-detail .rating i,
.product-box .product-info .rating i,
.product-wrap .product-detail .rating i,
.product-wrap .product-info .rating i {
  padding-right: 5px;
}

.product-box .product-detail .rating i:nth-child(-n+4),
.product-box .product-info .rating i:nth-child(-n+4),
.product-wrap .product-detail .rating i:nth-child(-n+4),
.product-wrap .product-info .rating i:nth-child(-n+4) {
  color: #ffa200;
}

.product-box .product-detail .rating i:last-child,
.product-box .product-info .rating i:last-child,
.product-wrap .product-detail .rating i:last-child,
.product-wrap .product-info .rating i:last-child {
  color: #ddd;
}

.product-box .product-detail h6,
.product-box .product-info h6,
.product-wrap .product-detail h6,
.product-wrap .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  transition: all 0.5s ease;
  font-size: 16px;
}

.product-box .product-detail h4,
.product-box .product-info h4,
.product-wrap .product-detail h4,
.product-wrap .product-info h4 {
  font-size: 18px;
  color: #222;
  font-weight: 700;
  margin-bottom: 0;
  transition: all 0.5s ease;
}

.product-box .product-detail .color-variant,
.product-box .product-info .color-variant,
.product-wrap .product-detail .color-variant,
.product-wrap .product-info .color-variant {
  padding-top: 15px;
}

.product-box .product-detail .color-variant li,
.product-box .product-info .color-variant li,
.product-wrap .product-detail .color-variant li,
.product-wrap .product-info .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  transition: all 0.1s ease;
  cursor: pointer;
}

.product-box .product-info,
.product-wrap .product-info {
  padding: 0;
  text-align: center;
  position: relative;
}

.product-box .product-info .add-btn,
.product-wrap .product-info .add-btn {
  position: absolute;
  bottom: 110px;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 1;
}

.product-box .product-info .add-btn i,
.product-wrap .product-info .add-btn i {
  color: #309345;
}

.product-box .product-info .add-btn .btn-outline,
.product-wrap .product-info .add-btn .btn-outline {
  transition: all 0.2s ease;
  color: #309345;
}

.product-box .product-info .add-btn .btn-outline:hover,
.product-wrap .product-info .add-btn .btn-outline:hover {
  color: #fff;
}

.product-box .product-info .add-btn .btn-outline:hover i,
.product-wrap .product-info .add-btn .btn-outline:hover i {
  color: #fff;
}

.product-box.effect-center .front img,
.product-wrap.effect-center .front img {
  transition: all 0.5s ease;
}

.product-box.effect-center .img-wrapper .cart-box,
.product-wrap.effect-center .img-wrapper .cart-box {
  bottom: 20%;
  transition: all 0.5s ease;
}

.product-box:before,
.product-wrap:before {
  content: '';
  transition: all .3s;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: 0;
  position: absolute;
  z-index: -1;
  background-color: #fff;
}

.product-box:hover,
.product-wrap:hover {
  z-index: 3;
}

.product-box:hover.effect-center .front img,
.product-wrap:hover.effect-center .front img {
  opacity: 0.3;
  transition: all 0.5s ease;
}

.product-box:hover.effect-center .img-wrapper .cart-box,
.product-wrap:hover.effect-center .img-wrapper .cart-box {
  bottom: 35%;
  transition: all 0.5s ease;
}

.product-box:hover .img-block .first,
.product-box:hover .img-wrapper .first,
.product-wrap:hover .img-block .first,
.product-wrap:hover .img-wrapper .first {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.product-box:hover .img-block .back,
.product-box:hover .img-wrapper .back,
.product-wrap:hover .img-block .back,
.product-wrap:hover .img-wrapper .back {
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.product-box:hover:before,
.product-wrap:hover:before {
  box-shadow: 0 0 14px 0 rgba(0,0,0,0.16);
}

.product-box:hover .cart-info,
.product-wrap:hover .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
}

.product-box:hover .cart-info button,
.product-wrap:hover .cart-info button {
  -webkit-animation: fadeInUp 300ms ease-in-out;
          animation: fadeInUp 300ms ease-in-out;
}

.product-box:hover .cart-info a:nth-child(2) i,
.product-wrap:hover .cart-info a:nth-child(2) i {
  -webkit-animation: fadeInUp 500ms ease-in-out;
          animation: fadeInUp 500ms ease-in-out;
}

.product-box:hover .cart-info a:nth-child(3) i,
.product-wrap:hover .cart-info a:nth-child(3) i {
  -webkit-animation: fadeInUp 700ms ease-in-out;
          animation: fadeInUp 700ms ease-in-out;
}

.product-box:hover .cart-info a:nth-child(4) i,
.product-wrap:hover .cart-info a:nth-child(4) i {
  -webkit-animation: fadeInUp 1000ms ease-in-out;
          animation: fadeInUp 1000ms ease-in-out;
}

.product-box:hover .cart-wrap button,
.product-wrap:hover .cart-wrap button {
  -webkit-animation: fadeInRight 300ms ease-in-out;
          animation: fadeInRight 300ms ease-in-out;
}

.product-box:hover .cart-wrap a:nth-child(2) i,
.product-wrap:hover .cart-wrap a:nth-child(2) i {
  -webkit-animation: fadeInRight 500ms ease-in-out;
          animation: fadeInRight 500ms ease-in-out;
}

.product-box:hover .cart-wrap a:nth-child(3) i,
.product-wrap:hover .cart-wrap a:nth-child(3) i {
  -webkit-animation: fadeInRight 700ms ease-in-out;
          animation: fadeInRight 700ms ease-in-out;
}

.product-box:hover .cart-wrap a:nth-child(4) i,
.product-wrap:hover .cart-wrap a:nth-child(4) i {
  -webkit-animation: fadeInRight 1000ms ease-in-out;
          animation: fadeInRight 1000ms ease-in-out;
}

.product-box:hover .cart-wrap.cart-effect-left button,
.product-wrap:hover .cart-wrap.cart-effect-left button {
  -webkit-animation: fadeInLeft 300ms ease-in-out;
          animation: fadeInLeft 300ms ease-in-out;
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(2) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(2) i {
  -webkit-animation: fadeInLeft 500ms ease-in-out;
          animation: fadeInLeft 500ms ease-in-out;
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(3) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(3) i {
  -webkit-animation: fadeInLeft 700ms ease-in-out;
          animation: fadeInLeft 700ms ease-in-out;
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(4) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(4) i {
  -webkit-animation: fadeInLeft 1000ms ease-in-out;
          animation: fadeInLeft 1000ms ease-in-out;
}

.product-box:hover .cart-detail,
.product-wrap:hover .cart-detail {
  opacity: 1;
  transition: all 0.5s ease;
}

.product-box:hover .cart-detail button,
.product-wrap:hover .cart-detail button {
  -webkit-animation: fadeInRight 300ms ease-in-out;
          animation: fadeInRight 300ms ease-in-out;
}

.product-box:hover .cart-detail a:nth-child(2) i,
.product-wrap:hover .cart-detail a:nth-child(2) i {
  -webkit-animation: fadeInRight 500ms ease-in-out;
          animation: fadeInRight 500ms ease-in-out;
}

.product-box:hover .cart-detail a:nth-child(3) i,
.product-wrap:hover .cart-detail a:nth-child(3) i {
  -webkit-animation: fadeInRight 700ms ease-in-out;
          animation: fadeInRight 700ms ease-in-out;
}

.product-box:hover .cart-detail a:nth-child(4) i,
.product-wrap:hover .cart-detail a:nth-child(4) i {
  -webkit-animation: fadeInRight 1000ms ease-in-out;
          animation: fadeInRight 1000ms ease-in-out;
}

.product-box:hover .product-info .add-btn,
.product-wrap:hover .product-info .add-btn {
  opacity: 1;
  transition: all 0.2s ease;
  -webkit-animation: fadeInUp 500ms ease-in-out;
          animation: fadeInUp 500ms ease-in-out;
}

.product-box:hover .img-wrapper .cart-box,
.product-wrap:hover .img-wrapper .cart-box {
  opacity: 1;
  transition: all 0.2s ease;
  -webkit-animation: fadeInUp 400ms ease-in-out;
          animation: fadeInUp 400ms ease-in-out;
}

.addtocart_count {
  position: relative;
}

.addtocart_count .product-box .product-detail {
  text-align: center;
}

.addtocart_count .product-box .cart-info {
  bottom: 40px;
  right: 10px;
}

.addtocart_count .product-box .cart-info a i {
  background-color: #e2e2e2;
  border-radius: 100%;
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
  color: #313131;
}

.addtocart_count .product-box .add-button {
  background-color: #efefef;
  color: black;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  width: 100%;
  padding: 5px 0;
  transition: all 0.5s ease;
  border: none;
  cursor: pointer;
}

.addtocart_count .product-box:hover .cart-info a:nth-child(1) i {
  -webkit-animation: fadeInRight 300ms ease-in-out;
          animation: fadeInRight 300ms ease-in-out;
}

.addtocart_count .product-box:hover .add-button {
  bottom: 0;
  transition: all 0.5s ease;
}

.addtocart_count .addtocart_btn {
  position: relative;
}

.addtocart_count .addtocart_btn .cart_qty {
  width: 100%;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box {
  position: absolute;
  bottom: 0;
  display: none;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control {
  width: 100%;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus {
  border-color: #efefef;
  box-shadow: none;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button {
  background: #efefef !important;
  position: absolute;
  height: 100%;
  z-index: 9;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus {
  left: 0;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus {
  right: 0;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button i {
  color: #000;
}

.addtocart_count .addtocart_btn .cart_qty.open {
  display: block;
}

.game-product .theme-tab .tab-title .current a,
.game-product .theme-tab .tab-title2 .current a {
  border-bottom: 2px solid #309345;
  padding-bottom: 5px;
}

.game-product .product-box .product-detail {
  text-align: center;
}

.game-product .product-box .cart-info {
  bottom: 40px;
  right: 10px;
}

.game-product .product-box .cart-info a i {
  background-color: white;
  border-radius: 100%;
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
}

.game-product .product-box .add-button {
  background-color: #309345;
  color: white;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  position: absolute;
  width: 100%;
  bottom: -40px;
  padding: 5px 0;
  transition: all 0.5s ease;
  cursor: pointer;
}

.game-product .product-box:hover .cart-info a:nth-child(1) i {
  -webkit-animation: fadeInRight 300ms ease-in-out;
          animation: fadeInRight 300ms ease-in-out;
}

.game-product .product-box:hover .add-button {
  bottom: 0;
  transition: all 0.5s ease;
}

.game-product .img-wrapper img {
  width: 100%;
}

.react-tabs__tab--selected {
  background: unset !important;
  border-color: transparent !important;
  color: #309345 !important;
  border-radius: 0 !important;
}

.react-tabs__tab--selected .nav-link {
  color: #309345 !important;
}

.tab-content.nav-material .react-tabs__tab--selected {
  border-bottom: 2px solid #309345 !important;
}

.react-tabs__tab:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.theme-tab {
  position: relative;
}

.theme-tab .tab-content {
  display: block;
}

.theme-tab .tab-content[style="display: block;"] .product-box,
.theme-tab .tab-content[style="display: block;"] .tab-box {
  -webkit-animation: zoomIn 300ms ease-in-out;
          animation: zoomIn 300ms ease-in-out;
}

.theme-tab .tab-content .product-tab .tab-box {
  background-color: #fff;
  width: 100%;
  flex: 0 0 25%;
  max-width: calc(25% - 10px);
  margin: 0 5px 10px;
}

.theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+4) {
  margin: 0 5px 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 img {
  height: 250px;
  padding: 15px;
  transition: all 0.5s ease;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
  margin-top: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i {
  padding-right: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:nth-child(-n+4) {
  color: #ffa200;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:last-child {
  color: #ddd;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
  margin-right: 35px;
  line-height: 17px;
  margin-top: 5px;
  margin-bottom: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
  font-size: 20px;
  margin-top: 5px;
  font-weight: 700;
  color: #222;
  margin-bottom: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 2px;
  margin-top: 20px;
  transition: all 0.1s ease;
  margin-bottom: 0;
  cursor: pointer;
}

.theme-tab .tab-content .product-tab .tab-box:hover .product-box2 img {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  transition: all 0.5s ease;
}

.theme-tab .tab-title,
.theme-tab .tab-title2 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 25px;
  margin-top: -9px;
}

.theme-tab .tab-title .current a,
.theme-tab .tab-title2 .current a {
  color: #309345;
}

.theme-tab .tab-title li,
.theme-tab .tab-title2 li {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0;
  line-height: 1;
}

.theme-tab .tab-title a,
.theme-tab .tab-title2 a {
  color: #2d2a25;
  text-transform: uppercase;
}

.theme-tab .tab-title2 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: -8px;
}

.theme-tab .tab-title2:after {
  content: "";
  position: absolute;
  border: 1px solid #f1f5f4;
  width: 100%;
  left: 0;
  top: 17px;
  z-index: -1;
}

.theme-tab .tab-title2 .current {
  background-color: #fff;
}

.theme-tab .tab-title2 .current a {
  background-color: #fff;
}

.tab-bg {
  background-color: #a0e0ad;
  padding: 70px 50px 60px 50px;
}

.tab-bg.tab-grey-bg {
  background-color: #f9f9f9;
}

.tab-bg .theme-tab .tab-title {
  margin-bottom: 30px;
}

.full-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 190px;
  padding-bottom: 190px;
  background-position: center;
}

.full-banner.parallax-banner1 {
  background-image: url("/assets/images/parallax/1.jpg");
}

.full-banner.parallax-banner2 {
  background-image: url("/assets/images/parallax/2.jpg");
}

.full-banner.parallax-banner3 {
  background-image: url("/assets/images/parallax/3.jpg");
}

.full-banner.parallax-banner4 {
  background-image: url("/assets/images/parallax/4.jpg");
}

.full-banner.parallax-banner5 {
  background-image: url("/assets/images/parallax/5.jpg");
}

.full-banner.parallax-banner6 {
  background-image: url("/assets/images/parallax/6.jpg");
}

.full-banner.parallax-banner7 {
  background-image: url("/assets/images/parallax/7.jpg");
}

.full-banner.parallax-banner8 {
  background-image: url("/assets/images/parallax/8.jpg");
}

.full-banner.parallax-banner9 {
  background-image: url("/assets/images/parallax/9.jpg");
}

.full-banner.parallax-banner10 {
  background-image: url("/assets/images/parallax/10.jpg");
}

.full-banner.parallax-banner11 {
  background-image: url("/assets/images/parallax/11.jpg");
}

.full-banner.parallax-banner12 {
  background-image: url("/assets/images/parallax/12.jpg");
}

.full-banner.parallax-banner13 {
  background-image: url("/assets/images/parallax/13.jpg");
}

.full-banner.parallax-banner14 {
  background-image: url("/assets/images/parallax/14.jpg");
}

.full-banner.parallax-banner15 {
  background-image: url("/assets/images/parallax/15.jpg");
}

.full-banner.parallax-banner16 {
  background-image: url("/assets/images/parallax/16.jpg");
}

.full-banner.parallax-banner17 {
  background-image: url("/assets/images/parallax/17.jpg");
}

.full-banner.parallax-banner18 {
  background-image: url("/assets/images/parallax/18.jpg");
}

.full-banner.parallax-banner19 {
  background-image: url("/assets/images/parallax/19.jpg");
}

.full-banner.parallax-banner20 {
  background-image: url("/assets/images/parallax/20.jpg");
}

.full-banner.parallax-banner21 {
  background-image: url("/assets/images/parallax/21.jpg");
}

.full-banner.parallax-banner22 {
  background-image: url("/assets/images/parallax/22.jpg");
}

.full-banner.parallax-banner23 {
  background-image: url("/assets/images/parallax/23.jpg");
}

.full-banner.parallax-banner24 {
  background-image: url("/assets/images/parallax/24.jpg");
}

.full-banner.parallax-banner25 {
  background-image: url("/assets/images/parallax/25.jpg");
}

.full-banner.parallax-banner26 {
  background-image: url("/assets/images/parallax/26.jpg");
}

.full-banner.parallax-banner27 {
  background-image: url("/assets/images/parallax/27.jpg");
}

.full-banner.parallax-banner28 {
  background-image: url("/assets/images/parallax/28.jpg");
}

.full-banner.parallax-layout {
  padding-top: 350px;
  padding-bottom: 350px;
}

.full-banner.parallax-layout .banner-contain h4 {
  color: #7f786d;
}

.full-banner.banner-layout-3 .banner-contain h4 {
  color: #fff;
}

.full-banner.banner-layout-3 .banner-contain h3 {
  color: #fff;
  margin-top: 10px;
}

.full-banner.banner-layout-3 .banner-contain .color {
  color: #309345;
  line-height: 1;
}

.full-banner.parallax {
  background-attachment: fixed;
}

.full-banner.p-left .banner-contain {
  float: left;
}

.full-banner.p-right .banner-contain {
  float: right;
}

.full-banner.p-center .banner-contain {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.full-banner .banner-contain .btn-solid,
.full-banner .banner-contain .btn-outline {
  margin-top: 20px;
}

.full-banner .banner-contain h2 {
  font-size: 100px;
  font-weight: 700;
  color: #309345;
  text-transform: uppercase;
  margin-top: -15px;
}

.full-banner .banner-contain h3 {
  font-size: 60px;
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
}

.full-banner .banner-contain h4 {
  font-size: 24px;
  color: #777;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  padding-top: 10px;
  margin-bottom: -5px;
  line-height: 1.3;
}

.parallax-cls section:nth-child(odd) .full-banner {
  background-position: right;
}

.parallax-cls section:nth-child(even) .full-banner {
  background-position: left;
}

.parallax-cls section .banner-contain {
  margin-top: -12px;
}

.collection-banner {
  position: relative;
  overflow: hidden;
}

.collection-banner img {
  transition: all 0.5s ease;
  width: 100%;
}

.collection-banner.p-left .contain-banner {
  justify-content: flex-start;
}

.collection-banner.p-right .contain-banner {
  justify-content: flex-end;
}

.collection-banner.p-center .contain-banner {
  justify-content: center;
}

.collection-banner .contain-banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
}

.collection-banner .contain-banner.banner-3 {
  padding-left: 50px;
  padding-right: 50px;
}

.collection-banner .contain-banner.banner-3 h2 {
  font-size: 36px;
  letter-spacing: 0.05em;
  color: white;
  margin-top: 5px;
  margin-bottom: -6px;
}

.collection-banner .contain-banner.banner-3 h4 {
  color: #fff;
}

.collection-banner .contain-banner.banner-4 {
  padding-left: 50px;
  padding-right: 50px;
}

.collection-banner .contain-banner.banner-4 h2 {
  font-size: 28px;
  letter-spacing: 0.03em;
  color: white;
  margin-bottom: -6px;
}

.collection-banner .contain-banner h4 {
  color: #309345;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

.collection-banner .contain-banner h2 {
  font-size: 60px;
  font-weight: 700;
  color: #222;
  letter-spacing: 0.1em;
  margin-bottom: -6px;
}

.collection-banner:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 0.5s ease;
}

.banner-furniture {
  padding-left: 15px;
  padding-right: 15px;
}

.banner-furniture .collection-banner .contain-banner.banner-3 h2 {
  color: #222;
  margin-bottom: 0;
}

.banner-furniture .collection-banner .contain-banner.banner-3 h4 {
  color: #309345;
}

.banner-goggles .collection-banner .contain-banner.banner-3 h2 {
  color: #222;
}

.banner-goggles .collection-banner .contain-banner.banner-3 h4 {
  color: #309345;
}

.banner-top-cls {
  margin-top: 30px;
}

.banner-6 .collection-banner .contain-banner.banner-3 h2 {
  color: #222222;
}

.absolute_banner {
  margin-bottom: 22px;
}

.absolute_banner .collection-banner {
  overflow: unset;
}

.absolute_banner .collection-banner .absolute-contain {
  position: absolute;
  background-color: #ffffff;
  bottom: -22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 20px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.35);
  min-width: 85%;
  text-align: center;
  transition: all 0.5s ease;
}

.absolute_banner .collection-banner .absolute-contain h3 {
  color: #309345;
  text-transform: capitalize;
  margin-top: -5px;
  font-weight: 700;
}

.absolute_banner .collection-banner .absolute-contain h4 {
  color: #000;
  margin-bottom: 0;
}

.absolute_banner .collection-banner:hover img {
  -webkit-transform: none;
          transform: none;
}

.layout7-product .product-box {
  padding: 2px;
}

.layout7-product .product-box img {
  width: 100%;
}

.layout7-product .product-box .cart-info {
  right: 7px;
  bottom: 7px;
}

.layout7-product .product-box .details-product {
  position: absolute;
  transition: all 0.5s ease;
  opacity: 0;
  bottom: 15px;
  left: 15px;
}

.layout7-product .product-box .details-product h6,
.layout7-product .product-box .details-product h4 {
  margin-bottom: 0;
}

.layout7-product .product-box .details-product h6 {
  color: #000;
}

.layout7-product .product-box:hover .details-product {
  opacity: 1;
  -webkit-animation: fadeIn 500ms ease-in-out;
          animation: fadeIn 500ms ease-in-out;
  transition: all 0.5s ease;
}

.theme-card {
  position: relative;
}

.theme-card.center-align {
  display: flex;
  height: 100%;
  align-items: center;
}

.theme-card.center-align .offer-slider .product-box2 .media .media-body {
  margin-top: 0;
}

.theme-card.card-border {
  border: 1px solid #ddd;
}

.theme-card.card-border h5 {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
}

.theme-card.card-border .slick-prev,
.theme-card.card-border .slick-next {
  top: -45px;
}

.theme-card.card-border .slick-next {
  right: 30px;
}

.theme-card.card-border .slick-prev {
  right: 50px;
}

.theme-card.card-border .offer-slider {
  padding-top: 0;
  padding-bottom: 20px;
}

.theme-card.card-border .offer-slider img {
  padding: 15px 15px 15px 30px;
}

.theme-card.card-border .offer-slider .media .media-body a h6 {
  margin-right: 25px;
}

.theme-card h5 {
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-top: -5px;
}

.theme-card h5.title-border {
  border-bottom: 1px solid #ddd;
}

.theme-card .slick-prev,
.theme-card .slick-next {
  top: -35px;
}

.theme-card .slick-prev:before,
.theme-card .slick-next:before {
  font: normal normal normal 30px/1 FontAwesome;
  color: #222;
  opacity: 0.6;
}

.theme-card .slick-next {
  right: 0;
}

.theme-card .slick-next:before {
  content: "\f105";
}

.theme-card .slick-prev {
  left: unset;
  right: 25px;
}

.theme-card .slick-prev:before {
  content: "\f104";
}

.theme-card .offer-slider img {
  height: 160px;
  padding: 15px 15px 15px 0;
}

.theme-card .offer-slider>div .media:last-child .media-body {
  margin-top: 15px;
}

.theme-card .offer-slider>div .media:last-child img {
  margin-bottom: -15px;
}

.theme-card .offer-slider .media .media-body .rating {
  margin-top: 0;
}

.theme-card .offer-slider .media .media-body .rating i {
  padding-right: 5px;
}

.theme-card .offer-slider .media .media-body .rating i:nth-child(-n+4) {
  color: #ffa200;
}

.theme-card .offer-slider .media .media-body .rating i:last-child {
  color: #ddd;
}

.theme-card .offer-slider .media .media-body a h6 {
  margin-right: 61px;
  line-height: 17px;
  margin-top: 5px;
  margin-bottom: 0;
}

.theme-card .offer-slider .media .media-body h4 {
  margin-top: 10px;
  font-weight: 700;
  color: #222;
  margin-bottom: 0;
}

.offer-slider .slick-slide {
  line-height: 1;
}

.center-slider .product-box {
  box-shadow: none;
}

.center-slider .product-box .img-wrapper img {
  padding: 15px;
}

.center-slider .slick-next:before,
.center-slider .slick-prev:before {
  box-shadow: 0 0 8px 0 #ddd;
}

.center-slider .title2 .title-inner2 {
  margin-bottom: 0;
}

.center-slider:hover .product-box {
  box-shadow: none;
}

.center-slider:hover .slick-next {
  right: 20px;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}

.center-slider:hover .slick-prev {
  left: 20px;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}

.full-box .theme-card .offer-slider .product-box2 .media img {
  height: 300px;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.5s ease;
}

.full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
  margin-right: 20px;
}

.full-box .theme-card .offer-slider .product-box2 .media .media-body h4 {
  font-size: 20px;
  margin-top: 5px;
}

.full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  margin-top: 15px;
  transition: all 0.1s ease;
  margin-bottom: 0;
  cursor: pointer;
}

.full-box .theme-card .offer-slider .product-box2+.product-box2 {
  border-top: 1px solid #f1f5f4;
}

.full-box .theme-card .offer-slider .product-box2:hover .media img {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  transition: all 0.5s ease;
}

.ribbon-1 {
  width: 24px;
  height: 45px;
  background: #309345;
  top: -6px;
  left: 25px;
  position: absolute;
  box-shadow: 4px 5px 15px 2px rgba(90,90,90,0.35);
}

.ribbon-1:before {
  height: 0;
  width: 0;
  border-bottom: 6px solid #174621;
  border-right: 6px solid transparent;
  right: -6px;
  content: "";
  position: absolute;
}

.ribbon-1:after {
  height: 0;
  width: 0;
  border-left: 12px solid #309345;
  border-right: 12px solid #309345;
  border-bottom: 12px solid transparent;
  bottom: -11px;
  left: 0;
  content: "";
  position: absolute;
}

.ribbon-1 span {
  display: block;
  color: #fff;
  height: 12px;
  text-transform: lowercase;
}

.modal-open {
  padding-right: 0 !important;
}

#sizemodal {
  padding-right: 0 !important;
}

.modal-dialog {
  margin: 0;
}

.modal-dialog .modal-content {
  border: none;
}

.modal-dialog .modal-content .modal-body {
  padding: 0;
}

.theme-modal {
  padding-right: 0 !important;
}

.theme-modal .modal-dialog .modal-content .modal-body {
  padding: 15px;
  background-image: linear-gradient(135deg, #309345 5.77%, #fff 5.77%, #fff 25%, #111 25%, #111 30.77%, #fff 30.77%, #fff 50%, #309345 50%, #309345 55.77%, #fff 55.77%, #fff 75%, #111 75%, #111 80.77%, #fff 80.77%, #fff 100%);
  background-size: 36.77px 36.77px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
  background-color: #fff;
  padding: 45px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
  padding-right: 10px;
  padding-top: 5px;
  position: absolute;
  right: 17px;
  top: 0;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {
  color: #000;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
  border-bottom: 1px dashed #777;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-weight: 700;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
  margin-bottom: 30px;
  text-align: center;
  color: #272727;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  height: 50px;
  border-radius: 0;
  width: 170px;
  letter-spacing: 0.05em;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
  margin-bottom: 40px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
  margin-bottom: 30px;
  text-align: center;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group {
  margin: 0 auto;
  text-align: center;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  max-width: 640px;
  left: 0;
  right: 0;
  margin: 0 auto;
  letter-spacing: 0.05em;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-solid,
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-outline {
  margin-top: 10px;
  text-align: center;
}

.theme-modal .modal-dialog .quick-view-modal button.close {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 5px;
}

.theme-modal .modal-dialog .quick-view-modal .view-detail {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.modal-dialog .modal-content .modal-body .modal-bg {
  padding: 25px;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart .media a img {
  max-width: 150px;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body {
  padding: 0 15px;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
  font-size: 16px;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 i {
  margin-right: 8px;
  color: #fff;
  background: #19a340;
  font-size: 14px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
  display: inline-block;
  font-size: 16px;
  padding: 6px 10px;
  margin: 0 6px;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box {
  padding-left: 10px;
  padding-right: 10px;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail {
  text-align: center;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 {
  font-weight: 700;
  text-transform: capitalize;
}

.modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: #309345;
}

.modal-dialog .modal-content .modal-body .upsell_payment {
  padding-top: 15px;
}

.modal-dialog .modal-content .modal-body .product-upsell {
  margin: 20px 0 10px;
}

.modal-dialog .modal-content .modal-body .product-upsell h4 {
  font-weight: 600;
}

.lookbook-section {
  padding: 25px;
}

.lookbook-section .lookbook-img>div:last-child img {
  margin-top: 25px;
}

.lookbook .lookbook-block {
  position: relative;
}

.lookbook .lookbook-block .lookbook-dot {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 29px;
  height: 29px;
  line-height: 29px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  top: 50%;
  left: 12%;
  background-color: #fff;
}

.lookbook .lookbook-block .lookbook-dot.dot2 {
  top: 70%;
  left: 27%;
}

.lookbook .lookbook-block .lookbook-dot.dot3 {
  top: 28%;
  left: 30%;
}

.lookbook .lookbook-block .lookbook-dot.dot4 {
  top: 67%;
  left: 8%;
}

.lookbook .lookbook-block .lookbook-dot.dot5 {
  top: 40%;
  left: 35%;
}

.lookbook .lookbook-block .lookbook-dot.dot6 {
  top: 80%;
  left: 58%;
}

.lookbook .lookbook-block .lookbook-dot.dot7 {
  top: 67%;
  left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot8 {
  top: 43%;
  left: 39%;
}

.lookbook .lookbook-block .lookbook-dot.dot9 {
  top: 32%;
  left: 50%;
}

.lookbook .lookbook-block .lookbook-dot.dot10 {
  top: 60%;
  left: 50%;
}

.lookbook .lookbook-block .lookbook-dot.dot11 {
  top: 50%;
  left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot12 {
  top: 75%;
  left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot13 {
  top: 40%;
  left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot14 {
  top: 76%;
  left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot15 {
  top: 50%;
  left: 15%;
}

.lookbook .lookbook-block .lookbook-dot:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-color: #fff;
  background-color: rgba(255,255,255,0.5);
  zoom: 1;
  background-color: transparent\9;
  z-index: -1;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.lookbook .lookbook-block .lookbook-dot span {
  text-align: center;
  justify-content: center;
  display: flex;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox {
  visibility: hidden;
  top: -98px;
  left: 150%;
  position: absolute;
  width: 130px;
  background-color: #fff;
  box-shadow: -3px -3px 13px rgba(48,54,61,0.1);
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
  margin-top: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  padding: 5px;
  text-align: center;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 20px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
  font-weight: 700;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
  text-transform: uppercase;
  color: #309345;
  margin-bottom: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff;
  border-bottom: 8px solid transparent;
  top: 41%;
  left: -8px;
}

.lookbook .lookbook-block .lookbook-dot:hover {
  background-color: #309345;
  box-shadow: 0 0 0 3px rgba(255,255,255,0.5);
  z-index: 10;
}

.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
  -webkit-animation: fadeInUp 400ms ease-in-out;
          animation: fadeInUp 400ms ease-in-out;
  visibility: visible;
  transition: all 0.5s ease;
}

.lookbook .lookbook-block .lookbook-dot:hover span {
  color: #fff;
}

.lookbook .lookbook-block .lookbook-dot:hover:before {
  -webkit-transform: scale(1.3);
}

.beauty-about .about-text p {
  text-align: left;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.about-section .small-section {
  padding-top: 20px;
}

.about-section .service .service-block1 img {
  margin-bottom: 10px;
}

.about-section .service .service-block1 h5 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
}

.video-modal .modal-body {
  padding: 0;
}

.video-modal iframe {
  height: 500px;
  width: 100%;
  vertical-align: middle;
  border: none;
}

.video-section .video-img {
  position: relative;
  overflow: hidden;
}

.video-section .video-img img {
  transition: all 0.5s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.video-section .video-img .play-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-section .video-img .play-btn span {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: #309345;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.video-section .video-img .play-btn i {
  font-size: 35px;
  padding-left: 5px;
  color: #fff;
}

.video-section .video-img:hover img {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  transition: all 0.5s ease;
}

.layout-8 {
  padding: 0 80px;
}

.layout-8 .layout-8-bg {
  background-image: url("/assets/images/electronics/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  -webkit-animation: 10s linear 0s normal none infinite animate;
          animation: 10s linear 0s normal none infinite animate;
}

.layout-8 .layout-8-bg .layout7-product .product-box .details-product {
  opacity: 1;
}

.layout-8 .layout-8-bg .layout7-product .product-box:hover .details-product {
  -webkit-animation: none;
          animation: none;
}

.white-bg {
  background-color: #fff;
}

@-webkit-keyframes animate {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 500px;
  }
}

@keyframes animate {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 500px;
  }
}

.pets-box .product-box .img-wrapper .cart-info {
  background-color: rgba(48,147,69,0.5);
  padding: 10px;
  border: none;
  -webkit-transform: perspective(500px) rotateX(90deg);
          transform: perspective(500px) rotateX(90deg);
  bottom: 0;
}

.pets-box .product-box .img-wrapper .cart-info i {
  padding: 5px;
  margin: 0 3px;
  background-color: white;
}

.pets-box .product-box .img-wrapper .cart-info a {
  border-left: none;
}

.pets-box .product-box .product-detail {
  text-align: center;
  background-color: #fafafa;
}

.pets-box .product-box .product-detail .rating {
  margin-top: 0;
  padding-top: 15px;
}

.pets-box .product-box:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.08);
}

.pets-box .product-box:hover .cart-info {
  -webkit-transform: perspective(500px) rotateX(0deg);
          transform: perspective(500px) rotateX(0deg);
}

.pets-box .br.br-default {
  margin: 0 0 15px 0;
  justify-content: center;
  display: flex;
}

.pets-box .product-m .slick-slide .img-wrapper {
  margin: 0;
}

.small-slider .home-slider .home {
  height: 55vh;
}

.small-slider .home-slider .home .slider-contain {
  height: 55vh;
}

.small-slider .home-slider .home .slider-contain h4,
.small-slider .home-slider .home .slider-contain h1 {
  color: #000;
}

.banner-6 .collection-banner .contain-banner.banner-3 h2 {
  color: #222222;
}

.banner-top-cls {
  margin-top: 30px;
}

.j-box .product-box {
  width: 100%;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 10px;
}

.j-box .product-box .cart-info {
  display: flex;
  left: 0;
  background-color: rgba(250,250,250,0.84);
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  bottom: -30px;
  transition: all 0.5s ease;
}

.j-box .product-box .cart-info a {
  border-left: 1px solid #ddd;
}

.j-box .product-box .cart-info a i {
  padding: 12px;
}

.j-box .product-box .product-detail {
  padding-left: 15px;
  padding-bottom: 15px;
  background-color: #fafafa;
}

.j-box .product-box .product-detail .rating {
  margin-top: 0;
  padding-top: 15px;
}

.j-box .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 0;
}

.j-box .product-box:hover .img-wrapper .cart-info button {
  -webkit-animation: none;
          animation: none;
}

.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  -webkit-animation: none;
          animation: none;
}

.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  -webkit-animation: none;
          animation: none;
}

.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  -webkit-animation: none;
          animation: none;
}

.pet-parallax {
  position: relative;
}

.pet-parallax .pet-decor {
  position: absolute;
  left: 110px;
  bottom: -164px;
}

.pet-parallax .banner-contain h4,
.pet-parallax .banner-contain h3,
.pet-parallax .banner-contain p {
  color: #212121;
}

.pet-parallax .banner-contain p {
  max-width: 75%;
  margin: 0 auto;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 0.04em;
}

.pet-parallax .banner-contain h4 {
  letter-spacing: 0.05em;
  padding-top: 0 !important;
  margin-bottom: 0;
  line-height: 1.3;
  margin-top: -7px;
}

.pet-parallax .banner-contain h3 {
  margin: 15px 0;
  font-size: 48px;
}

.pet-parallax .full-banner {
  padding-top: 130px;
  padding-bottom: 130px;
}

footer.pet-layout-footer .white-layout {
  background-color: #f5e1d0;
}

footer.pet-layout-footer .footer-brand-logo {
  margin-bottom: 20px;
}

footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: #309345;
}

footer.pet-layout-footer .sub-footer p {
  padding: 15px 0;
  color: #fff;
}

footer.pet-layout-footer .social-white {
  display: block;
  margin-top: 40px;
  margin-bottom: 30px;
}

.form_search {
  height: 45px;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: #f5f5f5;
  border-radius: 25px;
}

.form_search input {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: darkgrey;
  border: none;
  outline: none;
  background: none;
  padding: 0 10px;
}

.form_search button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
}

.form_search button i {
  font-size: 18px;
  color: #309345;
}

.blog.blog_box .blog-details {
  text-align: left;
  padding: 0;
}

.blog.blog_box .blog-details p {
  font-size: 14px;
  text-transform: none;
  color: #333;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 3px;
}

.blog.blog_box .blog-details a p {
  color: #40494f;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 10px;
}

.blog.blog_box .blog-details .read-cls {
  text-transform: capitalize;
  font-weight: 700;
  color: #000;
}

.header-metro {
  position: absolute;
  width: 100%;
}

.header-metro .top-header {
  background-color: transparent;
  z-index: 9;
  position: relative;
}

.header-metro .metro {
  background-color: white;
  z-index: 9;
  position: relative;
}

.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center;
}

.metro-section .product-box .product-detail h6 {
  color: #525252;
  padding-top: 0;
  margin-top: -2px;
}

.metro-section .product-box .product-detail h4 {
  font-weight: 400;
  color: #000;
}

.metro-section .product-box .product-detail .rating {
  margin-top: 0;
}

.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1;
}

.metro-section .product-box .cart-wrap button {
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: rgba(255,255,255,0.8);
  opacity: 1;
}

.metro-section .product-box .cart-wrap button i {
  padding: 7px;
  color: #333;
}

.metro-section .product-box .cart-wrap a i {
  border-radius: 100%;
  border: 1px solid #fff;
  margin: 5px 0;
  background-color: rgba(255,255,255,0.8);
  padding: 7px;
  color: #333;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px;
}

.metro-section .product-box:hover .cart-wrap button {
  -webkit-animation: none;
          animation: none;
}

.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1;
}

.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  -webkit-animation: fadeInDown 500ms ease-in-out;
          animation: fadeInDown 500ms ease-in-out;
}

.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  -webkit-animation: fadeInDown 700ms ease-in-out;
          animation: fadeInDown 700ms ease-in-out;
}

.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  -webkit-animation: fadeInDown 1000ms ease-in-out;
          animation: fadeInDown 1000ms ease-in-out;
}

.gym-slider .home-slider .home {
  height: 85vh;
}

.gym-slider .home-slider .home .slider-contain {
  height: 85vh;
}

.gym-slider .home-slider .home .slider-contain h4,
.gym-slider .home-slider .home .slider-contain h1 {
  color: #fff;
}

.metro-slider .home-slider .home {
  background-position-y: 70%;
}

.metro-slider .home-slider .home .slider-contain {
  margin-top: 75px;
}

.metro-slider .home-slider .home .slider-contain h4,
.metro-slider .home-slider .home .slider-contain h1 {
  color: #000;
}

.gym-parallax .title1 h2 {
  color: white;
}

.gym-parallax .product-para p {
  color: white;
}

.gym-parallax .instagram-box .overlay {
  background: linear-gradient(-180deg, rgba(1,239,252,0.8) 0%, rgba(72,95,242,0.8) 99%);
}

.gym-product {
  text-align: center;
}

.gym-product .product-box {
  background-color: white;
  padding: 15px;
  box-shadow: 0 0 8px 0 #ddd;
  transition: all 0.5s ease;
}

.gym-product .product-box .img-wrapper {
  position: unset;
}

.gym-product .product-box .img-wrapper .cart-info {
  z-index: 1;
  display: flex;
  left: 0;
  height: 100%;
  align-items: center;
  bottom: 0;
  top: 0;
}

.gym-product .product-box .img-wrapper .cart-info i {
  background: linear-gradient(180deg, #01effc 0%, #485ff2 99%);
  color: rgba(255,255,255,0.6);
  border-radius: 100%;
  margin: 0 5px;
  transition: all 0.5s ease;
}

.gym-product .product-box .img-wrapper .cart-info button:hover i,
.gym-product .product-box .img-wrapper .cart-info a:hover i {
  color: white;
}

.gym-product .product-box .br.br-default {
  display: flex;
  justify-content: center;
}

.gym-product .product-box:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: all 0.5s ease;
}

.gym-product .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
}

.gym-product .product-box:hover .img-wrapper .cart-info button {
  -webkit-animation: fadeInUp 300ms ease-in-out;
          animation: fadeInUp 300ms ease-in-out;
}

.gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  -webkit-animation: fadeInUp 500ms ease-in-out;
          animation: fadeInUp 500ms ease-in-out;
}

.gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  -webkit-animation: fadeInUp 700ms ease-in-out;
          animation: fadeInUp 700ms ease-in-out;
}

.gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  -webkit-animation: fadeInUp 1000ms ease-in-out;
          animation: fadeInUp 1000ms ease-in-out;
}

.gym-product .partition-cls>div:nth-child(-n+4) {
  margin-bottom: 30px;
}

.gym-blog .blog-details {
  text-align: left;
  padding-left: 0;
}

.gym-blog .blog-details p {
  margin-bottom: 3px;
}

.gym-banner .collection-banner .contain-banner h4,
.gym-banner .collection-banner .contain-banner h2 {
  color: #fff;
}

.bg-img-gym {
  background-image: url("/assets/images/gym/bg.jpg");
}

.bg-img-gym .dark-layout {
  background-color: transparent;
}

.bg-img-gym .footer-theme2 p {
  color: #fff;
}

.bg-img-gym .footer-theme2 .contact-details li {
  color: #fff;
}

.bg-img-gym .footer-theme2 .contact-details li a {
  color: #fff;
}

.bg-img-gym .footer-theme2 .footer-link a,
.bg-img-gym .footer-theme2 .footer-link-b a {
  color: #ebebeb;
}

.bg-img-gym .sub-footer.darker-subfooter {
  background-color: black;
}

.bg-img-gym .sub-footer.darker-subfooter p {
  color: #fff;
}

.tap-top.gradient-top {
  background: linear-gradient(180deg, #01effc 0%, #485ff2 99%);
}

.banner-furniture {
  padding-left: 15px;
  padding-right: 15px;
}

.banner-furniture .collection-banner .contain-banner.banner-3 h2 {
  color: #222;
  margin-bottom: 0;
}

.banner-furniture .collection-banner .contain-banner.banner-3 h4 {
  color: #309345;
}

.gym-slider .home-slider .home {
  height: 85vh;
}

.gym-slider .home-slider .home .slider-contain {
  height: 85vh;
}

.gym-slider .home-slider .home .slider-contain h4,
.gym-slider .home-slider .home .slider-contain h1 {
  color: #fff;
}

.main-nav-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-gym {
  position: absolute;
  width: 100%;
}

.header-gym .main-menu {
  background-color: rgba(0,0,0,0.8);
  padding: 0 15px;
  z-index: 1;
}

.header-gym .main-menu .menu-left .navbar {
  padding: 20px 45px 20px 0;
}

.header-gym .main-menu .menu-left .navbar i {
  color: white;
}

.header-gym .main-menu .brand-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-gym .main-menu .onhover-div .show-div {
  top: 70px;
}

.header-gym .sidebar-back i {
  color: black;
}

.header-gym .sm-horizontal>li>a {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
}

.header-gym .sm-horizontal>li>a:hover,
.header-gym .sm-horizontal>li>a:active,
.header-gym .sm-horizontal>li>a:focus,
.header-gym .sm-horizontal>li>a.highlighted {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
}

.header-gym .onhover-div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-gym .onhover-div>div img {
  -webkit-filter: brightness(54);
          filter: brightness(54);
}

.header-gym .top-header {
  z-index: 1;
  position: relative;
  background: transparent;
}

.header-gym .top-header .header-contact li {
  color: #fff;
}

.header-gym .top-header .header-contact li i {
  color: #fff;
}

.header-gym .top-header .header-dropdown li {
  color: #fff;
}

.header-gym .top-header .header-dropdown li a {
  color: #fff;
}

.header-gym .top-header .header-dropdown li a i {
  color: #fff;
  padding-right: 8px;
}

.header-gym .top-header.blue-layout {
  border-bottom: 1px solid #223b7d;
}

.btn-gradient {
  background: #309345;
  background: linear-gradient(180deg, #01effc 0%, #485ff2 99%);
  transition: background 300ms ease-in-out;
  background-size: 300% 100%;
  border: none;
}

.btn-gradient:hover {
  background: linear-gradient(-180deg, #485ff2 0%, #01effc 99%);
  transition: background 300ms ease-in-out;
  color: white;
}

.left-header .top-header {
  padding-left: 300px;
  background-color: #fff;
}

.left-header .top-header .header-dropdown .mobile-wishlist img,
.left-header .top-header .header-dropdown .mobile-account img {
  display: block;
}

.left-header .top-header .header-dropdown .mobile-wishlist i,
.left-header .top-header .header-dropdown .mobile-account i {
  display: none;
}

.left-header .onhover-div {
  padding-top: 15px;
  padding-bottom: 15px;
}

.left-header .onhover-div>div img {
  width: 20px;
  height: auto;
}

.left-header .onhover-div .show-div {
  top: 56px;
}

.left-header .sidenav {
  left: 0;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.25);
}

.left-header .sidenav .sidebar-back {
  display: none;
}

.left-header .sidenav nav {
  background-color: #fff;
}

.left-header .sidenav .pixelstrap>li>a {
  padding: 10px 0 20px 0;
}

.left-header .sidenav .pixelstrap>li>a:hover,
.left-header .sidenav .pixelstrap>li>a:focus {
  padding: 10px 0 20px 0;
  background-color: #fff;
}

.left-header .sidenav .pixelstrap>li .lable-nav {
  top: -12px;
  background-color: #eee;
}

.left-header .sidenav .pixelstrap>li .lable-nav:before {
  border-top: 5px solid #ff4c3b;
}

.left-header .sidenav .pixelstrap>li .lable-nav.grey-lable {
  background-color: #4d6171;
}

.left-header .sidenav .pixelstrap>li .lable-nav.grey-lable:before {
  border-top: 5px solid #4d6171;
}

.left-header .sidenav .left-sidebar_search {
  width: 100%;
  background-color: #fff;
}

.left-header .sidenav .left-sidebar_search input {
  height: 55px;
  width: 95%;
  border: none;
  padding-left: 15px;
  background-color: transparent;
}

.left-header .sidenav .left-sidebar_search .btn-search {
  position: absolute;
  right: 10px;
  top: 20px;
  border: none;
  background-color: transparent;
}

.left-header .sidenav .left-sidebar_center {
  padding: 40px 0 30px 30px;
  background-color: #fff;
}

.left-header .sidenav .bottom-section {
  padding: 70px 25px;
}

.left-header .sidenav .bottom-section .icon_settings li {
  display: inline-block;
}

.left-header .sidenav .bottom-section .icon_settings .shopping-cart {
  position: relative;
}

.left-header .sidenav .bottom-section .icon_settings .shopping-cart .shopping-count {
  width: 18px;
  height: 18px;
  background-color: #ff4c3b;
  color: white;
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -7px;
  right: 7px;
}

.left-header .sidenav .bottom-section ul li {
  display: inline-block;
  padding: 8px 15px 8px 0;
}

.left-header .sidenav .bottom-section ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #222;
}

.left-header .sidenav .bottom-section ul li a i {
  font-size: 18px;
  padding-right: 5px;
  color: #222;
}

.left-header .sidenav .bottom-section .leftside_setting .language,
.left-header .sidenav .bottom-section .leftside_setting .currency {
  display: flex;
}

.left-header .sidenav .bottom-section .leftside_setting .language h5,
.left-header .sidenav .bottom-section .leftside_setting .currency h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  color: #999;
  margin-top: 0;
}

.left-header .sidenav .bottom-section .leftside_setting .language select,
.left-header .sidenav .bottom-section .leftside_setting .currency select {
  margin-left: 10px;
  border: none;
  outline: none;
  text-transform: capitalize;
  color: #222;
}

.left-header .sidenav .bottom-section .leftside_setting h5 {
  margin-top: 10px;
}

.left-header .sidenav .leftside_social {
  position: relative;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #ddd;
}

.left-header .sidenav .leftside_social .footer-social {
  margin-top: 0;
}

.left-header .sidenav .leftside_social .footer-social i {
  font-size: 18px;
}

.left-header .sidenav .leftside_social .footer-social li {
  padding: 10px;
}

.left-header .sidenav .leftside_social .call_us h5 {
  text-align: center;
}

.left-header .sidenav .leftside_social .call_us h5 span {
  display: block;
}

.left-header .main-menu {
  justify-content: flex-end;
}

.left-header .main-menu .brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed #ddd;
}

.left-header .main-menu .menu-right .header-dropdown>li img {
  width: 20px;
  height: auto;
}

.left-header .main-menu .menu-right .icon-nav .onhover-div>img {
  width: 20px;
  height: auto;
}

.left-header .main-menu .menu-left .navbar {
  display: none;
}

.left-header .main-menu .menu-left .mobile-logo {
  display: none;
}

.left-header .search-overlay {
  padding-left: 300px;
}

.left-sidebar_space {
  padding-left: 300px;
}

.header-6 .top-header {
  background-color: #309345;
}

.header-6 .top-header .header-contact li {
  color: #fff;
}

.header-6 .top-header .header-contact li i {
  color: #fff;
}

.header-6 .top-header .header-dropdown li {
  color: #fff;
}

.header-6 .top-header .header-dropdown li a {
  color: #fff;
}

.header-6 .top-header .header-dropdown li a i {
  color: white;
}

.header-6 .mobile-search {
  display: none;
}

.form_search {
  height: 45px;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: #f5f5f5;
  border-radius: 25px;
}

.form_search input {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: darkgrey;
  border: none;
  outline: none;
  background: none;
  padding: 0 10px;
}

.form_search button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
}

.form_search button i {
  font-size: 18px;
  color: #309345;
}

.header-tools {
  position: absolute;
  width: 100%;
  top: 35px;
  z-index: 1;
  background-color: transparent;
}

.header-tools .container {
  padding-left: 30px;
  padding-right: 30px;
}

.header-tools .top-header {
  background-color: transparent;
}

.header-tools .top-header .container {
  background-color: #309345;
}

.header-tools .top-header .header-contact {
  padding: 10px 0;
}

.header-tools .top-header .header-contact li {
  color: #fff;
}

.header-tools .top-header .header-dropdown li {
  color: #fff;
  padding: 10px 25px;
  padding-right: 0;
}

.header-tools .top-header .header-dropdown li a {
  color: #fff;
}

.header-tools .top-header .header-dropdown li a i {
  color: #fff;
}

.header-tools .top-header .header-dropdown li:hover i {
  color: #fff;
}

.header-tools .logo-menu-part {
  background-color: #fff;
  border-bottom: 2px solid #309345;
}

.header-tools .main-menu .menu-left .navbar {
  padding: 25px 45px 25px 0;
}

.header-tools .main-menu .brand-logo {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header-tools .sm-horizontal>li>a {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header-tools .sm-horizontal>li>a:hover,
.header-tools .sm-horizontal>li>a:active,
.header-tools .sm-horizontal>li>a:focus,
.header-tools .sm-horizontal>li>a.highlighted {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header-tools .sm-horizontal li .lable-nav {
  top: 5px;
}

.header-tools .onhover-div {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header-tools .cart_qty_cls {
  top: 16%;
  right: -14px;
}

.tools-bg {
  background-color: #f5f5f5;
}

.tools-bg section {
  background-color: #f5f5f5;
}

.tools-parallax.small-slider .home-slider .home .slider-contain h4,
.tools-parallax.small-slider .home-slider .home .slider-contain h1 {
  color: #fff;
}

.tools-parallax-product.full-banner {
  padding-top: 110px;
  padding-bottom: 110px;
}

.tools-parallax-product .tools-description h3 {
  color: #fff;
  text-transform: uppercase;
  margin-top: -5px;
}

.tools-parallax-product .tools-description .tools-form .search-box {
  width: 75%;
  margin: 20px 0;
}

.tools-parallax-product .tools-description .tools-form .search-box select {
  text-transform: capitalize;
}

.tools-parallax-product .tools-description .tools-form .btn-find {
  width: 75%;
  padding: 7px 29px;
  background-size: 1000px;
}

.tools-parallax-product .tools-grey .slick-prev,
.tools-parallax-product .tools-grey .slick-next {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.tools_slider .home-slider .home .container {
  position: relative;
}

.tools_slider .home-slider .home .tools-parts {
  position: absolute;
  top: 0;
  right: 10%;
  display: flex;
  align-items: center;
  height: 75vh;
  margin-top: 50px;
}

.tools_slider .home-slider .home .tools-parts1 {
  position: absolute;
  top: 0;
  right: 20%;
  display: flex;
  align-items: center;
  height: 75vh;
  margin-top: 50px;
}

.tools_slider .home-slider .slider-contain {
  margin-top: 70px;
  margin-left: 150px;
}

.tools_slider .home-slider .slider-contain h4,
.tools_slider .home-slider .slider-contain h1 {
  color: #fff;
}

.tools-grey .product-m .slick-slide .img-wrapper {
  margin: 0;
}

.tools-grey .product-box {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
  margin: 0 10px;
}

.tools-grey .product-box .img-wrapper .lable-block {
  transition: all 0.5s ease;
}

.tools-grey .product-box .cart-info {
  display: flex;
  left: 0;
  bottom: -30px;
  transition: all 0.5s ease;
}

.tools-grey .product-box .cart-info .mobile-quick-view {
  display: none;
}

.tools-grey .product-box .cart-info a {
  background-color: #eef0f1;
  border-radius: 5px;
}

.tools-grey .product-box .cart-info a i {
  color: #555;
}

.tools-grey .product-box .cart-info a:hover {
  background-color: #309345;
  transition: all 0.5s ease;
}

.tools-grey .product-box .cart-info a:hover i {
  color: #fff;
  transition: all 0.5s ease;
}

.tools-grey .product-box .cart-info button {
  width: 55%;
  background-color: #eef0f1;
  color: #555;
  border-radius: 5px;
  margin: 0 5px;
  transition: all 0.5s ease;
}

.tools-grey .product-box .cart-info button i {
  display: none;
}

.tools-grey .product-box .cart-info button:hover {
  background-color: #309345;
  color: #fff;
  transition: all 0.5s ease;
}

.tools-grey .product-box .quick-view-part {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  background-color: #309345;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
}

.tools-grey .product-box .quick-view-part i {
  font-size: 18px;
  color: #fff;
}

.tools-grey .product-box .product-info {
  padding-left: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  border-top: 1px solid rgba(221,221,221,0.2);
}

.tools-grey .product-box .product-info .rating {
  margin-top: 0;
  padding-top: 15px;
}

.tools-grey .product-box .br.br-default {
  display: flex;
  justify-content: center;
}

.tools-grey .product-box:hover .img-wrapper .front img {
  opacity: 0.05;
  transition: all 0.5s ease;
}

.tools-grey .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 10px;
}

.tools-grey .product-box:hover .img-wrapper .cart-info button {
  -webkit-animation: none;
          animation: none;
}

.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  -webkit-animation: none;
          animation: none;
}

.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  -webkit-animation: none;
          animation: none;
}

.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  -webkit-animation: none;
          animation: none;
}

.tools-grey .product-box:hover .img-wrapper .lable-block {
  display: none;
  transition: all 0.5s ease;
}

.tools-grey .product-box:hover .quick-view-part {
  opacity: 1;
  transition: all 0.5s ease;
}

.tools-grey .product-box:hover .ribbon {
  display: none;
  transition: all 0.5s ease;
}

.tools-grey .ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  transition: all 0.5s ease;
}

.tools-grey .ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #309345;
  box-shadow: 0 3px 10px -5px #000;
  position: absolute;
  top: 19px;
  left: -21px;
}

.tools-grey .ribbon span:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #309345;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #309345;
}

.tools-grey .ribbon span:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #309345;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #309345;
}

.category-tools .category-m .category-wrapper {
  background-color: white;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
}

.banner-tools {
  margin-top: 30px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.banner-tools img {
  transition: all 0.5s ease;
}

.banner-tools:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 0.5s ease;
}

.tools-brand .row {
  background-color: white;
  padding: 25px 0;
  box-shadow: 0 0 8px 0 #ddd;
  margin: 0;
}

.sticky-footer {
  background-image: url("/assets/images/tools/footer.jpg");
}

.sticky-footer .darken-layout {
  background-color: transparent !important;
}

.sticky-footer .darken-layout p {
  color: silver;
}

.sticky-footer .darken-layout .sub-title li a {
  color: silver;
}

.sticky-footer .darken-layout .sub-title .contact-list li,
.sticky-footer .darken-layout .sub-title .contact-list i {
  color: silver;
}

.sticky-footer .darken-layout .footer-social i {
  color: silver;
}

.sticky-footer .dark-subfooter {
  background-color: #141111 !important;
}

.sticky-footer .dark-subfooter p {
  color: silver !important;
}

.green-gradient .top-header {
  background: #309345;
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

.green-gradient .top-header .header-contact {
  padding: 10px 0;
}

.green-gradient .top-header .header-contact li {
  color: #fff;
}

.green-gradient .top-header .header-contact li i {
  color: #fff;
}

.green-gradient .top-header .header-dropdown li {
  color: #fff;
  padding: 10px 25px;
}

.green-gradient .top-header .header-dropdown li a {
  color: #fff;
}

.green-gradient .top-header .header-dropdown li a i {
  color: #fff;
}

.green-gradient .pixelstrap li .lable-nav {
  background: #309345;
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

.service_slide {
  position: relative;
}

.service_slide .home-slider .home {
  background-blend-mode: overlay;
  background-color: rgba(0,0,0,0.4);
}

.service_slide .home-slider .slider-contain {
  height: 60vh;
}

.service_slide .service-home {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.service_slide .service-home .service-block1 {
  background-color: rgba(255,255,255,0.9);
  padding: 25px 0;
}

.service_slide .service-home .service-block1 h4 {
  margin-bottom: 0;
}

.service_slide .service-home .service-block1 p {
  text-transform: capitalize;
}

.service_slide .service-home .service-block1 svg {
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
}

.service_slide .service-home .service-block1:nth-child(even) {
  background-color: rgba(255,255,255,0.85);
}

.tab-left .theme-tab {
  display: flex;
}

.tab-left .theme-tab .left-side {
  width: 30%;
}

.tab-left .theme-tab .left-side .left-tab-title {
  margin-bottom: 38px;
}

.tab-left .theme-tab .left-side .left-tab-title h4 {
  color: #dafe69;
  margin-top: -3px;
}

.tab-left .theme-tab .left-side .left-tab-title h3 {
  color: #fff;
}

.tab-left .theme-tab .left-side .tab-title {
  margin-right: 100px;
  text-align: left;
}

.tab-left .theme-tab .left-side .tab-title a {
  text-transform: capitalize;
}

.tab-left .theme-tab .left-side li {
  width: 100%;
  padding: 7px 0;
  border-bottom: 1px solid #606060;
}

.tab-left .theme-tab .left-side li a {
  color: #fff;
}

.tab-left .theme-tab .left-side li:first-child {
  border-top: 1px solid #606060;
}

.tab-left .theme-tab .left-side li.current a {
  color: #dafe69;
}

.tab-left .theme-tab .tab-content-cls {
  width: 70%;
}

.product_section {
  background-image: url("/assets/images/marijuana/leaf-bg.jpg");
}

.border-box.tools-grey .ribbon span {
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

.border-box.tools-grey .product-box {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #ddd;
}

.border-box.tools-grey .product-box .cart-info a:hover {
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

.border-box.tools-grey .product-box .cart-info button:hover {
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

.border-box.tools-grey .product-box .quick-view-part {
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

.detail-cannabis .detail_section {
  text-align: center;
}

.detail-cannabis .detail_section>div {
  box-shadow: -1px 2px 5px 1px #ececec;
  padding: 45px 25px;
  background-color: white;
}

.detail-cannabis .detail_section p {
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.detail-cannabis .detail_section h4 {
  font-weight: 700;
}

.detail-cannabis .detail_section svg {
  width: 50px;
  margin-bottom: 15px;
  fill: #309345;
}

footer.footer-5 {
  background-image: linear-gradient(to right, #01effc, #485ff2);
}

footer.footer-5 .dark-layout {
  background-color: transparent;
}

footer.footer-5 .sub-footer {
  background-color: transparent;
}

footer.footer-5 .sub-footer>.container {
  border-top: 1px solid #fff;
}

footer.footer-5 .sub-footer p {
  color: #fff;
}

footer.footer-5 .footer-theme2 p {
  color: #fff;
}

footer.footer-5 .footer-theme2 .contact-details li {
  color: #fff;
}

footer.footer-5 .footer-theme2 .contact-details li a {
  color: #fff;
}

footer.footer-5 .footer-theme2 .subscribe-block {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.tap-top.green-top {
  background-image: linear-gradient(to right, #01effc, #485ff2);
  border: 1px solid rgba(255,255,255,0.5);
}

.quickviewm .modal-dialog .modal-content .modal-body {
  background-image: none;
}

#toast-container>div {
  opacity: 1;
}

.toast {
  font-size: initial !important;
  border: initial !important;
  -webkit-backdrop-filter: blur(0) !important;
          backdrop-filter: blur(0) !important;
}

.toast-success {
  background-color: #51A351 !important;
}

.toast-error {
  background-color: #BD362F !important;
}

.toast-info {
  background-color: #2F96B4 !important;
}

.toast-warning {
  background-color: #F89406 !important;
}

.addcart_btm_popup {
  position: fixed;
  right: 0;
  width: 42px;
  height: 41px;
  color: #000000;
  cursor: pointer;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  text-decoration: none;
  background: #ffffff;
  top: 33%;
  border: 1px solid white;
  box-shadow: 0 0 5px rgba(4,4,4,0.28);
}

.addcart_btm_popup .fixed_cart {
  position: relative;
  z-index: 99;
  display: block;
  float: right;
  font-size: 18px;
  text-decoration: none;
  padding-left: 8px;
  white-space: nowrap;
}

.addcart_btm_popup .fixed_cart .cart_qty_cls {
  font-weight: 400;
}

footer {
  position: relative;
}

footer .footer-lable {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #309345;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .footer-lable:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10px solid #0a200f;
  border-right: 10px solid transparent;
  top: 0;
  right: -10px;
}

footer .footer-lable i {
  color: white;
  font-size: 25px;
}

footer .footer-theme .footer-mobile-title {
  display: none;
}

footer .darken-layout p {
  color: #938d8c;
}

footer .darken-layout .sub-title .footer-title.active .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}

footer .darken-layout .sub-title .footer-title.active .according-menu.before {
  content: "\f068";
}

footer .darken-layout .sub-title h4 {
  color: #fff;
}

footer .darken-layout .sub-title li a {
  color: #938d8c;
}

footer .darken-layout .sub-title .contact-list i {
  color: #938d8c;
}

footer .darken-layout .sub-title .contact-list li {
  color: #938d8c;
}

footer .darken-layout .footer-social i {
  color: #938d8c;
}

footer p {
  color: #777777;
  margin-bottom: 5px;
}

.footer-white .light-layout {
  background-color: white;
}

.footer-white .sub-footer .row {
  border-top: 1px solid #ddd;
}

.footer-light .subscribe {
  display: flex;
  height: 100%;
  align-items: center;
  border-right: 1px solid #ddd;
  text-align: left;
}

.footer-light .subscribe p {
  line-height: 1;
}

.footer-light .subscribe h4 {
  margin-bottom: 10px;
}

.footer-light .subscribe-form {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.subscribe {
  text-align: center;
}

.subscribe h4 {
  color: #222;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
}

.subscribe h4 i {
  color: #309345;
  padding-right: 5px;
  font-size: 23px;
}

.subscribe-form {
  display: inline-flex;
}

.subscribe-form .form-group {
  margin-bottom: 0;
}

.subscribe-form .form-control {
  padding: 13px;
  width: 345px;
  border-radius: 0;
  border: 1px solid #eee;
}

.subscribe-form .form-control.name-cls {
  width: 265px;
}

.subscribe-form.classic-form .form-control {
  border-radius: 0;
  border: none;
  background-color: #f5f2f2;
  margin-left: 15px;
}

.subscribe-form.classic-form .btn-solid {
  margin-bottom: 0 !important;
}

.footer-social,
.social-white {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.footer-social li,
.social-white li {
  display: inline-block;
  padding-right: 45px;
}

.footer-social i,
.social-white i {
  font-size: 20px;
  color: #000;
  transition: 0.5s ease;
}

.footer-social i:hover,
.social-white i:hover {
  color: #309345 !important;
  transition: 0.5s ease;
}

.social-white li {
  padding-left: 22px;
  padding-right: 22px;
}

.footer-theme .footer-logo {
  margin-bottom: 18px;
}

.footer-theme .sub-title h4 {
  color: #222;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
}

.footer-theme .sub-title li {
  position: relative;
  display: inherit;
  padding-top: 13px;
  text-transform: capitalize;
  font-size: 14px;
  color: #777;
}

.footer-theme .sub-title li a {
  position: relative;
  color: #777;
}

.footer-theme .sub-title li a:before {
  position: absolute;
  top: 20px;
  content: "";
  height: 2px;
  width: 0;
  background-color: #309345;
  transition: 0.5s ease;
}

.footer-theme .sub-title li:hover a:before {
  width: 100%;
  height: 2px;
  transition: 0.5s ease;
}

.footer-theme .sub-title .contact-list i {
  position: absolute;
  left: 0;
  top: 21px;
}

.footer-theme .sub-title .contact-list li {
  position: relative;
  padding-left: 25px;
  line-height: 28px;
}

.footer-theme .sub-title .contact-list li a:hover {
  color: #309345;
  transition: all 0.5s ease;
}

.footer-theme .sub-title .contact-list li:hover {
  color: #999;
  transition: all 0.3s ease;
}

.footer-theme .sub-title .contact-list li:hover a:before {
  display: none;
}

.footer-theme .sub-title .contact-list li:last-child {
  margin-bottom: -5px;
}

.sub-footer {
  background-color: #fff;
}

.sub-footer.dark-subfooter {
  background-color: #2e2726;
}

.sub-footer.dark-subfooter p {
  color: #938d8c;
}

.sub-footer.darker-subfooter {
  background-color: #25221e;
}

.sub-footer.darker-subfooter p {
  color: #7f786d;
}

.sub-footer.black-subfooter {
  background-color: #222;
}

.sub-footer.black-subfooter p {
  color: #777;
}

.sub-footer p {
  color: #777;
  margin-bottom: 0;
  padding: 20px 0;
}

.sub-footer .payment-card-bottom {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sub-footer .payment-card-bottom li {
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
}

.footer-theme2 {
  text-align: center;
}

.footer-theme2 .footer-mobile-title {
  display: none !important;
}

.footer-theme2 .contact-details li a {
  color: #7f786d;
}

.footer-theme2 .contact-details li a:hover {
  color: #309345;
  transition: all 0.5s ease;
}

.footer-theme2.footer-border {
  border: 20px solid #f1f5f4;
}

.footer-theme2 .footer-logo {
  margin-bottom: 18px;
}

.footer-theme2 p {
  line-height: 32px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  color: #7f786d;
}

.footer-theme2 h4 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.footer-theme2 .contact-details li {
  display: block;
  color: #7f786d;
  padding: 0 50px;
  line-height: 2.2;
}

.footer-theme2 .contact-details li:last-child {
  margin-bottom: -8px;
}

.footer-theme2 .subscribe-block {
  border-left: 1px solid #38352f;
  border-right: 1px solid #38352f;
  padding: 10px 110px;
}

.footer-theme2 .subscribe-block h2 {
  color: #fff;
  margin-bottom: 30px;
}

.footer-theme2 .subscribe-block .btn-solid {
  margin-top: 10px;
}

.footer-theme2 .subscribe-block .form-group {
  margin-bottom: 0;
}

.footer-theme2 .subscribe-block .form-control {
  text-align: center;
  letter-spacing: 5px;
  font-size: 12px;
  padding: 15px 0;
  border-radius: 0;
}

.footer-theme2 .footer-link.link-white h4,
.footer-theme2 .footer-link-b.link-white h4 {
  color: #222;
}

.footer-theme2 .footer-link.link-white a,
.footer-theme2 .footer-link-b.link-white a {
  color: #777;
  transition: 0.5s ease;
}

.footer-theme2 .footer-link.link-white a:hover,
.footer-theme2 .footer-link-b.link-white a:hover {
  color: #2d2a25;
  transition: 0.5s ease;
}

.footer-theme2 .footer-link h4,
.footer-theme2 .footer-link-b h4 {
  padding-bottom: 20px;
}

.footer-theme2 .footer-link li,
.footer-theme2 .footer-link-b li {
  padding-right: 15px;
  padding-left: 15px;
  text-transform: capitalize;
}

.footer-theme2 .footer-link a,
.footer-theme2 .footer-link-b a {
  color: #7f786d;
  transition: 0.5s ease;
}

.footer-theme2 .footer-link a:hover,
.footer-theme2 .footer-link-b a:hover {
  color: #f5f2f2;
  transition: 0.5s ease;
}

.footer-theme2 .footer-link-b h4 {
  margin-top: 50px;
}

.footer-theme2.section-light h2,
.footer-theme2.section-light h4 {
  color: #222;
}

.footer-theme2.section-light .footer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.footer-theme2.section-light .footer-block h4 {
  padding-bottom: 25px;
}

.footer-theme2.section-light .footer-block .contact-details li a {
  color: #7f786d;
}

.footer-theme2.section-light .footer-block .contact-details li a:hover {
  color: #309345;
  transition: all 0.5s ease;
}

.footer-theme2.section-light .footer-block .subscribe-white {
  background-color: #3db957;
  width: 100%;
  padding: 85px 65px;
  border-left: 20px solid #f1f5f4;
  border-right: 20px solid #f1f5f4;
}

.footer-theme2.section-light .footer-block .subscribe-white .form-group {
  margin-bottom: 0;
}

.footer-theme2.section-light .footer-block .subscribe-white .form-control {
  border: none;
  border-radius: 0;
}

.footer-theme2.section-light .footer-block .subscribe-white h2 {
  margin-bottom: 30px;
  margin-top: -5px;
}

.footer-theme2.section-light .footer-block .subscribe-white .btn-solid {
  margin-top: 10px;
}

.footer-theme2.section-light .footer-block .subscribe-white .form-control {
  text-align: center;
  letter-spacing: 5px;
  font-size: 12px;
  padding: 15px 0;
}

.cart-qty-cls {
  position: absolute;
  background: #309345;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  top: 30%;
  right: -8px;
  padding: 3px;
}

@media (max-width: 577px) {
  .cart-qty-cls {
    position: absolute;
    background: #309345;
    width: 14px;
    height: 14px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 10px;
    line-height: 8px;
    top: 50%;
    right: -8px;
    padding: 3px;
  }
}

.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
}

.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: #309345;
  border-radius: 50%;
}

.loader-wrapper .loader:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  border: 0 solid white;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: loading 1000ms ease-out forwards infinite;
          animation: loading 1000ms ease-out forwards infinite;
  transition: all 0.3s ease;
}

@-webkit-keyframes loading {
  0% {
    border: 0 solid white;
    transition: all 0.3s ease;
  }

  20% {
    border: 8px solid white;
    width: 0;
    height: 0;
    transition: all 0.3s ease;
  }

  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
  }
}

@keyframes loading {
  0% {
    border: 0 solid white;
    transition: all 0.3s ease;
  }

  20% {
    border: 8px solid white;
    width: 0;
    height: 0;
    transition: all 0.3s ease;
  }

  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
  }
}

.tap-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 8;
  display: none;
  color: #fff;
  text-align: center;
  background: #309345;
  border: none;
  font-size: 22px;
  padding: 7px;
  cursor: pointer;
}

.tap-top.top-cls {
  bottom: 130px;
}

.color1 {
  background: #ff4c3b;
}

.color2 {
  background: #3fdda7;
}

.color3 {
  background: #f0b54d;
}

.color4 {
  background: #e4604a;
}

.color5 {
  background: #d4b196;
}

.color6 {
  background: #866e6c;
}

.color7 {
  background: #cc2121;
}

.color8 {
  background: #dc457e;
}

.color9 {
  background: #6d7e87;
}

.color10 {
  background: #fa869b;
}

.color11 {
  background: #81ba00;
}

.color12 {
  background: #fe816d;
}

.color13 {
  background: #01effc;
}

.color14 {
  background: #5d7227;
}

.color15 {
  background: #ff9944;
}

.color16 {
  background: #5fcbc4;
}

.color17 {
  background: #e38888;
}

.color18 {
  background: #000000;
}

.color-picker {
  position: fixed;
  right: -190px;
  top: calc(38vh + 220px);
  width: 190px;
  z-index: 1;
  transition: all .3s ease;
}

.color-picker .settings-header {
  background: #f3f3f3;
  padding: 5px 15px;
  box-shadow: 0px -10px 20px -5px rgba(220,220,220,0.62);
}

.color-picker .settings-header h3 {
  color: black;
  margin: 0;
  font-size: 20px;
  padding: 4px 9px;
}

.color-picker .section {
  background: white;
  padding: 10px;
  border: 1px solid #f3f3f3;
  overflow: auto;
}

.color-picker .section i {
  font-size: 16px;
  margin-right: 10px;
}

.color-picker a.handle {
  position: absolute;
  right: 190px;
  width: 45px;
  height: 41px;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: -1;
  text-decoration: none;
  background-color: #f9f9f9;
  box-shadow: -5.985px -0.419px 30px 0 rgba(0,0,0,0.08),inset -2px 0 10px 0 rgba(0,0,0,0.04);
  top: -80px;
  color: #000;
}

.color-picker a.handle i {
  margin-left: 5px;
}

.color-picker .colors a {
  width: 32px;
  height: 32px;
  margin: 5px;
  float: left;
  transition: all ease-in 0.3s;
}

.color-picker .skin a {
  display: block;
  width: 70px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  float: left;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid;
}

.color-picker .skin a.actt {
  border: 2px white solid;
}

.dark-light {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  box-shadow: 0px 0px 5px 0px rgba(154,154,154,0.54);
  top: calc(50% + 34px);
  cursor: pointer;
}

.setting-sidebar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #f7f7f7;
  width: 40px;
  height: 40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 5px 0px rgba(154,154,154,0.54);
  transition: all 0.5s ease;
  z-index: 1;
}

.setting-sidebar i {
  -webkit-animation: ring-rotate 3s infinite linear;
          animation: ring-rotate 3s infinite linear;
  font-size: 20px;
  color: #3c3c3c;
  margin-left: 10px;
}

.setting-sidebar.open-icon {
  right: 320px;
  transition: all 0.5s ease;
}

.setting_box_body {
  position: relative;
  background-color: white;
  z-index: 9;
  height: 100vh;
}

.setting-box {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 320px;
  right: -360px;
  background-color: white;
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 rgba(77,77,77,0.28);
  transition: all 0.5s ease;
}

.setting-box ::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}

.setting-box ::-webkit-scrollbar-track {
  background: #ddd;
}

.setting-box ::-webkit-scrollbar-thumb {
  background: #888;
}

.setting-box ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.setting-box.open-setting {
  right: 0;
  transition: all 0.5s ease;
}

.setting-box.open-setting .overlay {
  visibility: visible;
  transition: all 0.5s ease;
}

.setting-box .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: inset 0px 0px 6px rgba(115,115,115,0.09);
  background-color: #f3f3f3;
}

.setting-box .setting-title {
  padding: 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 0;
}

.setting-box .setting-title h4 {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
}

.setting-box .setting-title.active .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}

.setting-box .setting-title.active .according-menu:before {
  content: "\f068";
  position: absolute;
  right: 2px;
  top: 2px;
}

.setting-box .setting-title .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}

.setting-box .setting-title .according-menu:before {
  content: "\f067";
  position: absolute;
  right: 2px;
  top: 2px;
}

.setting-box .setting-contant {
  padding: 20px;
  border-bottom: 1px solid #efefef;
}

.setting-box .setting-contant .setting_buttons .setting_btn {
  background-color: #309345;
  color: white;
  width: 100%;
}

.setting-box .setting-contant .setting_buttons li:first-child {
  width: 49%;
}

.setting-box .setting-contant .setting_buttons li.active {
  box-shadow: 0px 0px 5px 0px #929292;
}

.setting-box .setting-contant .setting_buttons li:last-child {
  margin-left: 10px;
  width: 46%;
}

.setting-box .setting-contant .color-box li {
  margin: 3px;
  width: 32px;
  height: 32px;
  display: inline-grid;
}

.setting-box .setting-contant .color-box.active {
  position: relative;
}

.setting-box .setting-contant .color-box.active li {
  box-shadow: 0px 0px 5px 0px #929292;
}

.setting-box .setting-contant .color-box.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 12px;
  height: 15px;
  width: 15px;
  background-size: 80%;
  background-repeat: no-repeat;
  position: absolute;
  left: 13px;
  -webkit-filter: invert(100);
          filter: invert(100);
}

.setting-box .buy_btn {
  padding: 20px;
}

.setting-box .buy_btn a {
  background-color: #f3f3f3;
  color: #3c3c3c;
  padding: 10px;
  transition: all 0.5s ease;
}

.setting-box .buy_btn a i {
  margin-right: 5px;
  font-size: 18px;
}

.setting-box .buy_btn a img {
  -webkit-filter: grayscale(100);
          filter: grayscale(100);
}

.setting-box .overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}

.demo-section .title-text h3 {
  color: #309345;
  margin-bottom: 24px;
  display: inline-block;
  padding-bottom: 5px;
  font-size: 40px;
  position: relative;
}

.demo-section .title-text h3 i {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: -20px;
}

.demo-section .demo-effects {
  margin-bottom: 30px;
}

.demo-section .demo-effects h4 {
  text-transform: capitalize;
}

.demo-section .demo-effects .set-position {
  display: block;
}

.demo-section .demo-effects>div {
  text-decoration: none;
  color: black;
}

.demo-section .demo-effects>div .layout-container {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px #ececec;
}

.demo-section .demo-effects>div .layout-container.demo1 {
  background-image: url("/assets/images/landing-page/demo/1.jpg");
}

.demo-section .demo-effects>div .layout-container.demo2 {
  background-image: url("/assets/images/landing-page/demo/2.jpg");
}

.demo-section .demo-effects>div .layout-container.demo3 {
  background-image: url("/assets/images/landing-page/demo/3.jpg");
}

.demo-section .demo-effects>div .layout-container.demo4 {
  background-image: url("/assets/images/landing-page/demo/4.jpg");
}

.demo-section .demo-effects>div .layout-container.demo5 {
  background-image: url("/assets/images/landing-page/demo/5.jpg");
}

.demo-section .demo-effects>div .layout-container.demo6 {
  background-image: url("/assets/images/landing-page/demo/6.jpg");
}

.demo-section .demo-effects>div .layout-container.demo7 {
  background-image: url("/assets/images/landing-page/demo/7.jpg");
}

.demo-section .demo-effects>div .layout-container.demo8 {
  background-image: url("/assets/images/landing-page/demo/8.jpg");
}

.demo-section .demo-effects>div .layout-container.demo9 {
  background-image: url("/assets/images/landing-page/demo/9.jpg");
}

.demo-section .demo-effects>div .layout-container.demo10 {
  background-image: url("/assets/images/landing-page/demo/10.jpg");
}

.demo-section .demo-effects>div .layout-container.demo11 {
  background-image: url("/assets/images/landing-page/demo/11.jpg");
}

.demo-section .demo-effects>div .layout-container.demo12 {
  background-image: url("/assets/images/landing-page/demo/12.jpg");
}

.demo-section .demo-effects>div .layout-container.demo13 {
  background-image: url("/assets/images/landing-page/demo/13.jpg");
}

.demo-section .demo-effects>div .layout-container.demo14 {
  background-image: url("/assets/images/landing-page/demo/14.jpg");
}

.demo-section .demo-effects>div .layout-container.demo15 {
  background-image: url("/assets/images/landing-page/demo/15.jpg");
}

.demo-section .demo-effects>div .layout-container.demo16 {
  background-image: url("/assets/images/landing-page/demo/16.jpg");
}

.demo-section .demo-effects>div .layout-container.demo17 {
  background-image: url("/assets/images/landing-page/demo/17.jpg");
}

.demo-section .demo-effects>div .layout-container.demo18 {
  background-image: url("/assets/images/landing-page/demo/18.jpg");
}

.demo-section .demo-effects>div .layout-container.demo19 {
  background-image: url("/assets/images/landing-page/demo/19.jpg");
}

.demo-section .demo-effects>div .layout-container.demo20 {
  background-image: url("/assets/images/landing-page/demo/20.jpg");
}

.demo-section .demo-effects>div .layout-container.demo21 {
  background-image: url("/assets/images/landing-page/demo/21.jpg");
}

.demo-section .demo-effects>div .layout-container.demo22 {
  background-image: url("/assets/images/landing-page/demo/22.jpg");
}

.demo-section .demo-effects>div .layout-container.demo23 {
  background-image: url("/assets/images/landing-page/demo/23.jpg");
}

.demo-section .demo-effects>div .layout-container.demo24 {
  background-image: url("/assets/images/landing-page/demo/24.jpg");
}

.demo-section .demo-effects>div .layout-container.demo25 {
  background-image: url("/assets/images/landing-page/demo/25.jpg");
}

.demo-section .demo-effects>div .layout-container.demo26 {
  background-image: url("/assets/images/landing-page/demo/26.jpg");
}

.demo-section .demo-effects>div .layout-container.demo27 {
  background-image: url("/assets/images/landing-page/demo/27.jpg");
}

.demo-section .demo-effects>div .layout-container.demo28 {
  background-image: url("/assets/images/landing-page/demo/28.jpg");
}

.demo-section .demo-effects>div .layout-container.demo29 {
  background-image: url("/assets/images/landing-page/demo/29.jpg");
}

.demo-section .demo-effects>div .layout-container.demo30 {
  background-image: url("/assets/images/landing-page/demo/30.jpg");
}

.demo-section .demo-effects>div .layout-container.demo31 {
  background-image: url("/assets/images/landing-page/demo/31.jpg");
}

.demo-section .demo-effects>div .layout-container.demo32 {
  background-image: url("/assets/images/landing-page/demo/32.jpg");
}

.demo-section .demo-effects>div .layout-container.demo33 {
  background-image: url("/assets/images/landing-page/demo/33.jpg");
}

.demo-section .demo-effects>div .layout-container.demo34 {
  background-image: url("/assets/images/landing-page/demo/34.jpg");
}

.demo-section .demo-effects>div .layout-container.demo35 {
  background-image: url("/assets/images/landing-page/demo/35.jpg");
}

.demo-section .demo-effects>div .layout-container.demo36 {
  background-image: url("/assets/images/landing-page/demo/36.jpg");
}

.demo-section .demo-effects>div .layout-container.demo37 {
  background-image: url("/assets/images/landing-page/demo/37.jpg");
}

.demo-section .demo-effects>div .layout-container.demo38 {
  background-image: url("/assets/images/landing-page/demo/38.jpg");
}

.demo-section .demo-effects>div .layout-container.demo39 {
  background-image: url("/assets/images/landing-page/demo/39.jpg");
}

.demo-section .demo-effects>div .layout-container.demo40 {
  background-image: url("/assets/images/landing-page/demo/40.jpg");
}

.demo-section .demo-effects>div .layout-container.demo41 {
  background-image: url("/assets/images/landing-page/demo/41.jpg");
}

.demo-section .demo-effects>div .layout-container.demo42 {
  background-image: url("/assets/images/landing-page/demo/42.jpg");
}

.demo-section .demo-effects>div .layout-container.demo43 {
  background-image: url("/assets/images/landing-page/demo/43.jpg");
}

.demo-section .demo-effects>div .layout-container.demo44 {
  background-image: url("/assets/images/landing-page/demo/44.jpg");
}

.demo-section .demo-effects>div .layout-container.demo45 {
  background-image: url("/assets/images/landing-page/demo/45.jpg");
}

.demo-section .demo-effects>div .layout-container.demo46 {
  background-image: url("/assets/images/landing-page/demo/46.jpg");
}

.demo-section .demo-effects>div .layout-container.demo47 {
  background-image: url("/assets/images/landing-page/demo/47.jpg");
}

.demo-section .demo-effects>div .layout-container.demo48 {
  background-image: url("/assets/images/landing-page/demo/48.jpg");
}

.demo-section .demo-effects>div .layout-container.demo49 {
  background-image: url("/assets/images/landing-page/demo/49.jpg");
}

.demo-section .demo-effects>div .layout-container.demo50 {
  background-image: url("/assets/images/landing-page/demo/50.jpg");
}

.demo-section .demo-effects>div .layout-container.demo51 {
  background-image: url("/assets/images/landing-page/demo/51.jpg");
}

.demo-section .demo-effects>div .layout-container.demo52 {
  background-image: url("/assets/images/landing-page/demo/52.jpg");
}

.demo-section .demo-effects>div .layout-container.demo53 {
  background-image: url("/assets/images/landing-page/demo/53.jpg");
}

.demo-section .demo-effects>div .layout-container.demo54 {
  background-image: url("/assets/images/landing-page/demo/54.jpg");
}

.demo-section .demo-effects>div .layout-container.demo55 {
  background-image: url("/assets/images/landing-page/demo/55.jpg");
}

.demo-section .demo-effects>div .layout-container.demo56 {
  background-image: url("/assets/images/landing-page/demo/56.jpg");
}

.demo-section .demo-effects>div .layout-container.demo57 {
  background-image: url("/assets/images/landing-page/demo/57.jpg");
}

.demo-section .demo-effects>div .layout-container.demo58 {
  background-image: url("/assets/images/landing-page/demo/58.jpg");
}

.demo-section .demo-effects>div .layout-container.demo59 {
  background-image: url("/assets/images/landing-page/demo/59.jpg");
}

.demo-section .demo-effects>div .layout-container.demo60 {
  background-image: url("/assets/images/landing-page/demo/60.jpg");
}

.demo-section .demo-effects>div .layout-container.demo61 {
  background-image: url("/assets/images/landing-page/demo/61.jpg");
}

.demo-section .demo-effects>div .layout-container.demo62 {
  background-image: url("/assets/images/landing-page/demo/62.jpg");
}

.demo-section .demo-effects>div .layout-container.demo63 {
  background-image: url("/assets/images/landing-page/demo/63.jpg");
}

.demo-section .demo-effects>div .layout-container.demo64 {
  background-image: url("/assets/images/landing-page/demo/64.jpg");
}

.demo-section .demo-effects>div .layout-container.demo65 {
  background-image: url("/assets/images/landing-page/demo/65.jpg");
}

.demo-section .demo-effects>div .layout-container.demo66 {
  background-image: url("/assets/images/landing-page/demo/66.jpg");
}

.demo-section .demo-effects>div .layout-container.demo67 {
  background-image: url("/assets/images/landing-page/demo/67.jpg");
}

.demo-section .demo-effects>div .layout-container.demo68 {
  background-image: url("/assets/images/landing-page/demo/68.jpg");
}

.demo-section .demo-effects>div .layout-container.demo69 {
  background-image: url("/assets/images/landing-page/demo/69.jpg");
}

.demo-section .demo-effects>div .layout-container.demo70 {
  background-image: url("/assets/images/landing-page/demo/70.jpg");
}

.demo-section .demo-effects>div .demo-text h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #000000;
}

.demo-section .demo-effects>div .demo-text h4 span {
  font-weight: 500;
}

.demo-section .demo-effects>div .demo-text .demo-btn {
  margin-top: 10px;
}

.demo-section .demo-effects>div .demo-text .demo-btn .btn {
  border: 1px solid #ddd;
  background-color: white;
  width: 90px;
  color: #309345;
  padding: 5px 10px;
  border-radius: 30px;
}

.demo-section .demo-effects>div .demo-text .demo-btn .btn:hover {
  background-color: #309345;
  color: white;
}

.demo-section .demo-effects:last-child {
  margin-bottom: 0;
}

.demo-section .demo-effects.effect-2 .layout-container {
  background-size: cover;
  background-repeat: no-repeat;
}

.demo-section .demo-effects.effect-2:hover .layout-container {
  background-position: top !important;
}

.demo-section .demo-effects.effect-3 .layout-container {
  background-size: cover;
  background-repeat: no-repeat;
}

.demo-section .demo-effects.effect-3:hover .layout-container {
  background-position: top !important;
}

.demo-section .demo-effects:hover .layout-container {
  background-position: center 100% !important;
}

@-webkit-keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.sec-position {
  position: relative;
  top: -80px;
}

.color-picker {
  position: fixed;
  right: -190px;
  top: 35%;
  width: 190px;
  z-index: 1;
  transition: all .3s ease;
}

.color-picker .settings-header {
  background: #f3f3f3;
  padding: 5px 15px;
  box-shadow: 0px -10px 20px -5px rgba(220,220,220,0.62);
}

.color-picker .settings-header h3 {
  color: black;
  margin: 0;
  font-size: 20px;
  padding: 4px 9px;
}

.color-picker .section {
  background: white;
  padding: 10px;
  border: 1px solid #f3f3f3;
  overflow: auto;
}

.color-picker .section i {
  font-size: 16px;
  margin-right: 10px;
}

.color-picker a.handle {
  position: absolute;
  right: 190px;
  width: 42px;
  height: 41px;
  color: #000;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: -1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  text-decoration: none;
  background: #ffffff;
  top: -80px;
  border: 1px solid white;
  box-shadow: 0 0 5px rgba(4,4,4,0.28);
}

.color-picker a.handle i {
  margin-left: 5px;
}

.color-picker a.handle:hover {
  color: #309345;
}

.color-picker .colors a {
  width: 32px;
  height: 32px;
  margin: 5px;
  float: left;
  transition: all ease-in 0.3s;
}

.color-picker .skin a {
  display: block;
  width: 70px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  float: left;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid;
}

.color-picker .skin a.actt {
  border: 2px white solid;
}

.handle i {
  -webkit-animation: ring-rotate 3s infinite linear;
          animation: ring-rotate 3s infinite linear;
}

.handle:hover {
  background-color: white;
}

.error-section {
  padding: 130px 0 140px;
  text-align: center;
}

.error-section h1 {
  font-size: 200px;
  font-weight: 900;
  margin: 0;
  line-height: 0.8;
  color: #aaa;
  margin-top: -11px;
}

.error-section h2 {
  color: #aaa;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 40px 0;
}

.breadcrumb-section {
  background-color: #f8f8f8;
  padding: 30px 0;
}

.breadcrumb-section .page-title {
  display: flex;
  height: 100%;
  align-items: center;
}

.breadcrumb-section .page-title h1,
.breadcrumb-section .page-title h2 {
  font-size: 16px;
  margin-bottom: 0;
}

.breadcrumb-section .breadcrumb {
  background-color: transparent;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 14px;
  color: #555;
  font-weight: 600;
  margin: 0;
}

.breadcrumb-section .breadcrumb a {
  color: #555;
  font-weight: 600;
}

.about-head {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  height: 100%;
}

.about-head h2 {
  color: #309345;
  margin-bottom: 0;
}

.about-page p {
  margin-bottom: -7px;
}

.about-page .banner-section {
  border-radius: 5px;
  overflow: hidden;
}

.about-page h4 {
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
}

.about-page p {
  line-height: 2;
  letter-spacing: 0.03em;
}

.testimonial {
  background-color: #f7f7f7;
}

.testimonial .testimonial-slider .media img {
  border-radius: 100%;
  border: 1px solid #ddd;
  padding: 5px;
  height: 130px;
  width: 130px;
}

.testimonial .testimonial-slider .media h5 {
  font-weight: 700;
  color: #309345;
  margin-bottom: 0;
  margin-top: 7px;
}

.testimonial .testimonial-slider .media h6 {
  margin-bottom: -4px;
}

.testimonial .testimonial-slider .media .media-body {
  padding: 30px 75px 30px 30px;
}

.testimonial .testimonial-slider .media .media-body p {
  line-height: 2;
  letter-spacing: 0.03em;
}

.testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
  border-left: 1px solid #dddddd;
  padding-left: 50px;
}

.team {
  position: relative;
}

.team img {
  margin: 0 auto;
}

.team .slick-prev,
.team .slick-next {
  position: absolute;
  top: -45px;
}

.team .slick-prev:before,
.team .slick-next:before {
  font: normal normal normal 30px/1 FontAwesome;
  color: #222;
  opacity: 0.6;
}

.team .slick-prev {
  left: unset;
  right: 20px;
}

.team .slick-prev:before {
  content: "\f104";
}

.team .slick-next {
  right: 0;
}

.team .slick-next:before {
  content: "\f105";
}

.team h2 {
  color: #222;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 10px;
}

.team h4,
.team h6 {
  text-align: center;
}

.team h4 {
  color: #309345;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
}

.team h6 {
  margin-bottom: 0;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.collection-filter-block {
  margin-bottom: 30px;
  padding: 0 30px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.collection-filter-block .product-service {
  padding: 30px 0;
}

.collection-filter-block .product-service .media {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.collection-filter-block .product-service .media svg {
  height: 40px;
  width: 40px;
}

.collection-filter-block .product-service .media svg path {
  fill: #309345;
}

.collection-filter-block .product-service .media .media-body {
  padding-left: 10px;
}

.collection-filter-block .product-service .media .media-body h4 {
  margin-bottom: 5px;
}

.collection-filter-block .product-service .media .media-body p {
  margin-bottom: 0;
  text-transform: capitalize;
}

.collection-filter-block .product-service .media:last-child {
  padding-bottom: 0;
}

.collection-collapse-block.open {
  margin-bottom: 12px;
}

.collection-collapse-block:last-child {
  padding-bottom: 24px;
}

.collection-collapse-block .collapse-block-title {
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #444;
  font-weight: 600;
  margin: 30px 0 0 0;
}

.collection-collapse-block .collapse-block-title:after {
  position: absolute;
  display: block;
  top: 0;
  right: -3px;
  content: "\f0d7";
  font-family: FontAwesome;
  color: #444;
}

.collection-collapse-block .collection-collapse-block-content {
  overflow: hidden;
}

.collection-collapse-block .collection-collapse-block-content .color-selector {
  margin-top: 30px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li {
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 18px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a {
  color: #777;
  padding-bottom: 10px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:last-child a {
  padding-bottom: 0;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
  margin-top: 30px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
  margin-bottom: 12px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:first-child {
  margin-top: 30px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:last-child {
  margin-bottom: 0;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  color: #777;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:before {
  top: 1px;
  height: 15px;
  width: 15px;
  background: #fff;
  border: 1px solid #777;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:after {
  top: 1px;
  height: 15px;
  width: 15px;
  background-size: 65%;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
}

.color-selector ul li {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #444;
}

.color-selector ul .color-1 {
  background-color: white;
}

.color-selector ul .color-2 {
  background-color: grey;
}

.color-selector ul .color-3 {
  background-color: red;
}

.color-selector ul .color-4 {
  background-color: purple;
}

.color-selector ul .color-5 {
  background-color: blue;
}

.color-selector ul .color-6 {
  background-color: yellow;
}

.color-selector ul .color-7 {
  background-color: green;
}

.color-selector ul li.active {
  border: 1px solid black;
  position: relative;
}

.color-selector ul li.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 10px;
  right: 4px;
  height: 15px;
  width: 15px;
  background-size: 70%;
  background-repeat: no-repeat;
  position: absolute;
}

.col-lg-2 .product-box .img-wrapper .product-thumb-list li img {
  height: 35px;
}

.collection-sidebar-banner {
  margin-top: 30px;
}

.collection-sidebar-banner img {
  width: 100%;
}

.top-banner-wrapper .img-fluid {
  width: 100%;
}

.top-banner-wrapper .top-banner-content h4 {
  font-weight: 600;
  color: #444;
  margin-bottom: 15px;
}

.top-banner-wrapper .top-banner-content h5 {
  font-size: 16px;
  font-weight: 600;
  color: #444;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.top-banner-wrapper .top-banner-content p {
  line-height: 1.5;
  color: #777;
  margin-bottom: 0;
}

.product-wrapper-grid.list-view .product-wrap .product-info {
  text-align: left;
  align-self: center;
  padding-left: 15px;
}

.product-wrapper-grid.list-view .product-box {
  display: flex;
  padding-bottom: 0;
}

.product-wrapper-grid.list-view .product-box .img-wrapper,
.product-wrapper-grid.list-view .product-box .img-block {
  width: 25%;
  min-width: 150px;
}

.product-wrapper-grid.list-view .product-box .product-detail {
  padding-left: 15px;
  align-self: center;
  text-align: left !important;
}

.product-wrapper-grid.list-view .product-box .product-detail .rating {
  margin-top: 0;
}

.product-wrapper-grid.list-view .product-box .product-detail p {
  display: block !important;
  margin-bottom: 5px;
  line-height: 18px;
}

.product-wrapper-grid.list-view .product-box .product-detail .color-variant {
  padding-top: 10px;
}

.product-wrapper-grid.list-view .product-box .product-detail h6 {
  font-weight: 700;
}

.collection-product-wrapper .product-top-filter {
  margin-top: 25px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.collection-product-wrapper .product-top-filter .product-filter-content,
.collection-product-wrapper .product-top-filter .popup-filter {
  display: flex;
  flex-wrap: wrap;
}

.collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.collection-product-wrapper .product-top-filter .popup-filter .search-count,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  border-right: 1px solid #ddd;
  padding: 20px;
  text-transform: capitalize;
  width: 35%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup h5,
.collection-product-wrapper .product-top-filter .popup-filter .search-count h5,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup h5 {
  margin-bottom: 0;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  padding: 20px;
  width: 15%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li {
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
  margin-right: 14px;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  padding: 20px;
  width: 25%;
  border-right: 1px solid #ddd;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul {
  display: flex;
  justify-content: flex-end;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li {
  margin-left: 10px;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li img,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li img {
  height: 18px;
  cursor: pointer;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  position: relative;
  width: 25%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  border: 0;
  padding: 20px;
  border-right: 1px solid #ddd;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: url("/assets/images/dropdown.png") no-repeat 95%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select:focus {
  outline: unset;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select option {
  padding: 20px 0;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  width: 25%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 10px;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  border-right: none;
}

.collection-product-wrapper .product-top-filter .popup-filter {
  justify-content: unset;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar {
  width: 3px;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-track {
  background: #ddd;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb {
  background: #888;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  position: relative;
  cursor: pointer;
  width: 20%;
  background: url("/assets/images/dropdown.png") no-repeat 95%;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
  font-size: 16px;
  color: #222;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
  background-color: #f8f8f8;
  width: 270px;
  height: 600px;
  overflow-y: scroll;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-filter-block {
  border: none;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card {
  padding: 0 30px;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner {
  padding: 0 30px;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner img {
  width: 100%;
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  display: none;
  position: absolute;
  z-index: 9;
  top: 75px;
  left: 0;
  border: 1px solid #f1f5f4;
  box-shadow: 0 0 5px #ddd;
  min-width: 330px;
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup.open {
  display: block;
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup .collection-filter-block {
  margin-bottom: 0;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  width: 20%;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  padding-right: 45px;
  font-size: 16px;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 35px;
}

.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  padding: 20px;
}

.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  width: 20%;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 25px !important;
}

.collection-product-wrapper .product-wrapper-grid .product-box .product-detail p {
  display: none;
}

.collection-product-wrapper .product-wrapper-grid .product-five {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h6 {
  font-size: 13px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h4 {
  font-size: 18px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .rating i {
  padding-right: 0;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .img-wrapper .lable-block .lable3 {
  padding: 8px 4px;
  font-size: 12px;
}

.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer;
}

.filter-main-btn span {
  border-radius: 0;
  font-size: 20px;
}

.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  display: none;
}

.collection-mobile-back span {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  cursor: pointer;
}

.collection-mobile-back span i {
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
}

.product-pagination {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  margin: 50px 20px 0 20px;
}

.product-pagination .pagination {
  border-radius: 0;
}

.product-pagination .pagination .page-item {
  border-radius: 0;
}

.product-pagination .pagination .page-item a {
  padding: 18px;
  border-bottom: 0;
  border-top: 0;
  color: #777;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.product-pagination .pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.product-pagination .pagination .page-item.active a {
  color: #309345;
  background-color: #fff;
  border-color: #ddd;
}

.product-pagination .product-search-count-bottom {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding-right: 15px;
}

.product-pagination .product-search-count-bottom h5 {
  color: #777;
  font-size: 14px;
  margin-bottom: 0;
  padding: 5px 0;
}

.collection .collection-block {
  text-align: center;
}

.collection .collection-block img {
  margin-bottom: 20px;
}

.collection .collection-block .collection-content {
  text-align: center;
}

.collection .collection-block .collection-content h3 {
  margin-bottom: 10px;
  color: #222;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.05em;
}

.collection .collection-block .collection-content h4 {
  color: #222;
  margin-bottom: 10px;
  font-size: 16px;
}

.collection .collection-block .collection-content p {
  line-height: 1.5;
}

.collection .collection-block .collection-content .btn-solid,
.collection .collection-block .collection-content .btn-outline {
  padding: 10px 25px;
}

.faq-section .accordion.theme-accordion .card {
  margin-top: 10px;
  border-radius: 0;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}

.faq-section .accordion.theme-accordion .card :not(:first-of-type):not(:last-of-type),
.faq-section .accordion.theme-accordion .card .theme-accordion .card:first-of-type {
  border-bottom: 1px solid #ddd;
}

.faq-section .accordion.theme-accordion .card:first-child {
  margin-top: 0;
}

.faq-section .accordion.theme-accordion .card .card-header {
  padding: 0;
  border-bottom: 0;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.faq-section .accordion.theme-accordion .card .card-header button {
  color: #333;
  text-align: left;
  font-size: 17px;
  padding: 18px;
  font-weight: 600;
}

.faq-section .accordion.theme-accordion .card .card-header button:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid #000000;
  right: 20px;
  top: 25px;
  transition: all 0.3s ease;
}

.faq-section .accordion.theme-accordion .card .card-header button[aria-expanded="true"]:before {
  border-top: 0;
  border-bottom: 6px solid #000;
  transition: all 0.3s ease;
}

.faq-section .accordion.theme-accordion .card .card-header button:hover {
  text-decoration: none;
  color: #309345;
  transition: all 0.3s ease;
}

.faq-section .accordion.theme-accordion .card .card-body {
  padding: 15px 18px;
}

.faq-section .accordion.theme-accordion .card .card-body p {
  margin-bottom: 0;
  font-size: 14px;
  color: #555;
  line-height: 1.8;
  letter-spacing: 0.06em;
}

.pwd-page {
  text-align: center;
}

.pwd-page h2 {
  margin-bottom: 25px;
  color: #222;
  margin-top: -5px;
}

.pwd-page .theme-form input {
  border-color: #ddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: center;
}

.pwd-page .theme-form .btn-solid {
  margin: 0 auto;
}

.login-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  margin-top: -5px;
}

.login-page .theme-card {
  padding: 30px;
  border: 1px solid #ddd;
  line-height: 1;
}

.login-page .theme-card .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.login-page .theme-card .theme-form input {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}

.login-page .authentication-right {
  height: calc(100% - 48px);
}

.login-page .authentication-right h6 {
  text-transform: uppercase;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

.login-page .authentication-right p {
  font-size: 14px;
  color: #555;
  line-height: 2;
  margin-bottom: 1rem;
}

.register-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  margin-top: -5px;
}

.register-page .theme-card {
  padding: 30px;
  border: 1px solid #ddd;
  line-height: 1;
}

.register-page .theme-card .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.register-page .theme-card .theme-form input {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}

.search-block {
  padding: 0;
}

.search-block .form-header .input-group input {
  border-radius: 0 5px 5px 0;
  padding: 15px 20px;
}

.search-block .form-header .input-group i {
  padding-right: 10px;
}

.search-block .form-header .input-group .btn {
  z-index: 0;
}

.contact-page .map iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contact-page .contact-right {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact-page .contact-right ul li {
  position: relative;
  padding-left: 150px;
  margin-top: 45px;
  display: block;
}

.contact-page .contact-right ul li:first-child {
  margin-top: 0;
}

.contact-page .contact-right ul li .contact-icon {
  position: absolute;
  left: 0;
  text-align: center;
  width: 130px;
  border-right: 1px solid #dddddd;
}

.contact-page .contact-right ul li .contact-icon i {
  font-size: 20px;
  color: #ff4c3b;
}

.contact-page .contact-right ul li .contact-icon h6 {
  text-transform: uppercase;
  padding-top: 5px;
  font-weight: 600;
  color: #ff4c3b;
}

.contact-page .contact-right ul li p {
  font-weight: 600;
  margin-bottom: 0;
  line-height: 30px;
}

.contact-page .theme-form {
  margin-top: -6px;
}

.contact-page .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.contact-page .theme-form input {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}

.contact-page .theme-form textarea {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}

.contact-page .form-control {
  border-radius: 0;
}

.qty-box .input-group {
  justify-content: center;
}

.qty-box .input-group span button {
  background: #fff !important;
  border: 1px solid #ced4da;
}

.qty-box .input-group .form-control {
  text-align: center;
  width: 80px;
  flex: unset;
}

.qty-box .input-group button {
  background-color: transparent;
  border: 0;
  color: #777;
  cursor: pointer;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
}

.qty-box .input-group button i {
  font-weight: 900;
  color: #222;
}

.qty-box .input-group .icon {
  padding-right: 0;
}

.cart-section .table tbody+tbody,
.wishlist-section .table tbody+tbody {
  border-top: none;
}

.cart-section .cart-buttons,
.wishlist-section .cart-buttons {
  padding-top: 35px;
}

.cart-section .cart-buttons>div:last-child,
.wishlist-section .cart-buttons>div:last-child {
  text-align: right;
  padding-right: 59px;
}

.cart-section .wishlist-buttons,
.wishlist-section .wishlist-buttons {
  padding-top: 35px;
  text-align: right;
}

.cart-section .wishlist-buttons:last-child a,
.wishlist-section .wishlist-buttons:last-child a {
  margin-left: 15px;
}

.cart-section .cart-table,
.wishlist-section .cart-table {
  overflow: hidden;
  margin-bottom: 0;
}

.cart-section .cart-table thead th,
.wishlist-section .cart-table thead th {
  border-bottom-width: 1px;
  font-weight: 900;
  color: #222;
  text-transform: uppercase;
  font-size: 14px;
  border-top: 0;
  text-align: center;
  padding: 0 0.75rem 0.75rem 0.75rem;
}

.cart-section tbody h2,
.wishlist-section tbody h2 {
  margin-bottom: 0;
}

.cart-section tbody tr td,
.wishlist-section tbody tr td {
  min-width: 210px;
  vertical-align: middle;
  color: #777;
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
}

.cart-section tbody tr td a,
.cart-section tbody tr td p,
.wishlist-section tbody tr td a,
.wishlist-section tbody tr td p {
  color: #777;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.cart-section tbody tr td a img,
.cart-section tbody tr td p img,
.wishlist-section tbody tr td a img,
.wishlist-section tbody tr td p img {
  height: 90px;
}

.cart-section tbody tr td .td-color,
.wishlist-section tbody tr td .td-color {
  color: #309345;
}

.cart-section tbody tr td h2,
.wishlist-section tbody tr td h2 {
  font-size: 24px;
  color: #222;
  font-weight: 400;
}

.cart-section tbody tr td .mobile-cart-content,
.wishlist-section tbody tr td .mobile-cart-content {
  display: none;
  justify-content: center;
  margin-top: 10px;
}

.cart-section tbody tr td .mobile-cart-content .qty-box,
.wishlist-section tbody tr td .mobile-cart-content .qty-box {
  border-right: 1px solid #ddd;
  border-radius: 5px;
}

.cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control,
.wishlist-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
  width: 48px;
  padding: 6px;
}

.cart-section tbody tr td .mobile-cart-content .col-xs-3,
.wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
}

.cart-section tbody tr td .mobile-cart-content h2,
.wishlist-section tbody tr td .mobile-cart-content h2 {
  font-size: 20px;
}

.cart-section tfoot tr th,
.wishlist-section tfoot tr th {
  padding-top: 35px;
  text-align: right;
}

.cart-section tfoot tr td,
.wishlist-section tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px;
}

.cart-section tfoot tr td h2,
.wishlist-section tfoot tr td h2 {
  font-size: 24px;
  margin-bottom: 0;
}

.cart-section tfoot tr td:first-child,
.wishlist-section tfoot tr td:first-child {
  width: 85%;
  font-size: 15px;
  padding-right: 10px;
  text-transform: capitalize;
  font-weight: 700;
}

.blog-detail-page .blog-detail p:last-child {
  margin-bottom: 0;
}

.blog-detail-page .blog-detail img {
  margin-bottom: 40px;
}

.blog-detail-page .blog-detail h3 {
  color: #222;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.blog-detail-page .blog-detail p {
  color: #777;
  line-height: 1.8;
}

.blog-detail-page .blog-detail .post-social {
  color: #777;
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.blog-detail-page .blog-detail .post-social li+li {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #ddd;
}

.blog-detail-page .blog-advance>div:last-child p:last-child {
  margin-bottom: 0;
}

.blog-detail-page .blog-advance img {
  margin-bottom: 25px;
  margin-top: 25px;
}

.blog-detail-page .blog-advance ul {
  list-style-type: decimal;
  list-style-position: inside;
  line-height: 2.5;
  font-size: 15px;
}

.blog-detail-page .blog-advance ul li {
  display: list-item;
  font-weight: 700;
}

.blog-detail-page .blog-advance p {
  line-height: 1.8;
}

.blog-detail-page .comment-section {
  border-bottom: 1px solid #ddd;
}

.blog-detail-page .comment-section li {
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #ddd;
}

.blog-detail-page .comment-section li img {
  height: 65px;
  width: 65px;
  margin-right: 20px;
  border-radius: 100%;
}

.blog-detail-page .comment-section li h6 {
  color: #444;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
}

.blog-detail-page .comment-section li h6 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  color: #777;
  margin-left: 20px;
}

.blog-detail-page .comment-section li p {
  line-height: 1.8;
  margin-bottom: -4px;
}

.blog-detail-page .blog-contact h2 {
  margin-bottom: 30px;
  margin-top: -5px;
}

.blog-detail-page .blog-contact .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 16px;
  font-weight: 700;
}

.blog-detail-page .blog-contact .theme-form input,
.blog-detail-page .blog-contact .theme-form textarea {
  border-color: #ddd;
  font-size: 14px;
  padding: 17px 25px;
  margin-bottom: 30px;
}

.blog-page .blog-sidebar h6 {
  margin-bottom: 0;
  line-height: 20px;
  color: #333;
  font-weight: 700;
}

.blog-page .blog-sidebar p {
  line-height: 20px;
  margin-bottom: 0;
  color: #555;
}

.blog-page .blog-sidebar h4 {
  color: #333;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px;
}

.blog-page .blog-sidebar .theme-card {
  border: 1px solid #ddd;
  padding: 30px;
  background-color: #fff;
}

.blog-page .blog-sidebar .theme-card .recent-blog li .media img {
  width: 100px;
  margin-right: 10px;
}

.blog-page .blog-sidebar .theme-card .recent-blog li+li {
  margin-top: 30px;
}

.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
  background-color: #309345;
  height: 70px;
  width: 70px;
  margin: 0 15px 0 0;
  padding: 15px;
}

.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date span {
  display: block;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.blog-page .blog-sidebar .theme-card .popular-blog li+li {
  margin-top: 30px;
}

.blog-page .blog-sidebar .theme-card .popular-blog li p {
  margin-top: 10px;
}

.blog-page .blog-sidebar .theme-card+.theme-card {
  margin-top: 30px;
}

.blog-page .blog-media {
  margin-bottom: 30px;
}

.blog-page .blog-media:last-child {
  margin-bottom: 0;
}

.blog-page .blog-media .blog-right {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.blog-page .blog-media .blog-right h6 {
  color: #309345;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: -3px;
}

.blog-page .blog-media .blog-right h4 {
  color: #333;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px;
}

.blog-page .blog-media .blog-right p {
  line-height: 1.8;
  margin-bottom: 0;
  letter-spacing: 0.04em;
}

.blog-page .blog-media .blog-right ul {
  color: #777;
  margin-bottom: 15px;
}

.blog-page .blog-media .blog-right ul li {
  font-size: 14px;
  line-height: 1.5;
}

.blog-page .blog-media .blog-right ul li i {
  padding-right: 3px;
}

.blog-page .blog-media .blog-right ul li+li {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #dddddd;
}

.loading-cls {
  width: 70px;
  height: 60px;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-image: url("/assets/images/loader.gif");
}

.seen-cls {
  text-align: center;
  margin-top: 15px;
}

.seen-it-cls {
  margin-top: 50px;
  color: red;
  font-size: 16px;
}

.seen-it-cls b {
  border: 1px solid #ddd;
  padding: 5px;
}

.instock-cls {
  color: #309345;
  font-weight: 700;
}

.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
  z-index: 0;
}

.product-slick .slick-prev,
.product-slick .slick-next,
.rtl-product-slick .slick-prev,
.rtl-product-slick .slick-next,
.product-right-slick .slick-prev,
.product-right-slick .slick-next,
.rtl-product-right-slick .slick-prev,
.rtl-product-right-slick .slick-next {
  opacity: 0;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.5s ease;
  margin-top: -10px;
}

.product-slick .slick-prev:before,
.product-slick .slick-next:before,
.rtl-product-slick .slick-prev:before,
.rtl-product-slick .slick-next:before,
.product-right-slick .slick-prev:before,
.product-right-slick .slick-next:before,
.rtl-product-right-slick .slick-prev:before,
.rtl-product-right-slick .slick-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  opacity: 1;
  color: black;
  background-color: white;
  border-radius: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-slick .slick-next,
.rtl-product-slick .slick-next,
.product-right-slick .slick-next,
.rtl-product-right-slick .slick-next {
  right: 1px;
}

.product-slick .slick-next:before,
.rtl-product-slick .slick-next:before,
.product-right-slick .slick-next:before,
.rtl-product-right-slick .slick-next:before {
  content: "\f105";
}

.product-slick .slick-prev,
.rtl-product-slick .slick-prev,
.product-right-slick .slick-prev,
.rtl-product-right-slick .slick-prev {
  left: 1px;
  z-index: 1;
}

.product-slick .slick-prev:before,
.rtl-product-slick .slick-prev:before,
.product-right-slick .slick-prev:before,
.rtl-product-right-slick .slick-prev:before {
  content: "\f104";
}

.product-slick .slick-list,
.rtl-product-slick .slick-list,
.product-right-slick .slick-list,
.rtl-product-right-slick .slick-list {
  overflow: hidden;
}

.product-slick .slick-slide>div,
.rtl-product-slick .slick-slide>div,
.product-right-slick .slick-slide>div,
.rtl-product-right-slick .slick-slide>div {
  display: flex;
  margin-bottom: 5px;
}

.product-slick:hover .slick-prev,
.product-slick:hover .slick-next,
.rtl-product-slick:hover .slick-prev,
.rtl-product-slick:hover .slick-next,
.product-right-slick:hover .slick-prev,
.product-right-slick:hover .slick-next,
.rtl-product-right-slick:hover .slick-prev,
.rtl-product-right-slick:hover .slick-next {
  opacity: 1;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 0.5s ease;
}

.product-slick:hover .slick-prev:before,
.product-slick:hover .slick-next:before,
.rtl-product-slick:hover .slick-prev:before,
.rtl-product-slick:hover .slick-next:before,
.product-right-slick:hover .slick-prev:before,
.product-right-slick:hover .slick-next:before,
.rtl-product-right-slick:hover .slick-prev:before,
.rtl-product-right-slick:hover .slick-next:before {
  opacity: 1;
}

.product-slick:hover .slick-next,
.rtl-product-slick:hover .slick-next,
.product-right-slick:hover .slick-next,
.rtl-product-right-slick:hover .slick-next {
  right: 20px;
}

.product-slick:hover .slick-prev,
.rtl-product-slick:hover .slick-prev,
.product-right-slick:hover .slick-prev,
.rtl-product-right-slick:hover .slick-prev {
  left: 20px;
}

.slider-nav .slick-track {
  height: auto !important;
}

.slider-nav .slick-slide,
.rtl-slider-nav .slick-slide,
.slider-right-nav .slick-slide,
.rtl-slider-right-nav .slick-slide {
  padding: 0 15px 15px;
  padding-bottom: 0;
}

.slider-nav .slick-slide>div,
.rtl-slider-nav .slick-slide>div,
.slider-right-nav .slick-slide>div,
.rtl-slider-right-nav .slick-slide>div {
  display: flex;
  margin: 15px 15px 0 15px;
  border: 1px solid #ddd;
  transition: all 0.5s ease;
}

.slider-nav .slick-slide.slick-current>div,
.rtl-slider-nav .slick-slide.slick-current>div,
.slider-right-nav .slick-slide.slick-current>div,
.rtl-slider-right-nav .slick-slide.slick-current>div {
  border-color: #aaa;
  transition: all 0.5s ease;
}

.slider-nav .slick-slide.slick-current img,
.rtl-slider-nav .slick-slide.slick-current img,
.slider-right-nav .slick-slide.slick-current img,
.rtl-slider-right-nav .slick-slide.slick-current img {
  border: 1px solid #ddd;
}

.slider-nav .slick-slide.slick-active,
.rtl-slider-nav .slick-slide.slick-active,
.slider-right-nav .slick-slide.slick-active,
.rtl-slider-right-nav .slick-slide.slick-active {
  transition: all 0.5s ease;
}

.slider-right-nav .slick-slide:first-child>div,
.rtl-slider-right-nav .slick-slide:first-child>div {
  margin-top: 0;
}

.product-thumbnail .slider-nav .slick-list .slick-track {
  margin-top: 30px;
}

.pro_sticky_info {
  border: 1px solid #ddd;
  padding: 20px;
}

.is_stuck {
  margin-top: 80px;
}

.image-scroll div>div {
  padding-bottom: 20px;
}

.image-scroll div>div:last-child {
  padding: 0;
}

.product-right p {
  margin-bottom: 0;
  line-height: 1.5em;
}

.product-right .product-title {
  color: #222;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
}

.product-right .border-product {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #ddd;
}

.product-right .border-product:last-child {
  padding-bottom: 0;
}

.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em;
}

.product-right h3 {
  font-size: 26px;
  color: #222;
  margin-bottom: 15px;
}

.product-right h4 {
  font-size: 16px;
  margin-bottom: 7px;
}

.product-right h4 del {
  color: #777;
}

.product-right h4 span {
  padding-left: 5px;
  color: #309345;
}

.product-right .color-variant {
  margin-bottom: 10px;
}

.product-right .color-variant li {
  height: 30px;
  width: 30px !important;
  cursor: pointer;
  float: none;
  display: inline-block;
}

.product-right .product-buttons {
  margin-bottom: 20px;
}

.product-right .product-buttons .btn-solid,
.product-right .product-buttons .btn-outline {
  padding: 7px 25px;
}

.product-right .product-buttons a:last-child {
  margin-left: 10px;
}

.product-right .product-description h6 span {
  float: right;
}

.product-right .product-description .qty-box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.product-right .product-description .qty-box .input-group {
  justify-content: unset;
  width: unset;
}

.product-right .product-description .qty-box .input-group .form-control {
  border-right: none;
}

.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-right .size-box ul li {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #f7f7f7;
  text-align: center;
}

.product-right .size-box ul li a {
  color: #222;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.product-right .size-box ul li.active {
  background-color: #f7f7f7;
}

.product-right .product-icon {
  display: flex;
}

.product-right .product-icon .product-social {
  margin-top: 5px;
}

.product-right .product-icon .product-social li {
  padding-right: 30px;
}

.product-right .product-icon .product-social li a {
  color: #333;
  transition: all 0.3s ease;
}

.product-right .product-icon .product-social li a i {
  font-size: 18px;
}

.product-right .product-icon .product-social li a:hover {
  color: #309345;
}

.product-right .product-icon .product-social li:last-child {
  padding-right: 0;
}

.product-right .product-icon .wishlist-btn {
  background-color: transparent;
  border: none;
}

.product-right .product-icon .wishlist-btn i {
  border-left: 1px solid #ddd;
  font-size: 18px;
  padding-left: 10px;
  margin-left: 5px;
  transition: all 0.5s ease;
}

.product-right .product-icon .wishlist-btn span {
  padding-left: 10px;
  font-size: 18px;
}

.product-right .product-icon .wishlist-btn:hover i {
  color: #309345;
  transition: all 0.5s ease;
}

.product-right .payment-card-bottom {
  margin-top: 10px;
}

.product-right .payment-card-bottom ul li {
  padding-right: 10px;
}

.product-right .timer {
  margin-top: 10px;
  background-color: #f7f7f7;
}

.product-right .timer p {
  color: #222;
}

.product-right.product-form-box {
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px;
}

.product-right.product-form-box .size-text {
  text-align: left;
}

.product-right.product-form-box .product-description .qty-box {
  margin-bottom: 5px;
}

.product-right.product-form-box .product-description .qty-box .input-group {
  justify-content: center;
  width: 100%;
}

.product-right.product-form-box .product-buttons {
  margin-bottom: 0;
}

.product-right.product-form-box .timer {
  margin-bottom: 10px;
  text-align: left;
}

.single-product-tables {
  display: flex;
  margin-top: 15px;
}

.single-product-tables table {
  width: 20%;
}

.single-product-tables table tr {
  height: 35px;
}

.single-product-tables table tr td:first-child {
  font-weight: 600;
}

.single-product-tables.detail-section {
  margin-top: 0;
}

.single-product-tables.detail-section table {
  width: 55%;
}

.product-accordion .btn-link {
  color: #222;
}

.product-accordion .btn-link:hover {
  text-decoration: none;
}

.product-accordion .card {
  border: none;
}

.product-accordion .card-body {
  width: 100%;
  height: 300px;
}

.product-accordion .card-header {
  padding: 5px 8px;
  border-bottom: 1px dashed rgba(0,0,0,0.125);
  background-color: rgba(127,127,127,0.03);
}

.product-related h2 {
  color: #222;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 20px;
  margin-top: -5px;
}

.rating {
  margin-top: 0;
}

.rating i {
  padding-right: 5px;
}

.rating i:nth-child(-n+4) {
  color: #ffa200;
}

.rating i:last-child {
  color: #ddd;
}

.rating .three-star {
  padding-bottom: 5px;
}

.rating .three-star i {
  color: #acacac;
}

.rating .three-star i:nth-child(-n+3) {
  color: #ffd200;
}

.tab-border {
  border: 1px solid #ddd;
  margin: 0;
  padding: 8px 0;
}

.tab-border .nav-border {
  border-bottom: 0;
  border-right: 1px solid #ddd;
}

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  padding: 15px 20px;
  cursor: pointer;
}

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item:focus,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.tab-product,
.product-full-tab {
  padding-top: 30px;
}

.tab-product .nav-material.nav-tabs,
.product-full-tab .nav-material.nav-tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  color: #212121;
  text-align: center;
  padding: 0 15px 20px 15px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
}

.tab-product .nav-material.nav-tabs .nav-item .material-border,
.product-full-tab .nav-material.nav-tabs .nav-item .material-border {
  border-bottom: 2px solid #309345;
  opacity: 0;
}

.tab-product .theme-form input,
.product-full-tab .theme-form input {
  border-color: #ddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: left;
}

.tab-product .theme-form .btn-solid,
.product-full-tab .theme-form .btn-solid {
  margin: 0 auto;
}

.tab-product .theme-form textarea,
.product-full-tab .theme-form textarea {
  border-color: #ddd;
  font-size: 15px;
  padding: 17px 25px;
  margin-bottom: 15px;
  height: inherit;
}

.tab-product .tab-content.nav-material p,
.product-full-tab .tab-content.nav-material p {
  line-height: 2;
  letter-spacing: 0.05em;
}

.tab-product .tab-content.nav-material .media,
.product-full-tab .tab-content.nav-material .media {
  margin-top: 20px;
}

.tab-product .title,
.product-full-tab .title {
  padding-right: 45px;
  color: #309345;
  padding-bottom: 20px;
}

.tab-product .theme-slider .slick-arrow,
.product-full-tab .theme-slider .slick-arrow {
  top: -45px;
  height: auto;
}

.tab-product .theme-slider .slick-arrow :before,
.product-full-tab .theme-slider .slick-arrow :before {
  color: #000;
  font-size: 18px;
}

.tab-product .product-box,
.product-full-tab .product-box {
  position: relative;
  margin: 5px;
}

.tab-product .product-box:hover,
.product-full-tab .product-box:hover {
  box-shadow: 0 0 12px 0 #dddddd;
}

.tab-product .product-box:hover .lbl-1,
.product-full-tab .product-box:hover .lbl-1 {
  opacity: 1;
  transition: all 0.3s ease;
}

.tab-product .product-box:hover .lbl-2,
.product-full-tab .product-box:hover .lbl-2 {
  opacity: 1;
  -webkit-animation: flipInY 1000ms ease-in-out;
          animation: flipInY 1000ms ease-in-out;
}

.tab-product .product-box:hover .color-variant li,
.product-full-tab .product-box:hover .color-variant li {
  opacity: 1 !important;
  -webkit-animation: fadeInUp 500ms ease-in-out;
          animation: fadeInUp 500ms ease-in-out;
}

.tab-product .product-box .img-block,
.product-full-tab .product-box .img-block {
  min-height: unset;
}

.tab-product .product-box .cart-info,
.product-full-tab .product-box .cart-info {
  position: absolute;
  padding: 10px 0;
  top: 25%;
  right: 15px;
  width: 40px;
  margin-right: 0;
}

.tab-product .product-box .cart-info i,
.product-full-tab .product-box .cart-info i {
  padding-right: 0;
}

.tab-product .product-box .cart-info a,
.tab-product .product-box .cart-info button,
.product-full-tab .product-box .cart-info a,
.product-full-tab .product-box .cart-info button {
  color: #333;
  transition: all 0.3s ease;
  background-color: #fff;
  height: 35px;
  width: 35px;
  margin: 7px 0;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  box-shadow: 0 0 12px 0 #ddd;
}

.tab-product .product-box .cart-info a :hover,
.tab-product .product-box .cart-info button :hover,
.product-full-tab .product-box .cart-info a :hover,
.product-full-tab .product-box .cart-info button :hover {
  transition: all 0.3s ease;
  color: #309345;
}

.tab-product .product-box .lbl-1,
.product-full-tab .product-box .lbl-1 {
  background-color: #309345;
  padding: 2px 20px 2px 10px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0;
}

.tab-product .product-box .lbl-1:before,
.product-full-tab .product-box .lbl-1:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 12px solid #309345;
  border-bottom: 13px solid #309345;
  border-right: 7px solid #fff;
}

.tab-product .product-box .lbl-2,
.product-full-tab .product-box .lbl-2 {
  font-size: 14px;
  top: 15px;
  position: absolute;
  right: 10px;
  color: #333333;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 0;
}

.tab-product .product-box a,
.product-full-tab .product-box a {
  color: #0072bb;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}

.tab-product .product-box .color-variant,
.product-full-tab .product-box .color-variant {
  position: absolute;
  top: -35px;
  width: 100%;
}

.tab-product .product-box .slick-slide img,
.product-full-tab .product-box .slick-slide img {
  display: block;
}

.tab-product .product-box .product-details,
.product-full-tab .product-box .product-details {
  position: relative;
}

.tab-product .product-box .product-details .color-variant,
.product-full-tab .product-box .product-details .color-variant {
  position: absolute;
  top: -35px;
  width: 100%;
}

.tab-product .product-box .product-details .color-variant li,
.product-full-tab .product-box .product-details .color-variant li {
  opacity: 0;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin: 0 3px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tab-product .product-box .product-details h6,
.product-full-tab .product-box .product-details h6 {
  color: #333333;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.tab-product .product-box .product-details .price,
.product-full-tab .product-box .product-details .price {
  padding-bottom: 10px;
  font-size: 16px;
  color: #309345;
}

.product-full-tab {
  padding-top: 70px;
}

.product-load-more .col-grid-box {
  display: none;
}

.load-more-sec {
  margin-top: 40px;
  text-align: center;
}

.load-more-sec a {
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative;
}

.checkout_class {
  text-transform: capitalize;
}

.checkout-page .checkout-title {
  margin-bottom: 25px;
}

.checkout-page .checkout-title h3 {
  color: #444;
  font-weight: 700;
}

.checkout-page .checkout-form .checkout-details {
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 40px;
}

.checkout-page .checkout-form .check-out .form-group:last-child {
  margin-bottom: -5px;
}

.checkout-page .checkout-form .check-out .form-group:last-child label {
  margin-bottom: -5px;
}

.checkout-page .checkout-form .form-group {
  position: relative;
  margin-bottom: 25px;
}

.checkout-page .checkout-form .form-group h3 {
  color: #444;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
}

.checkout-page .checkout-form .form-group .field-label {
  line-height: 24px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 10px;
  font-weight: 700;
}

.checkout-page .checkout-form .form-group .field-label span {
  font-size: 16px;
  color: #222;
  font-weight: 600;
}

.checkout-page .checkout-form .form-group label {
  color: #777;
}

.checkout-page .checkout-form select {
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
  background: url("/assets/images/dropdown.png") no-repeat 95%;
}

.checkout-page .checkout-form input[type="text"],
.checkout-page .checkout-form input[type="email"],
.checkout-page .checkout-form input[type="password"],
.checkout-page .checkout-form input[type="tel"],
.checkout-page .checkout-form input[type="number"],
.checkout-page .checkout-form input[type="url"] {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #dddddd;
}

.checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #dddddd;
}

.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
}

.checkout-page .check-box label {
  position: relative;
  top: -1px;
  font-weight: normal;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.checkout-page .lower-content {
  margin-top: 30px;
}

.checkout-page .lower-content .order-column {
  margin-bottom: 40px;
}

.order-box {
  position: relative;
  margin-bottom: 50px;
}

.order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: #444;
  font-weight: 600;
  font-size: 22px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
}

.order-box .title-box span {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em;
}

.order-box .qty {
  position: relative;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}

.order-box .qty li {
  position: relative;
  display: block;
  font-size: 15px;
  color: #444;
  line-height: 20px;
  margin-bottom: 20px;
}

.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
  font-weight: 400;
  width: 35%;
}

.order-box .sub-total {
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #309345;
  font-weight: 400;
  width: 35%;
  float: right;
}

.order-box .sub-total .shopping-option label {
  position: relative;
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  color: #6f6f6f;
}

.order-box .sub-total .shipping {
  width: 35%;
  float: right;
}

.order-box .total {
  position: relative;
  margin-bottom: 40px;
}

.order-box .total li {
  position: relative;
  display: block;
  font-weight: 400;
  color: #333;
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.order-box .total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #309345;
  font-weight: 400;
}

.order-box .total li span {
  float: right;
  font-size: 15px;
  line-height: 20px;
  color: #444;
  font-weight: 400;
  width: 35%;
  display: block;
}

.payment-box {
  position: relative;
}

.payment-box .upper-box {
  position: relative;
}

.payment-box .payment-options {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}

.payment-box .payment-options li {
  display: flex;
  margin-bottom: 15px;
}

.payment-box .payment-options li .radio-option {
  position: relative;
}

.payment-box .payment-options li .radio-option label {
  position: relative;
  padding-left: 30px;
  text-transform: capitalize;
  color: #6f6f6f;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}

.payment-box .payment-options li .radio-option input[type="radio"] {
  position: absolute;
  left: 0;
  top: 5px;
}

.payment-box .payment-options li .radio-option label .small-text {
  position: relative;
  display: none;
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
  color: #666666;
  margin-top: 10px;
}

.payment-box .payment-options li .radio-option label img {
  position: relative;
  display: block;
  max-width: 100%;
  margin-left: -30px;
  margin-top: 5px;
}

.stripe-section {
  text-transform: uppercase;
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #dddddd;
}

.stripe-section table tbody tr td:first-child {
  padding-right: 15px;
}

.stripe-section h5 {
  font-weight: 700;
}

.stripe-section .content h5 {
  color: #4f4f4f;
  font-weight: 400;
}

.stripe-section .content table {
  width: 100%;
}

.stripe-section .content table tr {
  border-bottom: 1px solid #f1f1f1;
}

.stripe-section .content table tr td {
  padding: 8px 0;
  color: #9a9a9a;
}

.stripe-section .content table tr td:last-child {
  text-align: right;
}

.stripe-section .content table tr td:first-child {
  text-align: right;
}

.dashboard-left .block-title h2 {
  font-size: 24px;
  padding-bottom: 20px;
}

.dashboard-left .block-content {
  border: 1px solid #ddd;
  padding: 15px;
}

.dashboard-left .block-content ul li {
  display: flex;
  transition: all 0.5s ease;
}

.dashboard-left .block-content ul li.active {
  color: #309345;
}

.dashboard-left .block-content ul li.active a {
  color: #309345;
}

.dashboard-left .block-content ul li:before {
  content: '\f105';
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 3px;
}

.dashboard-left .block-content ul li:hover {
  padding-left: 10px;
  color: #ff0000;
  transition: all 0.5s ease;
}

.dashboard-left .block-content ul li:hover a {
  color: #ff0000;
  transition: all 0.5s ease;
}

.padding-cls {
  padding-left: 50px;
  padding-right: 50px;
}

.dashboard-right p {
  color: #5f5f5f;
  line-height: 20px;
}

.dashboard-right .dashboard {
  border: 1px solid #ddd;
  padding: 30px;
}

.dashboard-right .dashboard .page-title h2 {
  font-size: 22px;
  margin-bottom: 15px;
}

.dashboard-right .dashboard .welcome-msg p {
  margin-bottom: 0;
}

.dashboard .box-head h2 {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: capitalize;
  color: #333;
}

.dashboard .box a {
  color: #309345;
}

.dashboard .box h6 {
  margin-bottom: 0;
}

.dashboard .box .box-title {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 5px;
  padding: 12px 0;
  position: relative;
  width: 100%;
}

.dashboard .box .box-title h3 {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
  color: #333;
}

.dashboard .box .box-title>a {
  position: absolute;
  right: 0;
  top: 12px;
  color: #309345;
}

.dashboard .box address {
  margin-bottom: 0;
}

.box-content h6 {
  font-size: 14px;
  line-height: 25px !important;
  margin-bottom: 0;
}

.box-content a {
  color: #309345;
}

.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: #309345;
  color: #fff;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px;
}

.success-text {
  text-align: center;
}

.success-text i {
  font-size: 50px;
  color: #4ead4e;
}

.success-text h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.success-text p {
  font-size: 18px;
  text-transform: capitalize;
}

.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize;
}

.order-success-sec .order-detail li {
  display: flex;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 16px;
}

.order-success-sec .payment-mode p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}

.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: #f9f9f9;
}

.delivery-sec h2,
.delivery-sec h3 {
  color: #000;
  text-transform: capitalize;
}

.product-order h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}

.product-order .product-order-detail {
  margin-top: 15px;
}

.product-order .product-order-detail img {
  height: 130px;
}

.product-order .product-order-detail .order_detail {
  display: flex;
  align-items: center;
}

.product-order .product-order-detail .order_detail h4 {
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 700;
}

.product-order .product-order-detail .order_detail h5 {
  text-transform: capitalize;
  margin-bottom: 0;
}

.product-order .total-sec {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 15px 0;
  padding: 15px 0;
}

.product-order .total-sec ul li {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
}

.product-order .total-sec ul li span {
  float: right;
}

.product-order .final-total h3 {
  display: block;
  margin-bottom: 0;
}

.product-order .final-total h3 span {
  float: right;
}

.compare-padding {
  padding-bottom: 50px;
}

.compare-padding h2 {
  margin-bottom: 20px;
}

.compare-lable {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1;
}

.compare-page .table-wrapper {
  padding-bottom: 0;
}

.compare-page .table-wrapper .table {
  border: 1px solid #eee;
  text-transform: capitalize;
  color: #222;
}

.compare-page .table-wrapper .table .product-name {
  width: 15%;
}

.compare-page .table-wrapper .table .featured-image {
  width: 185px;
}

.compare-page .table-wrapper .table td {
  border-top: none;
}

.compare-page .table-wrapper .table thead .th-compare td {
  font-size: 14px;
  font-weight: 700;
  background: #f8f8f8;
  border-right: 1px solid #eee;
}

.compare-page .table-wrapper .table thead .th-compare th {
  text-align: left;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  float: left;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

.compare-page .table-wrapper .table tbody tr th {
  background: #f8f8f8;
}

.compare-page .table-wrapper .table tbody tr td {
  border-right: 1px solid #eee;
  position: relative;
}

.compare-page .table-wrapper .table tbody tr th,
.compare-page .table-wrapper .table tbody tr td {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
}

.compare-page .table-wrapper .table tbody tr .grid-link__title {
  font-size: 15px;
}

.compare-page .table-wrapper .table tbody tr .product_price {
  margin: 15px 0;
}

.compare-page .table-wrapper .table tbody tr .product_price span.money {
  padding-left: 5px;
}

.compare-page .table-wrapper .table tbody tr p {
  color: #222;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}

.compare-page .table-wrapper .table tbody tr .add-to-cart,
.compare-page .table-wrapper .table tbody tr .select-option-cls {
  padding: 6px 10px;
}

.compare-section .compare-part {
  position: relative;
}

.compare-section .compare-part .close-btn {
  background-color: transparent;
  border: none;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.compare-section .compare-part .img-secton {
  padding: 0 15px 15px;
}

.compare-section .compare-part .img-secton img {
  width: 50%;
  margin: 0 auto;
  padding-top: 25px;
}

.compare-section .compare-part .img-secton a h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  margin-top: 10px;
}

.compare-section .compare-part .img-secton h5 {
  margin-bottom: 0;
  line-height: 1.2;
}

.compare-section .compare-part .detail-part .title-detail {
  background-color: #f7f8fa;
  text-align: center;
  padding: 8px 0;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.compare-section .compare-part .detail-part .title-detail h5 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.compare-section .compare-part .detail-part .inner-detail {
  padding: 15px;
}

.compare-section .compare-part .detail-part .inner-detail p {
  margin-bottom: 0;
  line-height: 1.2;
  letter-spacing: 0.05em;
}

.compare-section .compare-part .btn-part {
  text-align: center;
  padding: 15px;
  border-top: 1px solid #ddd;
}

.compare-section .compare-part .btn-part .btn-solid {
  padding: 5px 10px;
}

.compare-section .slick-slide>div {
  border: 1px solid #ddd;
  border-left: none;
}

.compare-section .slick-slide:first-child {
  border-left: 1px solid #ddd;
}

body.dark {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cfd4da;
}

.dark h1 {
  color: #fff;
}

.dark h2 {
  color: #f1f3f5;
}

.dark h3 {
  color: #eaedef;
}

.dark h4 {
  color: #e7eaec;
}

.dark h5 {
  color: #dee2e6;
}

.dark h6 {
  color: #cfd4da;
}

.dark p {
  color: #cfd4da;
}

.dark li {
  color: #cfd4da;
}

.dark a {
  color: #cbcbcb;
}

.dark .page-link {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .breadcrumb-section {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .light-layout {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .form-control {
  color: #cfd4da;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border: 1px solid #404040;
}

.dark .btn-solid {
  color: #fff !important;
}

.dark .btn-solid:hover {
  color: #222 !important;
}

.dark select {
  color: #cfd4da;
}

.dark option {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark header {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark header.sticky.fixed {
  background-color: #2b2b2b;
}

.dark header.header-5 .pixelstrap a,
.dark header.header-5 .pixelstrap:hover,
.dark header.header-5 .pixelstrap:active {
  color: #222;
}

.dark header.header-metro .metro {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark header.header-metro .top-header .header-contact li {
  color: #999;
}

.dark header.header-metro .top-header .header-dropdown li {
  color: #999;
}

.dark header.header-metro .top-header .header-dropdown li a i {
  color: #999;
}

.dark header.header-tools {
  background-color: transparent;
  transition: all 0.3s ease;
}

.dark header.header-tools .logo-menu-part>.container {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark header.left-header .top-header {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark header.left-header .main-menu .menu-right .header-dropdown>li img {
  -webkit-filter: invert(100);
          filter: invert(100);
}

.dark header.left-header .onhover-div>div img {
  -webkit-filter: invert(100);
          filter: invert(100);
}

.dark header.left-header .sidenav nav {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark header.left-header .sidenav .left-sidebar_center {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark header.left-header .sidenav .pixelstrap>li>a {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark header.green-gradient {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark header.green-gradient .top-header {
  background-color: #232323;
  transition: all 0.3s ease;
  background: #232323;
  background-image: none;
}

.dark .top-header {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .border-section {
  border-color: #404040;
}

.dark .main-navbar .nav-menu .back-btn .mobile-back {
  color: #f1f3f5;
}

.dark .main-navbar .nav-menu>li>a {
  color: #f1f3f5;
}

.dark .main-navbar .nav-menu>li .nav-submenu {
  background-color: #2b2b2b;
  box-shadow: none;
}

.dark .main-navbar .nav-menu>li .nav-submenu li a {
  color: #f1f3f5;
}

.dark .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu {
  background-color: #2b2b2b;
  box-shadow: none;
}

.dark .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu li a {
  color: #f1f3f5;
}

.dark .main-navbar .nav-menu>li .mega-menu-container {
  background-color: #2b2b2b;
  box-shadow: none;
}

.dark .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  color: #f1f3f5;
}

.dark .sm-vertical {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .sidenav nav {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .sidenav nav .sidebar-menu {
  background-color: #232323;
}

.dark .sidenav nav .sidebar-menu li a {
  color: #f1f3f5;
}

.dark .sidenav nav .sidebar-menu li .mega-menu {
  background-color: #232323;
  box-shadow: none;
}

.dark .sidenav nav .sidebar-menu li .mega-menu .link-section ul {
  background-color: #232323;
}

.dark .sidenav nav .sidebar-menu li ul {
  background-color: #232323;
}

.dark .sidenav .sidebar-back {
  color: #f1f3f5;
  border-color: #404040;
}

.dark .pixelstrap ul {
  background: #232323;
  box-shadow: none;
}

.dark .pixelstrap ul a {
  color: #e7eaec;
}

.dark .pixelstrap ul a:hover,
.dark .pixelstrap ul a:focus,
.dark .pixelstrap ul a:active,
.dark .pixelstrap ul a.highlighted {
  color: #e7eaec;
}

.dark .pixelstrap .home-menu,
.dark .pixelstrap .feature-menu,
.dark .pixelstrap .category-menu,
.dark .pixelstrap .full-mega-menu,
.dark .pixelstrap .clothing-menu {
  box-shadow: 0 0 1px 0 #2b2b2b;
}

.dark .onhover-div>div img {
  -webkit-filter: brightness(100);
          filter: brightness(100);
}

.dark .onhover-div .show-div {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .error-section h1 {
  color: #e6e6e6;
}

.dark .breadcrumb-item.active {
  color: #e7eaec;
}

.dark .breadcrumb-section .breadcrumb a {
  color: #f7f7f7;
}

.dark .main-menu .menu-left .navbar i {
  color: #f7f7f7;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  color: #cfd4da;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #cfd4da;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #e7eaec;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
  color: #dee2e6;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #fff;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-color: #404040;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
  color: #dee2e6;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  color: #cbcbcb;
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
  color: #309345;
}

.dark .footer-theme .sub-title li {
  color: #cfd4da;
}

.dark .footer-theme .sub-title li a {
  color: #cfd4da;
}

.dark .footer-theme .sub-title h4 {
  color: #e7eaec;
}

.dark .footer-social i,
.dark .social-white i {
  color: #cfd4da;
}

.dark .footer-light .subscribe {
  border-color: #404040;
}

.dark .footer-theme2 .contact-details li {
  color: #cfd4da;
}

.dark .footer-theme2 .contact-details li a {
  color: #e7eaec;
}

.dark .footer-theme2 .footer-link a,
.dark .footer-theme2 .footer-link-b a {
  color: #cbcbcb;
}

.dark .footer-theme2 .footer-link a:hover,
.dark .footer-theme2 .footer-link-b a:hover {
  color: #fff;
}

.dark .top-header .header-contact li {
  color: #cfd4da;
}

.dark .top-header .header-dropdown li {
  color: #cfd4da;
}

.dark .top-header .header-dropdown li a i {
  color: #cfd4da;
}

.dark .top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #cbcbcb;
}

.dark .top-header.top-header-dark .header-dropdown li a {
  color: #cbcbcb;
}

.dark .top-header.top-header-dark .header-contact li i {
  color: #cbcbcb;
}

.dark .top-header.top-header-dark2 .header-dropdown li a {
  color: #cfd4da;
}

.dark .top-header.top-header-dark2 .header-contact li i {
  color: #cfd4da;
}

.dark .top-header.top-header-dark3 .header-dropdown li a {
  color: #cbcbcb;
}

.dark .top-header.top-header-dark3 .header-contact li i {
  color: #cbcbcb;
}

.dark .onhover-dropdown .onhover-show-div {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .testimonial {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
  border-color: #404040;
}

.dark .testimonial .testimonial-slider .media img {
  border-color: #404040;
}

.dark .team h2 {
  border-color: #404040;
}

.dark .service-block+.service-block {
  border-color: #404040;
}

.dark .pixelstrap.light-font-menu li>a {
  color: #f1f3f5;
}

.dark .theme-tab .tab-title a,
.dark .theme-tab .tab-title2 a {
  color: #cbcbcb;
}

.dark .theme-tab .tab-title .current a,
.dark .theme-tab .tab-title2 .current a {
  color: #309345;
}

.dark .theme-tab .tab-title2:after {
  border-color: #404040;
}

.dark .theme-tab .tab-title2 .current {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .theme-tab .tab-title2 .current a {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .product-box .product-detail h4,
.dark .product-box .product-info h4,
.dark .product-wrap .product-detail h4,
.dark .product-wrap .product-info h4 {
  color: #e7eaec;
}

.dark .theme-card .offer-slider .media .media-body h4 {
  color: #e7eaec;
}

.dark .theme-card .slick-prev:before,
.dark .theme-card .slick-next:before {
  color: #fff;
}

.dark .theme-card h5.title-border {
  border-color: #404040;
}

.dark .theme-card.card-border {
  border-color: #404040;
}

.dark .dark-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .sub-footer.darker-subfooter {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .sub-footer.darker-subfooter p {
  color: #cfd4da;
}

.dark .blog-page .blog-media .blog-right h4 {
  color: #e7eaec;
}

.dark .blog-page .blog-media .blog-right ul {
  color: #cfd4da;
}

.dark .blog-page .blog-sidebar .theme-card {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .blog-page .blog-sidebar h4 {
  color: #e7eaec;
}

.dark .blog-page .blog-sidebar h6 {
  color: #cfd4da;
}

.dark .blog-page .blog-sidebar p {
  color: #cfd4da;
}

.dark .blog-detail-page .blog-detail h3 {
  color: #eaedef;
}

.dark .blog-detail-page .blog-detail p {
  color: #cfd4da;
}

.dark .blog-detail-page .post-social {
  color: #cfd4da;
  border-color: #404040;
}

.dark .blog-detail-page .post-social li+li {
  border-color: #404040;
}

.dark .blog-detail-page .comment-section {
  border-color: #404040;
}

.dark .blog-detail-page .comment-section li {
  border-color: #404040;
}

.dark .blog-detail-page .comment-section li h6 {
  color: #cfd4da;
}

.dark .blog-detail-page .comment-section li h6 span {
  color: #929292;
}

.dark .blog-detail-page .blog-contact .theme-form label {
  color: #cfd4da;
}

.dark .blog-detail-page .blog-contact .theme-form input,
.dark .blog-detail-page .blog-contact .theme-form textarea {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .cart-section .cart-table thead th,
.dark .wishlist-section .cart-table thead th {
  color: #f1f3f5;
}

.dark .cart-section tbody tr td,
.dark .wishlist-section tbody tr td {
  border-color: #404040;
}

.dark .cart-section tbody tr td a,
.dark .wishlist-section tbody tr td a {
  color: #cbcbcb;
}

.dark .cart-section tbody tr td h2,
.dark .wishlist-section tbody tr td h2 {
  color: #f1f3f5;
}

.dark .table tbody+tbody {
  border-color: #404040;
}

.dark .table thead th {
  border-color: #404040;
}

.dark .top-banner-wrapper .top-banner-content h4 {
  color: #e7eaec;
}

.dark .top-banner-wrapper .top-banner-content h5 {
  color: #dee2e6;
}

.dark .top-banner-wrapper .top-banner-content p {
  color: #cfd4da;
}

.dark .collection-product-wrapper .product-top-filter {
  border-color: #404040;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.dark .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.dark .collection-product-wrapper .product-top-filter .popup-filter .search-count,
.dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  border-color: #404040;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.dark .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  border-color: #404040;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
  border-color: #404040;
}

.dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
  color: #cbcbcb;
}

.dark .product-pagination {
  border-color: #404040;
}

.dark .product-pagination .pagination .page-item.active a {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .product-pagination .product-search-count-bottom {
  border-color: #404040;
}

.dark .product-pagination .product-search-count-bottom h5 {
  color: #dee2e6;
}

.dark .portfolio-section.metro-section .product-box .product-detail {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .portfolio-section.metro-section .product-box .product-detail h4 {
  color: #e7eaec;
}

.dark .portfolio-section.metro-section .product-box .product-detail h6 {
  color: #cfd4da;
}

.dark .portfolio-section.metro-section .product-box .cart-wrap button {
  border-color: #404040;
  background-color: rgba(43,43,43,0.8);
  transition: all 0.3s ease;
}

.dark .portfolio-section.metro-section .product-box .cart-wrap button i {
  color: #cbcbcb;
}

.dark .portfolio-section.metro-section .product-box .cart-wrap a i {
  border-color: #404040;
  background-color: rgba(43,43,43,0.8);
  transition: all 0.3s ease;
  color: #cbcbcb;
}

.dark .collection-filter-block {
  border-color: #404040;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .collection-collapse-block .collapse-block-title {
  color: #eaedef;
}

.dark .collection-collapse-block .collapse-block-title:after {
  color: #eaedef;
}

.dark .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  color: #929292;
}

.dark .load-more-sec a {
  border-color: #404040;
}

.dark .checkout-page .checkout-title h3 {
  color: #eaedef;
}

.dark .checkout-page .checkout-form .form-group .field-label {
  color: #cfd4da;
}

.dark .checkout-page .checkout-form input[type="text"],
.dark .checkout-page .checkout-form input[type="email"],
.dark .checkout-page .checkout-form input[type="password"],
.dark .checkout-page .checkout-form input[type="tel"],
.dark .checkout-page .checkout-form input[type="number"],
.dark .checkout-page .checkout-form input[type="url"] {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .checkout-page .checkout-form select,
.dark .checkout-page .checkout-form textarea {
  border-color: #404040;
}

.dark .checkout-page .checkout-form .checkout-details {
  background-color: #232323;
  transition: all 0.3s ease;
  border: 1px solid #404040;
}

.dark .order-box .title-box {
  color: #eaedef;
  border-color: #404040;
}

.dark .order-box .qty {
  border-color: #404040;
}

.dark .order-box .qty li {
  color: #cfd4da;
}

.dark .order-box .qty li span {
  color: #dee2e6;
}

.dark .order-box .sub-total {
  border-color: #404040;
}

.dark .order-box .sub-total li {
  color: #cfd4da;
}

.dark .order-box .sub-total .shopping-option label {
  color: #929292;
}

.dark .order-box .total li {
  color: #cfd4da;
}

.dark .payment-box .payment-options li .radio-option label {
  color: #929292;
}

.dark .collection .collection-block .collection-content h4 {
  color: #e7eaec;
}

.dark .collection .collection-block .collection-content h3 {
  color: #eaedef;
}

.dark .table th,
.dark .table td {
  border-color: #404040;
}

.dark .compare-page .table-wrapper .table {
  color: #f1f3f5;
  border-color: #404040;
}

.dark .compare-page .table-wrapper .table thead .th-compare td {
  background: #232323;
  border-color: #404040;
}

.dark .compare-page .table-wrapper .table thead .th-compare th {
  border-color: #404040;
}

.dark .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  color: #cfd4da;
}

.dark .compare-page .table-wrapper .table tbody tr th {
  border-color: #404040;
  background: #232323;
}

.dark .compare-page .table-wrapper .table tbody tr td {
  border-color: #404040;
}

.dark .compare-page .table-wrapper .table tbody tr p {
  color: #cfd4da;
}

.dark .compare-section .compare-part .detail-part .title-detail {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .compare-section .compare-part .btn-part {
  border-color: #404040;
}

.dark .compare-section .compare-part .close-btn {
  color: #e7eaec;
}

.dark .compare-section .slick-slide>div {
  border-color: #404040;
}

.dark .compare-section .slick-slide:first-child {
  border-color: #404040;
}

.dark .contact-page .theme-form label {
  color: #cfd4da;
}

.dark .contact-page .theme-form input {
  border-color: #404040;
}

.dark .contact-page .theme-form textarea {
  border-color: #404040;
}

.dark .contact-page .contact-right ul li .contact-icon {
  border-color: #404040;
}

.dark .dashboard .box-head h2 {
  color: #f1f3f5;
}

.dark .dashboard .box .box-title {
  border-color: #404040;
}

.dark .dashboard .box .box-title h3 {
  color: #eaedef;
}

.dark .dashboard-left .block-content {
  border-color: #404040;
}

.dark .dashboard-left .block-content ul li a {
  color: #cbcbcb;
}

.dark .dashboard-right .dashboard {
  border-color: #404040;
}

.dark .white-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .border-bottom-grey {
  border-color: #404040;
}

.dark .layout-8 .layout-8-bg {
  background-blend-mode: overlay;
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .card {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .card .card-header {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .faq-section .accordion.theme-accordion .card {
  border-color: #404040;
}

.dark .faq-section .accordion.theme-accordion .card .card-header {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .faq-section .accordion.theme-accordion .card .card-header button {
  color: #dee2e6;
}

.dark .faq-section .accordion.theme-accordion .card .card-header button[aria-expanded="true"]:before {
  border-bottom-color: #404040;
}

.dark .faq-section .accordion.theme-accordion .card .card-header button:before {
  border-top-color: #404040;
}

.dark .faq-section .accordion.theme-accordion .card .card-body p {
  color: #cfd4da;
}

.dark.box-layout-body .box-layout-header {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark.box-layout-body .box-layout.bg-image {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none;
  box-shadow: none;
}

.dark.box-layout-body .light-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark.box-layout-body .sub-footer {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .full-box .theme-card .offer-slider .product-box2+.product-box2 {
  border-top: none;
}

.dark .center-slider {
  border-color: #404040;
}

.dark .bg-block {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none;
}

.dark .theme-tab .tab-content .product-tab .tab-box {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
  color: #e7eaec;
}

.dark .flower-bg {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none;
}

.dark .pwd-page .theme-form input {
  border-color: #404040;
}

.dark .full-scroll-menu {
  background-color: transparent;
  transition: all 0.3s ease;
}

.dark .full-scroll-footer .sub-footer {
  background-color: transparent;
  transition: all 0.3s ease;
}

.dark .portfolio-section .filter-button {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cfd4da;
}

.dark .portfolio-section .filter-button.active {
  background-color: #309345;
  transition: all 0.3s ease;
  color: #fff;
}

.dark .portfolio-section .isotopeSelector:hover .overlay-background {
  border-color: #ddd;
}

.dark .portfolio-section .isotopeSelector:hover .overlay-background i {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .portfolio-section .isotopeSelector .overlay {
  border-color: #ddd;
}

.dark .bg-img-gym .dark-layout {
  background-color: transparent;
  transition: all 0.3s ease;
}

.dark .bg-img-gym .sub-footer.darker-subfooter {
  background-color: #000;
  transition: all 0.3s ease;
}

.dark .category-block .category-image {
  border-color: #404040;
}

.dark .category-block .category-image.svg-image {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .j-box .product-box {
  border-color: #404040;
}

.dark .j-box .product-box .product-detail {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .j-box .product-box .cart-info {
  background-color: rgba(35,35,35,0.84);
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .j-box .product-box .cart-info a {
  border-color: #000;
}

.dark .white-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .footer-theme2.footer-border {
  border-color: #404040;
}

.dark .footer-theme2.section-light .footer-block .subscribe-white {
  border-color: #404040;
}

.dark .footer-theme2.section-light .footer-block h4 {
  color: #e7eaec;
}

.dark .footer-theme2.section-light .footer-block .contact-details li a {
  color: #e7eaec;
}

.dark .footer-theme2 .footer-link.link-white h4 {
  color: #e7eaec;
}

.dark .jewel-footer .sub-footer.black-subfooter {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .jewel-footer .sub-footer p {
  color: #cfd4da;
}

.dark .absolute-banner .absolute-bg {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .login-page .theme-card {
  border-color: #404040;
}

.dark .login-page .theme-card .theme-form input {
  border-color: #404040;
}

.dark .login-page .theme-card .theme-form label {
  color: #929292;
}

.dark .login-page .authentication-right h6,
.dark .login-page .authentication-right p {
  color: #cfd4da;
}

.dark .lookbook .lookbook-block .lookbook-dot:before {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .blog-left .blog-details h4 {
  color: #e7eaec;
}

.dark .blog-left .blog-details h6 {
  color: #cfd4da;
}

.dark footer.footer-black .below-section {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark footer.footer-black .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark footer.footer-black .upside .small-section {
  background-color: #383838;
  transition: all 0.3s ease;
}

.dark footer.footer-black .subscribe h4 {
  color: #e7eaec;
}

.dark footer.footer-black .subscribe p {
  color: #cfd4da;
}

.dark footer.footer-black.footer-light .subscribe {
  border-color: #929292;
}

.dark footer.pet-layout-footer .white-layout {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark footer.footer-classic .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark footer.footer-5 {
  background-image: unset;
}

.dark footer.footer-5 .footer-theme2 .subscribe-block {
  border-color: #404040;
}

.dark footer.footer-5 .sub-footer>.container {
  border-color: #404040;
}

.dark .logo-block img {
  -webkit-filter: invert(100);
          filter: invert(100);
}

.dark .logo-block img:hover {
  -webkit-filter: invert(100);
          filter: invert(100);
}

.dark .bg-white {
  background-color: #2b2b2b !important;
  transition: all 0.3s ease;
}

.dark .product-box .img-wrapper .cart-box,
.dark .product-wrap .img-wrapper .cart-box {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .tab-bg.tab-grey-bg {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .delivery-sec {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .product-order .total-sec {
  border-color: #404040;
}

.dark .blog.blog_box .blog-details p {
  color: #cfd4da;
}

.dark .blog.blog_box .blog-details a p {
  color: #cfd4da;
}

.dark .blog.blog_box .blog-details .read-cls {
  color: #cbcbcb;
}

.dark .product-right .product-title {
  color: #cfd4da;
}

.dark .product-right .border-product {
  border-color: #404040;
}

.dark .product-right .product-icon .product-social li a {
  color: #cbcbcb;
}

.dark .product-right .product-icon .wishlist-btn {
  color: #cbcbcb;
}

.dark .product-right .product-icon .wishlist-btn i {
  border-color: #404040;
}

.dark .product-right.product-form-box {
  border-color: #404040;
}

.dark .product-right.product-form-box .timer {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .product-right .timer {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .product-right .timer p {
  color: #cfd4da;
}

.dark .product-right .size-box ul li {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .product-right .size-box ul li.active {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .product-right .size-box ul li a {
  color: #cbcbcb;
}

.dark .product-right h4 del {
  color: #929292;
}

.dark .timer span .timer-cal {
  color: #929292;
}

.dark .qty-box .input-group span button {
  background: #2b2b2b !important;
  border-color: #404040;
}

.dark .qty-box .input-group button i {
  color: #929292;
}

.dark .nav-tabs {
  border-color: #404040;
}

.dark .nav-tabs .nav-link.active {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .tab-product .nav-material.nav-tabs .nav-item .nav-link {
  color: #cbcbcb;
}

.dark .tab-product .nav-material.nav-tabs .nav-link.active {
  color: #309345;
}

.dark .product-full-tab .nav-material.nav-tabs .nav-link.active {
  color: #309345;
}

.dark .tab-product .theme-form input,
.dark .tab-product .theme-form textarea,
.dark .product-full-tab .theme-form input,
.dark .product-full-tab .theme-form textarea {
  border-color: #404040;
}

.dark .product-related h2 {
  border-color: #404040;
}

.dark .product-accordion .btn-link {
  color: #e7eaec;
}

.dark .product-accordion .card-header {
  border-color: rgba(64,64,64,0.125);
}

.dark .theme_checkbox label {
  color: #929292;
}

.dark .theme_checkbox label .checkmark {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .theme_checkbox label .checkmark:after {
  border-color: #fff;
}

.dark .bundle .bundle_detail .price_product {
  color: #fff;
}

.dark .modal-content {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark button.close {
  color: #fff;
}

.dark .modal-header {
  border-color: #404040;
}

.dark .collection-filter-block .product-service .media {
  border-color: #404040;
}

.dark .pro_sticky_info {
  border-color: #404040;
}

.dark .tab-border {
  border-color: #404040;
}

.dark .register-page .theme-card {
  border-color: #404040;
}

.dark .register-page .theme-card .theme-form input {
  border-color: #404040;
}

.dark .register-page .theme-card .theme-form label {
  color: #929292;
}

.dark .category-border {
  background-color: #404040;
  transition: all 0.3s ease;
}

.dark .category-border div .category-banner .category-box h2 {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .background {
  background-color: #404040;
  transition: all 0.3s ease;
}

.dark .background .contain-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .background .contain-bg h4 {
  color: #e7eaec;
}

.dark .background .contain-bg:hover h4 {
  color: #309345;
}

.dark .blog-bg {
  background-color: #404040;
  transition: all 0.3s ease;
}

.dark .sub-footer.black-subfooter {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark.tools-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark.tools-bg section {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .category-tools .category-m .category-wrapper {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .category-m .category-wrapper {
  border-color: #404040;
}

.dark .category-m .category-wrapper h4 {
  color: #e7eaec;
}

.dark .category-m .category-wrapper .category-link li a {
  color: #cbcbcb;
}

.dark .tools-grey .product-box .product-info {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: rgba(64,64,64,0.2);
}

.dark .tools-grey .product-box .cart-info button {
  background-color: #232323;
  transition: all 0.3s ease;
  color: #cbcbcb;
}

.dark .tools-grey .product-box .cart-info a {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .tools-grey .product-box .cart-info a i {
  color: #cbcbcb;
}

.dark .tools-grey .product-box .img-wrapper .front {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .tools-brand .row {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .typography_section .typography-box .headings {
  background-color: rgba(35,35,35,0.5);
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .typography_section .typography-box .headings h3 {
  color: #eaedef;
}

.dark .typography_section .typography-box .headings span {
  color: #929292;
}

.dark .typography_section .typography-box .headings span code {
  color: #929292;
}

.dark .typography_section .typography-box .typo-content .sub-title {
  color: #f1f3f5;
  border-color: rgba(64,64,64,0.7);
}

.dark .typography_section .typo-content.heading_content h1,
.dark .typography_section .typo-content.heading_content h2,
.dark .typography_section .typo-content.heading_content h3,
.dark .typography_section .typo-content.heading_content h4,
.dark .typography_section .typo-content.heading_content h5,
.dark .typography_section .typo-content.heading_content h6 {
  color: white;
}

.dark .typography_section .typo-content.product-pagination .pagination .page-item {
  border-color: #404040;
}

.dark .typography_section code {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .absolute_banner .collection-banner .absolute-contain {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .absolute_banner .collection-banner .absolute-contain h4 {
  color: #e7eaec;
}

.dark .absolute-product .product-box {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .absolute-product .product-box .product-detail .cart-bottom {
  border-color: #404040;
}

.dark .absolute-product .product-box .product-detail .cart-bottom i {
  color: #cbcbcb;
}

.dark .absolute-product .product-box .product-detail .cart-bottom i:hover {
  color: #fff;
}

.dark .box-product .full-box .theme-card .offer-slider .product-box2 {
  box-shadow: none;
}

.dark .box-product .full-box .theme-card .offer-slider .product-box2 .media {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
  border-color: #404040;
}

.dark .insta-title {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .tab-bg {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .darken-layout {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .darken-layout p {
  color: #cfd4da;
}

.dark .darken-layout .sub-title .contact-list li {
  color: #cfd4da;
}

.dark .darken-layout .sub-title .contact-list i {
  color: #cfd4da;
}

.dark .darken-layout .footer-social i {
  color: #cfd4da;
}

.dark .sub-footer.dark-subfooter p {
  color: #cfd4da;
}

.dark .cart-section tbody tr td a,
.dark .cart-section tbody tr td p,
.dark .wishlist-section tbody tr td a,
.dark .wishlist-section tbody tr td p {
  color: #cbcbcb;
}

.dark .footer-title {
  border-color: #404040;
}

.dark .category-bg {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .category-bg .contain-block {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .category-bg .contain-block h2 {
  color: #f1f3f5;
}

.dark .category-bg .contain-block h6 span {
  color: #929292;
}

.dark .service_slide .service-home .service-block1 {
  background-color: rgba(35,35,35,0.9);
  transition: all 0.3s ease;
}

.dark .service_slide .service-home .service-block1:nth-child(even) {
  background-color: rgba(35,35,35,0.7);
  transition: all 0.3s ease;
}

.dark .service_slide .service-home .service-block1 svg path {
  fill: #fff;
}

.dark .bg-grey {
  background-color: #232323;
  transition: all 0.3s ease;
}

.dark .detail-cannabis .detail_section>div {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: none;
}

.dark .detail-cannabis .detail_section svg {
  fill: #fff;
}

.dark .border-box.tools-grey .product-box {
  border-color: #404040;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body {
  background-image: linear-gradient(135deg, #309345 5.77%, #232323 5.77%, #232323 25%, #f1f3f5 25%, #f1f3f5 30.77%, #232323 30.77%, #232323 50%, #309345 50%, #309345 55.77%, #232323 55.77%, #232323 75%, #f1f3f5 75%, #f1f3f5 80.77%, #232323 80.77%, #232323 100%);
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
  color: #e7eaec;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {
  color: #fff;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
  color: #f1f3f5;
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .layout-container {
  box-shadow: none;
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .demo-text h4 {
  color: #e7eaec;
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .demo-text .demo-btn .btn {
  border-color: #404040;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cbcbcb;
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .title-text h3 {
  color: #eaedef;
}

.dark .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: #cbcbcb;
}

.dark .theme-modal.exit-modal .media .media-body h5 {
  color: #dee2e6;
}

.dark .theme-modal.exit-modal .stop {
  -webkit-filter: invert(100);
          filter: invert(100);
}

.dark #quick-view .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

.dark .addcart_btm_popup {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}

.dark .addcart_btm_popup .fixed_cart i {
  color: #cbcbcb;
}

.dark .review-page .comnt-sec li a {
  color: #cbcbcb;
}

.dark .sitemap_page ul li a {
  color: #cbcbcb;
}

.dark .sitemap_page ul li a:hover {
  color: #309345;
}

.dark .sitemap_page ul ul li a {
  color: #dee2e6;
}

.dark .effect-cls:before,
.dark .effect-cls:after {
  -webkit-filter: invert(100);
          filter: invert(100);
}

.dark .game-product .product-box .cart-info a i {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cbcbcb;
}

.dark .demo-right a {
  background-color: #000;
  transition: all 0.3s ease;
}

.dark .rtl-btn,
.dark .dark-light {
  background-color: #000;
  box-shadow: none;
}

.dark .color-picker a.handle {
  background-color: #000;
  transition: all 0.3s ease;
  color: #cbcbcb;
}

.dark .setting-box .setting-title h4 {
  color: #333;
}

.portfolio-padding {
  padding-bottom: 40px;
}

.portfolio-section .article-title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: white;
  transition: all 0.5s ease;
  text-align: center;
  float: left;
  font-size: 18px;
  line-height: 24px;
}

.portfolio-section .article-title a {
  color: white;
}

.portfolio-section .isotopeSelector {
  float: left;
  width: 100%;
  position: relative;
}

.portfolio-section .isotopeSelector .overlay {
  position: relative;
  border: 1px solid #ddd;
}

.portfolio-section .isotopeSelector img {
  width: 100%;
  height: auto;
}

.portfolio-section .isotopeSelector {
  margin-bottom: 30px;
}

.portfolio-section .isotopeSelector img {
  transition: all .4s ease;
}

.portfolio-section .isotopeSelector .overlay-background {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all .4s ease;
}

.portfolio-section .isotopeSelector .overlay-background i {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 10px;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #309345;
  font-size: 22px;
  display: none;
  transition: all 0.4s ease;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.portfolio-section .isotopeSelector:hover img {
  -webkit-transform: scale(1.2) rotate(2deg);
          transform: scale(1.2) rotate(2deg);
  transition: all .4s ease;
}

.portfolio-section .isotopeSelector:hover .overlay-background {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 3px solid white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(48,147,69,0.2);
  transition: all 0.4s ease;
}

.portfolio-section .isotopeSelector:hover .overlay-background i {
  display: flex;
  transition: all 0.4s ease;
}

.portfolio-section .border-portfolio {
  margin: 3px;
  overflow: hidden;
}

.portfolio-section #form1 {
  padding-bottom: 20px;
}

.portfolio-section .filter-button {
  padding: 0 24px;
  font-size: 20px;
  line-height: 35px;
  border: none;
  background-color: white;
}

.portfolio-section .filter-button.active {
  background-color: #309345;
  color: #fff;
}

.portfolio-section.fullwidth-portfolio .isotopeSelector {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 14px;
}

.portfolio-section.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center;
}

.portfolio-section.metro-section .product-box .product-detail h6 {
  color: #525252;
  padding-top: 0;
  margin-top: -2px;
}

.portfolio-section.metro-section .product-box .product-detail h4 {
  font-weight: 400;
  color: #000;
}

.portfolio-section.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1;
}

.portfolio-section.metro-section .product-box .cart-wrap button {
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: rgba(255,255,255,0.8);
  opacity: 1;
}

.portfolio-section.metro-section .product-box .cart-wrap button i {
  padding: 7px;
  color: #333;
}

.portfolio-section.metro-section .product-box .cart-wrap a i {
  border-radius: 100%;
  border: 1px solid #fff;
  margin: 5px 0;
  background-color: rgba(255,255,255,0.8);
  padding: 7px;
  color: #333;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-section.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap button {
  -webkit-animation: none;
          animation: none;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  -webkit-animation: fadeInDown 500ms ease-in-out;
          animation: fadeInDown 500ms ease-in-out;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  -webkit-animation: fadeInDown 700ms ease-in-out;
          animation: fadeInDown 700ms ease-in-out;
}

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  -webkit-animation: fadeInDown 1000ms ease-in-out;
          animation: fadeInDown 1000ms ease-in-out;
}

.filter-section .filter-container {
  text-align: center;
  padding: 0 0 20px;
}

.filter-section .filter-container ul.filter>li {
  padding: 0 25px;
}

.filter-section .filter-container ul.filter>li>a {
  color: #309345;
  font-size: 20px;
  line-height: 40px;
}

.filter-section .filter-container ul.filter>li>a:hover,
.filter-section .filter-container ul.filter>li>a:focus {
  text-decoration: none;
  color: #309345;
  outline: none;
}

.filter-section .filter-container ul.filter>li.active {
  background-color: #309345;
}

.filter-section .filter-container ul.filter>li.active a {
  color: #fff;
}

.filter-section .filter-container ul.filter>li span {
  color: #ddd;
  font-size: 20px;
  display: inline-block;
  margin: 0 5px;
}

.filter-section .filter-container .filter>li {
  padding: 0;
  margin: 0;
}

.filter-section .title1 .title-inner1 {
  margin-top: -5px;
}

.filter-section h1 {
  text-align: center;
}

.demo-colored-wrap {
  background: #ecf0f1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-title {
  display: none;
}

.offer-box {
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  left: -415px;
  transition: all 0.5s ease;
  z-index: 9;
}

.offer-box img {
  margin-right: 18px;
}

.offer-box.toggle-cls {
  left: 0;
  transition: all 0.5s ease;
}

.offer-box .heading-right h3 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 230px;
  height: 36px;
  line-height: 36px;
  letter-spacing: 5px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transform: translate(50%, -50%) rotate(-90deg);
          transform: translate(50%, -50%) rotate(-90deg);
  text-align: center;
  background-color: #309345;
  margin-bottom: 0;
  cursor: pointer;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  width: 100%;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column>div {
  margin-bottom: 30px;
}

.post-detail-html div[style*="text-align:none"] {
  text-align: center !important;
}

.sm,
.sm ul,
.sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}

.sm {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.sm ul {
  display: none;
}

.sm li {
  position: relative;
}

.sm>li>h1 {
  margin: 0;
  padding: 0;
}

.sm>li>h2 {
  margin: 0;
  padding: 0;
}

.sm>li>h3 {
  margin: 0;
  padding: 0;
}

.sm>li>h4 {
  margin: 0;
  padding: 0;
}

.sm>li>h5 {
  margin: 0;
  padding: 0;
}

.sm>li>h6 {
  margin: 0;
  padding: 0;
}

.sm a {
  position: relative;
  display: block;
}

.sm a.disabled {
  cursor: default;
}

.sm * {
  box-sizing: inherit;
}

.sm *::before {
  box-sizing: inherit;
}

.sm *::after {
  box-sizing: inherit;
}

.sm::after {
  content: "";
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  overflow: hidden;
}

header .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}

header .main-navbar .nav-menu>li {
  position: relative;
  float: left;
  display: block;
}

header .main-navbar .nav-menu>li>a {
  display: block;
  padding-right: 20px;
  color: #222222;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 42px;
  padding-bottom: 42px;
  padding-left: 0;
  letter-spacing: 0.04em;
  position: relative;
}

header .main-navbar .nav-menu>li>a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}

header .main-navbar .nav-menu>li>a .sub-arrow:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
}

header .main-navbar .nav-menu>li .nav-submenu {
  position: absolute;
  left: 0;
  z-index: 4;
  box-shadow: 0 0 1px 0 #ebebeb;
  padding: 15px 0px 15px 0px;
  background: white;
}

header .main-navbar .nav-menu>li .nav-submenu li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}

header .main-navbar .nav-menu>li .nav-submenu li a {
  border: 0 !important;
  background: transparent;
  color: #222222;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 5px 35px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
}

header .main-navbar .nav-menu>li .nav-submenu li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: #ff4c3b;
  transition: 0.5s ease;
}

header .main-navbar .nav-menu>li .nav-submenu li a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}

header .main-navbar .nav-menu>li .nav-submenu li a .sub-arrow:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
}

header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  box-shadow: 0 0 1px 0 #ebebeb;
}

header .main-navbar .nav-menu>li .mega-menu-container {
  position: absolute;
  left: 0;
  z-index: 4;
  box-shadow: 0 0 1px 0 #ebebeb;
  padding: 30px;
  background: white;
  width: 100%;
}

header .main-navbar .nav-menu>li .mega-menu-container .mega-box {
  width: 20%;
}

header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-title h5 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
}

header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content {
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}

header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}

header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  border: 0 !important;
  background: transparent;
  color: #222222;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 3px 0;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
}

header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: #ff4c3b;
  transition: 0.5s ease;
}

header .main-navbar .nav-menu>li:hover>a {
  color: #309345;
}

header .main-navbar .nav-menu>li.mega-menu {
  position: unset;
}

header .sidenav {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -300px;
  background-color: #fff;
  transition: all 0.5s ease;
}

header .sidenav .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  margin-bottom: 0px;
  cursor: pointer;
}

header .sidenav nav {
  background-color: #fff;
  z-index: 9;
  position: relative;
  height: 100vh;
  top: 0;
}

header .sidenav.open-side {
  left: 0;
  transition: all 0.5s ease;
}

header .sidenav.open-side .sidebar-overlay {
  opacity: 0.8;
  visibility: visible;
}

header .sidenav .sidebar-overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1s;
}

header .sidenav .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  margin-left: 50px;
  margin-top: 50px;
  color: #938d8c;
}

header .sidenav .sidebar-menu {
  background-color: #ffffff;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
}

header .sidenav .sidebar-menu li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
  float: none;
}

header .sidenav .sidebar-menu li a {
  padding: 10px 50px 20px 40px;
  position: relative;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: block;
}

header .sidenav .sidebar-menu li ul {
  position: absolute;
  width: 200px;
  left: 100%;
  top: 0;
  padding: 15px 0px;
  display: none;
  background-color: #fff;
}

header .sidenav .sidebar-menu li ul li a {
  padding: 5px 35px;
}

header .sidenav .sidebar-menu li ul li a:active,
header .sidenav .sidebar-menu li ul li a:focus,
header .sidenav .sidebar-menu li ul li a.highlighted {
  padding: 5px 35px;
}

header .sidenav .sidebar-menu li .mega-menu {
  position: absolute;
  left: 100%;
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 #ebebeb;
}

header .sidenav .sidebar-menu li .mega-menu li ul {
  position: relative;
  width: 100%;
  left: inherit;
}

header .sidenav .pixelstrap li a {
  padding: 10px 50px 20px 40px;
}

header .sidenav .pixelstrap li ul li a {
  padding: 5px 35px;
}

header .sidenav .pixelstrap li ul li a:active,
header .sidenav .pixelstrap li ul li a:focus,
header .sidenav .pixelstrap li ul li a.highlighted {
  padding: 5px 35px;
}

header .sidenav .pixelstrap li .clothing-menu li a {
  padding: 5px 0 !important;
}

header .sidenav .pixelstrap li .clothing-menu li a:focus {
  font-size: 14px;
}

header .sidenav .pixelstrap li .clothing-menu li a:hover {
  font-size: 14px;
  padding: 5px 0;
}

header .sidenav .pixelstrap li .clothing-menu li a:focus,
header .sidenav .pixelstrap li .clothing-menu li a:hover {
  padding: 5px 0 !important;
}

header .layout3-menu .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}

header .layout3-menu .main-navbar .nav-menu>li {
  position: relative;
  float: left;
  display: block;
}

header .layout3-menu .main-navbar .nav-menu>li>a {
  text-transform: capitalize;
  padding-right: 34px;
  letter-spacing: 0.04em;
}

header .layout3-menu .main-navbar .nav-menu>li.mega-menu {
  position: unset;
}

.toggle-nav {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;
}

.toggle-nav i {
  font-size: 24px;
  color: #36a64e;
}

.mega-menu-banner:hover:before {
  display: none;
}

.new-tag {
  padding-left: 3px;
  color: #309345;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.up-text {
  position: relative;
}

.up-text span {
  position: absolute;
  top: -5px;
  right: -10px;
  color: #309345;
  font-size: 12px;
  font-weight: 700;
}

.pixelstrap.sm-vertical a .sub-arrow {
  margin-top: -22px;
  right: 7px;
}

.pixelstrap ul {
  border-radius: 0;
}

.pixelstrap.light-font-menu li>a {
  color: #393230;
}

.pixelstrap a,
.pixelstrap a:hover,
.pixelstrap a:active {
  padding-right: 45px;
  color: #222;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 42px;
  padding-bottom: 42px;
  letter-spacing: 0.07em;
}

.pixelstrap a:hover {
  color: #309345;
}

.pixelstrap a.disabled {
  color: #85d796;
}

.pixelstrap a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}

.pixelstrap>li:last-child>a,
.pixelstrap>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul {
  border-radius: 0 0 0 0;
}

.pixelstrap>li:last-child>a.highlighted,
.pixelstrap>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted {
  border-radius: 0;
}

.pixelstrap ul {
  background: #fff;
}

.pixelstrap ul ul {
  background: rgba(51,155,73,0.04);
}

.pixelstrap ul a,
.pixelstrap ul a:hover,
.pixelstrap ul a:focus,
.pixelstrap ul a:active {
  background: rgba(0,0,0,0);
  color: #2a803c;
  font-size: 14px;
  text-shadow: none;
}

.pixelstrap ul a.current {
  background: #309345;
  color: #fff;
}

.pixelstrap ul a.disabled {
  color: #b3b3b3;
}

.pixelstrap ul ul a,
.pixelstrap ul ul a:hover,
.pixelstrap ul ul a:focus,
.pixelstrap ul ul a:active {
  border-left: 16px solid transparent;
}

.pixelstrap ul ul ul a,
.pixelstrap ul ul ul a:hover,
.pixelstrap ul ul ul a:focus,
.pixelstrap ul ul ul a:active {
  border-left: 24px solid transparent;
}

.pixelstrap ul ul ul ul a,
.pixelstrap ul ul ul ul a:hover,
.pixelstrap ul ul ul ul a:focus,
.pixelstrap ul ul ul ul a:active {
  border-left: 32px solid transparent;
}

.pixelstrap ul ul ul ul ul a,
.pixelstrap ul ul ul ul ul a:hover,
.pixelstrap ul ul ul ul ul a:focus,
.pixelstrap ul ul ul ul ul a:active {
  border-left: 40px solid transparent;
}

.pixelstrap ul li {
  border-top: 1px solid rgba(0,0,0,0.05);
}

.pixelstrap ul li:first-child {
  border-top: 0;
}

.pixelstrap .mega {
  position: unset !important;
}

.pixelstrap .mega .full-mega-menu.center-mega-menu {
  margin-left: 0 !important;
}

.pixelstrap .mega ul {
  margin-left: 0 !important;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.pixelstrap .icon-trend {
  color: #309345;
}

.pixelstrap .full-mega-menu,
.pixelstrap .clothing-menu {
  max-width: inherit !important;
  padding: 30px 0 15px;
}

.pixelstrap .full-mega-menu a,
.pixelstrap .clothing-menu a {
  padding: 0;
  position: relative;
}

.pixelstrap .full-mega-menu a h6,
.pixelstrap .clothing-menu a h6 {
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease;
  font-weight: 400;
  text-transform: capitalize;
  color: #222222;
}

.pixelstrap .full-mega-menu a img,
.pixelstrap .clothing-menu a img {
  display: flex;
  margin: 0 auto;
}

.pixelstrap .full-mega-menu a:hover,
.pixelstrap .clothing-menu a:hover {
  padding: 0;
}

.pixelstrap .full-mega-menu a:hover:before,
.pixelstrap .clothing-menu a:hover:before {
  width: 50px;
  height: 2px;
  transition: 0.5s ease;
}

.pixelstrap .full-mega-menu a:hover h6,
.pixelstrap .clothing-menu a:hover h6 {
  transition: all 0.5s ease;
}

.pixelstrap .link-section h5 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
}

.pixelstrap .link-section ul {
  position: relative;
  display: block;
  padding: 0;
}

.pixelstrap .link-section ul li a {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  padding-left: 0;
}

.pixelstrap .full-mega-menu {
  width: 100% !important;
  padding: 30px !important;
  border-radius: 0;
}

.pixelstrap .full-mega-menu .mega-box .link-section ul li a:hover,
.pixelstrap .full-mega-menu .mega-box .link-section ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pixelstrap .full-mega-menu .img-section img {
  margin-top: 15px;
}

.pixelstrap .clothing-menu {
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #fff;
}

.pixelstrap .clothing-menu li img {
  padding: 20px 0 10px 0;
}

.pixelstrap .clothing-menu .link-section h5 {
  margin-top: 15px;
}

.pixelstrap .clothing-menu .link-section ul {
  background: none;
}

.pixelstrap .mega-menu ul {
  box-shadow: none;
}

.pixelstrap li a {
  position: relative;
}

.pixelstrap li .lable-nav {
  position: absolute;
  background-color: #309345;
  padding: 2px 7px;
  color: #fff;
  top: 20px;
  text-transform: uppercase;
  font-size: 9px;
  left: 0;
  line-height: 1.3;
}

.pixelstrap li .lable-nav:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #309345;
  top: 15px;
  left: 5px;
}

.pixelstrap li .lable-nav.lable-red {
  background-color: #ff4c3b;
}

.pixelstrap li .lable-nav.lable-red:before {
  border-top: 5px solid #ff4c3b;
}

.sm-vertical {
  background-color: #fff;
}

.mega-box .link-section .demo ul li a {
  position: relative;
}

.mega-box .link-section .demo ul li a:hover,
.mega-box .link-section .demo ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}

.mega-box .link-section .demo .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: #309345;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3;
}

.mega-box .link-section .demo .title {
  border-bottom: 2px solid red !important;
}

.rtl .sidenav .pixelstrap li {
  text-align: right;
}

.rtl .link-section {
  float: right;
}

.rtl .pixelstrap li .lable-nav {
  left: unset;
}

.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px;
}

@media (min-width: 1200px) {
  .responsive-btn {
    display: none;
  }

  header .main-navbar .nav-menu>li .nav-submenu {
    width: 200px;
    display: none;
  }

  header .main-navbar .nav-menu>li .nav-submenu li a:hover:before {
    width: 50px;
    height: 2px;
    transition: 0.5s ease;
  }

  header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu {
    display: none;
  }

  header .main-navbar .nav-menu>li .nav-submenu li:hover .nav-sub-childmenu {
    display: block;
  }

  header .main-navbar .nav-menu>li .mega-menu-container {
    display: none;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover:before {
    width: 50px;
    height: 2px;
    transition: 0.5s ease;
  }

  header .main-navbar .nav-menu>li:hover .nav-submenu {
    display: block;
  }

  header .main-navbar .nav-menu>li:hover .mega-menu-container {
    display: block;
  }

  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: auto;
    right: 7px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }

  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
  }

  header .sidenav .sidebar-menu li>ul {
    display: none;
  }

  header .sidenav .sidebar-menu li>ul li a {
    padding: 5px 35px;
    text-transform: capitalize;
  }

  header .sidenav .sidebar-menu li>ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: #ff4c3b;
    transition: 0.5s ease;
  }

  header .sidenav .sidebar-menu li>ul li a:hover:before {
    width: 50px;
    height: 2px;
    transition: 0.5s ease;
  }

  header .sidenav .sidebar-menu li>ul li a .sub-arrow {
    margin-top: -17px;
  }

  header .sidenav .sidebar-menu li:hover>ul {
    display: block;
  }

  header .sidenav .sidebar-menu li .mega-menu {
    position: absolute;
    left: 100%;
    width: 815px !important;
    padding: 15px 30px 25px !important;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 #ebebeb;
  }

  header .sidenav .sidebar-menu li .mega-menu li .link-section h5 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
    margin-top: 15px;
  }

  header .sidenav .sidebar-menu li .mega-menu li .link-section ul {
    position: relative;
    width: 100%;
    left: inherit;
    display: block;
    padding: 0;
  }

  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a {
    padding: 5px 0;
  }

  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: #ff4c3b;
    transition: 0.5s ease;
  }

  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:hover:before {
    width: 50px;
    height: 2px;
    transition: 0.5s ease;
  }

  header .sidenav .sidebar-menu li .mega-menu li .mega-menu-banner {
    padding: 0;
  }

  header .sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px;
  }

  header .sidenav .pixelstrap li ul li a {
    padding: 5px 35px;
  }

  header .sidenav .pixelstrap li ul li a:active,
  header .sidenav .pixelstrap li ul li a:focus,
  header .sidenav .pixelstrap li ul li a.highlighted {
    padding: 5px 35px;
  }

  header .sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important;
  }

  header .sidenav .pixelstrap li .clothing-menu li a:focus {
    font-size: 14px;
  }

  header .sidenav .pixelstrap li .clothing-menu li a:hover {
    font-size: 14px;
    padding: 5px 0;
  }

  header .sidenav .pixelstrap li .clothing-menu li a:focus,
  header .sidenav .pixelstrap li .clothing-menu li a:hover {
    padding: 5px 0 !important;
  }

  .toggle-nav {
    display: none;
  }

  .sm-vertical li ul li a .sub-arrow {
    margin-top: -15px !important;
  }

  .mobile-back {
    display: none !important;
  }

  .pixelstrap ul {
    position: absolute;
    width: 16em !important;
  }

  .pixelstrap li {
    position: relative;
    float: left;
  }

  .pixelstrap.sm-rtl li {
    float: right;
  }

  .pixelstrap ul li,
  .pixelstrap.sm-rtl ul li,
  .pixelstrap.sm-vertical li {
    float: none;
  }

  .pixelstrap a {
    white-space: nowrap;
  }

  .pixelstrap ul a,
  .pixelstrap.sm-vertical a {
    white-space: normal;
  }

  .pixelstrap .sm-nowrap>li>a,
  .pixelstrap .sm-nowrap>li>:not(ul) a {
    white-space: nowrap;
  }

  .pixelstrap .sm-nowrap>li>a.highlighted,
  .pixelstrap .sm-nowrap>li>:not(ul) a.highlighted {
    white-space: nowrap;
  }

  .sm-horizontal .mega-menu li a {
    padding: 0;
  }

  .sm-horizontal .mega-menu li a:focus,
  .sm-horizontal .mega-menu li a:active {
    padding: 0;
  }

  .pixelstrap ul {
    box-shadow: 0 0 1px 0 #ebebeb;
  }

  .pixelstrap .mega {
    position: unset !important;
  }

  .pixelstrap .mega ul {
    margin-left: 0 !important;
  }

  .pixelstrap .mega ul ul {
    box-shadow: none;
  }

  .pixelstrap .full-mega-menu,
  .pixelstrap .clothing-menu {
    box-shadow: 0 0 1px 0 #ebebeb;
  }

  .pixelstrap .full-mega-menu a,
  .pixelstrap .clothing-menu a {
    padding: 0;
    position: relative;
  }

  .pixelstrap .full-mega-menu {
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }

  .pixelstrap .full-mega-menu.center-mega-menu {
    left: 50% !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .pixelstrap .full-mega-menu.layout-3 {
    margin-left: -100px !important;
    -webkit-transform: unset !important;
            transform: unset !important;
  }

  .pixelstrap .full-mega-menu ul {
    background-color: transparent;
  }

  .pixelstrap .full-mega-menu .mega-box {
    max-width: 20%;
  }

  .pixelstrap .category-menu.center-menu {
    left: 50% !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .pixelstrap a .sub-arrow::before {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
  }

  .pixelstrap ul {
    border: none;
    padding: 15px 0px 15px 0px;
    background: white;
  }

  .pixelstrap ul ul {
    background: white;
  }

  .pixelstrap ul a {
    position: relative;
  }

  .pixelstrap ul a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: #309345;
    transition: 0.5s ease;
  }

  .pixelstrap ul a,
  .pixelstrap ul a:hover,
  .pixelstrap ul a:focus,
  .pixelstrap ul a:active,
  .pixelstrap ul a.highlighted {
    border: 0 !important;
    background: rgba(0,0,0,0);
    color: #222;
    border-radius: 0 !important;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 5px 35px;
  }

  .pixelstrap ul a:hover:before,
  .pixelstrap ul a:hover:hover:before,
  .pixelstrap ul a:focus:hover:before,
  .pixelstrap ul a:active:hover:before,
  .pixelstrap ul a.highlighted:hover:before {
    width: 50px;
    height: 2px;
    transition: 0.5s ease;
  }

  .pixelstrap ul a .sub-arrow:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
  }

  .pixelstrap ul li {
    border: 0;
  }

  .pixelstrap .scroll-up,
  .pixelstrap .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #fff;
    height: 20px;
  }

  .pixelstrap .scroll-up-arrow,
  .pixelstrap .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 8px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #2a803c transparent;
  }

  .pixelstrap .scroll-down-arrow {
    top: 6px;
    border-style: solid dashed dashed dashed;
    border-color: #2a803c transparent transparent transparent;
  }

  .pixelstrap.sm-rtl.sm-vertical a .sub-arrow:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
  }

  .pixelstrap.sm-rtl>li:first-child {
    border-left: 1px solid #2a803c;
  }

  .pixelstrap.sm-rtl>li:last-child {
    border-left: 0;
  }

  .pixelstrap.sm-rtl ul a .sub-arrow {
    right: auto;
    left: 10px;
  }

  .pixelstrap.sm-vertical a {
    padding: 9px 23px;
  }

  .pixelstrap.sm-vertical a .sub-arrow:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
  }

  .pixelstrap.sm-vertical>li {
    border-left: 0 !important;
  }

  .main-nav-center>nav {
    text-align: center;
  }

  .main-nav-center .sm {
    display: inline-block;
    vertical-align: middle;
  }

  .main-nav-center .sm>li .lable-nav {
    top: 3px;
  }

  .main-nav-center .sm>li>a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .layout3-menu .pixelstrap a {
    text-transform: capitalize;
    padding-right: 34px;
    letter-spacing: 0.04em;
  }

  .layout3-menu .pixelstrap .sub-arrow {
    right: 12px;
  }

  .rtl .pixelstrap .category-menu {
    width: 698px !important;
  }

  .rtl .pixelstrap .category-menu.center-menu {
    left: 0 !important;
    -webkit-transform: unset;
            transform: unset;
  }

  .rtl .pixelstrap .full-mega-menu {
    right: auto !important;
  }

  .rtl .pixelstrap ul a:before {
    right: 0;
  }

  .rtl .pixelstrap ul a:hover,
  .rtl .pixelstrap ul a:focus,
  .rtl .pixelstrap ul a:active,
  .rtl .pixelstrap ul a.highlighted {
    padding-right: 0;
  }

  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important;
  }

  .rtl .mega .full-mega-menu.center-mega-menu {
    margin-right: 0 !important;
    margin-left: 0 !important;
    left: unset !important;
    right: 50% !important;
  }

  .rtl .layout3-menu .light-font-menu li>a,
  .rtl .layout3-menu .light-font-menu li>a:hover,
  .rtl .layout3-menu .light-font-menu li>a:active {
    padding-right: 0;
    padding-left: 28px;
  }

  .rtl .layout3-menu .light-font-menu .sub-arrow {
    left: -5px;
    right: auto;
  }

  .rtl .sm-horizontal .mega ul {
    float: right;
    left: 50% !important;
    right: 0;
  }

  .rtl .sm-horizontal .mega ul li {
    text-align: right;
  }

  .rtl .sm-horizontal ul li {
    text-align: right;
  }

  .rtl .sm-horizontal ul li a .sub-arrow {
    left: 15px;
    right: auto;
  }

  .rtl .pixelstrap .full-mega-menu.layout-3 {
    margin-right: -100px !important;
  }

  .rtl .light-font-menu .mega ul {
    float: right;
    left: unset !important;
    right: 0 !important;
  }

  .rtl .sidenav .pixelstrap li ul li a:hover,
  .rtl .sidenav .pixelstrap li ul li a:focus {
    padding: 5px 35px;
  }

  .rtl .sidenav .pixelstrap li ul li a:before {
    right: 35px;
  }

  .rtl .sidenav .pixelstrap li a .sub-arrow {
    right: 7px;
    left: unset;
  }

  .rtl .sidenav .pixelstrap .mega-menu li ul li a:before {
    right: 0;
  }

  .rtl .sidenav .sidebar-back i {
    float: left;
  }

  .rtl header.header-5 .top-header .header-dropdown>li:last-child {
    padding: 15px 25px;
    padding-left: 0;
  }
}

@media (max-width: 1440px) {
  .pixelstrap .category-menu {
    width: 698px !important;
  }

  .pixelstrap .full-mega-menu.layout-3 {
    margin-left: 0 !important;
  }

  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important;
  }

  .rtl .pixelstrap .mega .full-mega-menu.layout-3 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1367px) {
  .pixelstrap .full-mega-menu.layout-3 {
    width: 1079px !important;
  }

  .pixelstrap a {
    font-size: 14px;
  }

  .pixelstrap a:hover,
  .pixelstrap a:active {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  header .main-navbar .nav-menu {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
  }

  header .main-navbar .nav-menu.opennav {
    right: 0;
  }

  header .main-navbar .nav-menu .back-btn {
    display: block;
    width: 100%;
  }

  header .main-navbar .nav-menu .back-btn .mobile-back {
    padding: 20px;
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  header .main-navbar .nav-menu>li {
    display: block;
    float: none;
    width: 100%;
  }

  header .main-navbar .nav-menu>li a {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    position: relative;
  }

  header .main-navbar .nav-menu>li a .sub-arrow {
    right: 0;
  }

  header .main-navbar .nav-menu>li a .sub-arrow:before {
    content: '+';
    font-family: inherit;
    position: relative;
  }

  header .main-navbar .nav-menu>li .nav-submenu {
    width: 100%;
    padding: 0;
    position: relative;
    display: none;
  }

  header .main-navbar .nav-menu>li .nav-submenu.opensubmenu {
    display: block;
  }

  header .main-navbar .nav-menu>li .nav-submenu li a {
    text-transform: uppercase;
  }

  header .main-navbar .nav-menu>li .nav-submenu li a .sub-arrow {
    right: 0;
  }

  header .main-navbar .nav-menu>li .nav-submenu li a .sub-arrow:before {
    content: '+';
    font-family: inherit;
    position: relative;
  }

  header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu {
    display: none;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
    background: rgba(255,85,69,0.04);
  }

  header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu.opensubchild {
    display: block;
  }

  header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu li a {
    padding: 5px 35px 5px 50px;
  }

  header .main-navbar .nav-menu>li .mega-menu-container {
    display: none;
    padding: 0 30px;
  }

  header .main-navbar .nav-menu>li .mega-menu-container.opensubmenu {
    display: block;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box {
    width: 100%;
    padding: 0;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-title h5 {
    margin-bottom: 0;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow {
    font: bold 16px/34px monospace !important;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow:before {
    content: "+";
    position: absolute;
    right: -16px;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content {
    display: none;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content.opensubmegamenu {
    display: block;
    padding: 0 15px;
  }

  header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a {
    line-height: 1.7;
  }

  header .main-navbar .nav-menu .cat-onhover-dropdown .onhover-show-div {
    display: block;
    position: relative;
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    visibility: visible;
    top: 0;
    left: 30px;
    right: 0;
  }

  header .sidenav .sidebar-menu li a {
    padding: 10px 25px;
    font-weight: 700;
  }

  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }

  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: '+';
    font-family: inherit;
    position: relative;
  }

  header .sidenav .sidebar-menu li ul {
    position: relative;
    left: 0;
    width: 100%;
    display: none;
    padding: 5px 0;
  }

  header .sidenav .sidebar-menu li ul.opensub1 {
    display: block;
  }

  header .sidenav .sidebar-menu li ul li a {
    font-size: 12px;
    font-weight: 400;
  }

  header .sidenav .sidebar-menu li ul li ul {
    display: none;
    background: rgba(255,85,69,0.04);
  }

  header .sidenav .sidebar-menu li ul li ul.opensub2 {
    display: block;
  }

  header .sidenav .sidebar-menu li ul li ul li a {
    padding: 5px 45px;
  }

  header .sidenav .sidebar-menu li ul li ul li ul {
    display: none;
    background: rgba(255,85,69,0.04);
  }

  header .sidenav .sidebar-menu li ul li ul li ul.opensub3 {
    display: block;
  }

  header .sidenav .sidebar-menu li ul li ul li ul li ul {
    display: none;
  }

  header .sidenav .sidebar-menu li ul li ul li ul li ul.opensub4 {
    display: block;
  }

  header .sidenav .sidebar-menu li .mega-menu {
    width: 100% !important;
    padding: 0 30px !important;
    position: relative;
    display: none;
    left: 0;
  }

  header .sidenav .sidebar-menu li .mega-menu.opensidesubmenu {
    display: block;
    padding: 10px 30px 0 !important;
    box-shadow: none;
  }

  header .sidenav .sidebar-menu li .mega-menu .link-section h5 {
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
  }

  header .sidenav .sidebar-menu li .mega-menu .link-section ul {
    display: block;
    padding: 0;
    background: #fff;
  }

  header .sidenav .sidebar-menu li .mega-menu .link-section ul li a {
    padding: 5px 0px 5px 10px !important;
    font-size: 12px;
    font-weight: 400;
  }

  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner {
    padding: 5px 0;
  }

  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner img {
    padding: 20px 0 0;
  }

  .mega-box .link-section .demo ul li a span img {
    top: 30px;
    right: -60px;
  }

  .sm-horizontal {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
  }

  .sm-horizontal .mobile-back {
    padding: 20px;
    font-size: 18px;
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .mega-box.col {
    flex-basis: unset;
  }

  .pixelstrap .feature-menu.product-menu {
    width: 100% !important;
  }

  .pixelstrap .mega {
    position: unset !important;
  }

  .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
  }

  .pixelstrap .mega ul {
    margin-left: 0 !important;
  }

  .pixelstrap .full-mega-menu,
  .pixelstrap .clothing-menu {
    width: 100% !important;
    padding: 0 30px !important;
  }

  .pixelstrap a.highlighted .sub-arrow:before {
    content: '-';
  }

  .pixelstrap a .sub-arrow {
    right: 0;
  }

  .pixelstrap a .sub-arrow:before {
    content: '+';
  }

  .pixelstrap>li>a {
    font-weight: 700;
  }

  .pixelstrap>li>a:hover,
  .pixelstrap>li>a:focus,
  .pixelstrap>li>a:active {
    font-weight: 700;
  }

  .pixelstrap .link-section .menu-title h5 {
    cursor: pointer;
  }

  .pixelstrap .link-section .menu-title.active .according-menu {
    font: bold 16px/34px monospace !important;
  }

  .pixelstrap .link-section .menu-title.active .according-menu:before {
    content: "-";
    position: absolute;
    right: -18px;
  }

  .pixelstrap .link-section .menu-title .according-menu {
    font: bold 16px/34px monospace !important;
  }

  .pixelstrap .link-section .menu-title .according-menu:before {
    content: "+";
    position: absolute;
    right: -18px;
  }

  .pixelstrap .link-section .menu-content a {
    text-transform: capitalize;
  }

  .pixelstrap .link-section ul {
    background-color: unset;
  }

  .pixelstrap .link-section h5 {
    font-weight: 700;
  }

  .pixelstrap .full-mega-menu .mega-box {
    border-right: none;
    margin-bottom: 0;
  }

  .pixelstrap .category-menu a img {
    width: 30% !important;
  }

  .pixelstrap .clothing-menu {
    background: none;
  }

  .pixelstrap li .lable-nav {
    left: 15px;
    top: -8px;
  }

  .pixelstrap li a {
    padding: 10px 15px;
    font-size: 16px;
  }

  .pixelstrap li a:hover {
    font-size: 16px;
    padding: 10px 15px;
  }

  .pixelstrap li ul li a {
    padding: 5px 35px;
    font-size: 14px;
    color: #222;
    font-weight: 400;
  }

  .pixelstrap li ul li a:hover {
    color: #309345;
    font-size: 14px;
    padding: 5px 35px;
  }

  .pixelstrap .category-menu a:hover img {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  .pixelstrap.sm-vertical a .sub-arrow {
    margin-top: -18px;
  }

  .pixelstrap .mega ul {
    max-width: 100%;
  }

  .pixelstrap ul li {
    border-top: none;
  }

  .sidenav {
    overflow-y: auto;
  }

  .sidenav .pixelstrap li ul li a {
    padding: 5px 45px;
  }

  .sidenav .pixelstrap li ul li a:hover,
  .sidenav .pixelstrap li ul li a.highlighted {
    padding: 5px 45px;
  }

  .sidenav .pixelstrap li a {
    padding: 10px 25px;
  }

  .sidenav .pixelstrap li a:hover {
    padding: 10px 25px;
  }

  .main-menu.border-section {
    border: none;
  }

  .main-menu .menu-left .main-menu-right .toggle-nav {
    position: absolute;
    z-index: 2;
    right: 145px;
    top: 6px;
  }

  .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    font-size: 18px;
    padding-left: 5px;
    text-transform: uppercase;
  }

  .main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 175px;
    top: -105px;
  }

  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    top: -1px;
  }

  .rtl .mega-box .link-section .demo ul li a span img {
    left: -70px;
  }

  .rtl .sm li {
    text-align: right;
  }

  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: 0;
  }

  .rtl .pixelstrap li .lable-nav {
    left: unset;
    right: 15px;
  }

  .rtl .pixelstrap li .lable-nav:before {
    left: unset;
    right: 5px;
  }
}

@media (max-width: 991px) {
  .top-header .header-contact {
    display: none;
  }
}

@media (min-width: 578px) {
  .main-menu .menu-right .icon-nav .mobile-search i,
  .main-menu .menu-right .icon-nav .mobile-cart i,
  .main-menu .menu-right .icon-nav .mobile-setting i,
  .main-menu .menu-right .icon-nav .mobile-setting-logged-in i,
  .main-menu .menu-right .icon-nav .mobile-cart-logged-in i {
    display: none;
  }

  .main-menu .menu-right .icon-nav .mobile-search .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-setting .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-setting-logged-in .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-cart-logged-in .close-circle i {
    display: block;
  }

  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    display: inline-block;
  }
}

@media (max-width: 577px) {
  .main-nav-center .toggle-nav {
    right: 15px;
    top: -85px;
  }

  .mobile-fix-option {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    background-color: #333;
    width: 100vw;
    z-index: 9;
    box-shadow: 0 2px 5px 0 #a8a8a8;
  }

  .header-5 .top-header .header-dropdown .mobile-wishlist img,
  .header-5 .top-header .header-dropdown .mobile-account img {
    -webkit-filter: brightness(5);
            filter: brightness(5);
  }

  .header-5 .main-menu .brand-logo {
    margin: 0;
    left: 0;
    -webkit-transform: unset;
            transform: unset;
  }

  .top-header .header-dropdown .mobile-account,
  .top-header .header-dropdown .mobile-wishlist {
    position: fixed;
    bottom: 20px;
    right: 27.5%;
    font-size: 0;
    padding: 0;
    z-index: 9;
  }

  .top-header .header-dropdown .mobile-account i,
  .top-header .header-dropdown .mobile-wishlist i {
    font-size: 22px;
    font-family: themify;
    color: #999;
  }

  .top-header .header-dropdown .mobile-account i:before,
  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e602";
  }

  .top-header .header-dropdown .mobile-account .onhover-show-div,
  .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    top: unset;
    bottom: 42px;
    right: 0;
  }

  .top-header .header-dropdown .mobile-account:hover i,
  .top-header .header-dropdown .mobile-wishlist:hover i {
    color: #999;
  }

  .top-header .header-dropdown .mobile-wishlist {
    right: 64%;
  }

  .top-header .header-dropdown .mobile-wishlist i {
    font-family: themify;
    color: #999 !important;
  }

  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e634";
  }

  .main-menu .menu-left.category-nav-right .navbar {
    padding-left: 0 !important;
  }

  .main-menu .menu-left .navbar {
    padding: 30px 45px 30px 0 !important;
  }

  .main-menu .menu-right .icon-nav .mobile-setting,
  .main-menu .menu-right .icon-nav .mobile-cart,
  .main-menu .menu-right .icon-nav .mobile-search,
  .main-menu .menu-right .icon-nav .mobile-setting-logged-in,
  .main-menu .menu-right .icon-nav .mobile-cart-logged-in {
    position: fixed;
    bottom: 20px;
    right: 16%;
    font-size: 0;
    padding: 0;
  }

  .main-menu .menu-right .icon-nav .mobile-setting .show-div,
  .main-menu .menu-right .icon-nav .mobile-cart .show-div,
  .main-menu .menu-right .icon-nav .mobile-search .show-div,
  .main-menu .menu-right .icon-nav .mobile-setting-logged-in .show-div,
  .main-menu .menu-right .icon-nav .mobile-cart-logged-in .show-div {
    top: -58px;
    left: 0;
  }

  .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-setting-logged-in .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-cart-logged-in .show-div.shopping-cart {
    top: unset;
    bottom: 42px;
    left: -118px !important;
  }

  .main-menu .menu-right .icon-nav .mobile-setting {
    right: 33.3333%;
  }

  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: inline-block;
    font-size: 22px;
    color: #999;
  }

  .main-menu .menu-right .icon-nav .mobile-setting>div img {
    display: none;
  }

  .main-menu .menu-right .icon-nav .mobile-setting .setting {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }

  .main-menu .menu-right .icon-nav .mobile-setting-logged-in {
    right: 47.5%;
  }

  .main-menu .menu-right .icon-nav .mobile-setting-logged-in i {
    display: inline-block;
    font-size: 22px;
    color: #999;
  }

  .main-menu .menu-right .icon-nav .mobile-setting-logged-in>div img {
    display: none;
  }

  .main-menu .menu-right .icon-nav .mobile-setting-logged-in .setting {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }

  .main-menu .menu-right .icon-nav .mobile-cart {
    right: 61.3333%;
  }

  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i {
    font-size: 14px;
  }

  .main-menu .menu-right .icon-nav .mobile-cart i {
    display: inline-block;
    font-size: 22px;
    color: #999;
  }

  .main-menu .menu-right .icon-nav .mobile-cart>div img {
    display: none;
  }

  .main-menu .menu-right .icon-nav .mobile-cart-logged-in {
    right: 67.5%;
  }

  .main-menu .menu-right .icon-nav .mobile-cart-logged-in .close-circle i {
    font-size: 14px;
  }

  .main-menu .menu-right .icon-nav .mobile-cart-logged-in i {
    display: inline-block;
    font-size: 22px;
    color: #999;
  }

  .main-menu .menu-right .icon-nav .mobile-cart-logged-in>div img {
    display: none;
  }

  .main-menu .menu-right .icon-nav .mobile-search {
    right: 85%;
    z-index: 1;
  }

  .main-menu .menu-right .icon-nav .mobile-search i {
    display: inline-block;
    font-size: 22px;
    color: #999;
  }

  .main-menu .menu-right .icon-nav .mobile-search img {
    display: none;
  }

  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-group {
    margin-bottom: 0;
  }

  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    font-size: 14px;
  }

  .brand-logo {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .toggle-nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rtl .main-nav-center .toggle-nav {
    right: unset;
    left: 15px;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-setting,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search,
  .rtl .main-menu .menu-right .icon-nav .mobile-setting-logged-in,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart-logged-in {
    right: unset;
    left: 16%;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-setting-logged-in .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart-logged-in .show-div {
    left: unset;
    right: 0;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-setting-logged-in .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart-logged-in .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-setting .setting {
    left: unset !important;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-cart {
    right: unset;
    left: 48%;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 80%;
  }

  .rtl .main-menu .menu-left .navbar {
    padding: 30px 0 30px 25px !important;
  }

  .rtl .top-header .header-dropdown .mobile-account,
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 32%;
  }

  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div,
  .rtl .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    right: 0;
  }

  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 64%;
  }
}

@media (max-width: 480px) {
  .brand-logo img {
    height: 50px;
    width: auto;
  }
}

@media (min-width: 1430px) {
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 1430px) {
  .banner-slider .height-banner {
    height: unset;
  }

  .pet-parallax .pet-decor {
    left: 60px;
    bottom: -125px;
  }

  .pet-parallax .pet-decor img {
    width: 240px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #ddd;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
    display: none;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #ddd;
    width: 60%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    width: 10%;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    width: 25%;
  }

  .cart-section .cart-buttons>div:last-child {
    padding-right: 38px;
  }

  .cart-section tbody tr td {
    min-width: 175px;
  }

  .cart-section tbody tr td .qty-box .input-group .form-control {
    width: 75px;
  }

  .cart-section tfoot tr td {
    padding-right: 41px;
  }

  .product-right .product-icon .product-social li {
    padding-right: 10px;
  }

  .product-form-box .timer {
    padding-left: 17px;
  }

  .product-form-box .timer span {
    width: 55px;
  }

  .rtl .cart-section .cart-buttons>div:last-child,
  .rtl .wishlist-section .cart-buttons>div:last-child {
    padding-left: 41px;
  }

  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    border-left: none;
    border-right: 1px solid #ddd;
  }

  .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
    border-left: 1px solid #ddd;
    border-right: none;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    border-right: 1px solid #ddd;
    padding-right: 20px;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
    text-align: center;
  }

  .rtl .product-right .product-icon .product-social li {
    padding-left: 10px;
  }

  .rtl header.left-header .sidenav {
    right: -300px;
    left: unset;
  }

  .rtl header.left-header .sidenav.open-side {
    right: 0;
    left: unset;
  }

  .rtl header.left-header .top-header {
    padding-right: 0;
  }

  .rtl .left-sidebar_space {
    padding-right: 0;
    padding-left: 0;
  }

  .dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    border-color: #404040;
  }

  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-color: #404040;
  }
}

@media (max-width: 1430px) and (min-width: 1368px) {
  .banner-timer .timer {
    margin-top: 45px;
  }
}

@media (max-width: 1430px) and (min-width: 1200px) {
  .layout3-menu {
    max-width: 100%;
  }

  .layout3-menu .main-menu .menu-left .navbar {
    padding: 40px 10px 40px 0;
  }

  .layout3-menu .pixelstrap>a {
    padding-right: 38px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap a i {
    padding: 5px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap i {
    font-size: 14px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap button i {
    padding: 5px;
  }

  .rtl .layout3-menu .main-menu .menu-left .navbar {
    padding: 40px 0 40px 35px;
  }
}

@media (max-width: 1367px) {
  h2 {
    font-size: 32px;
  }

  .title1 .title-inner1 {
    padding-bottom: 10px;
  }

  .title1 .title-inner1:after {
    height: 2px;
  }

  .title3 .line {
    height: 3px;
  }

  .title3 .line:after,
  .title3 .line:before {
    height: 3px;
  }

  .title3 .title-inner3 {
    margin-bottom: 10px;
  }

  .title4 .title-inner4 {
    padding-bottom: 15px;
  }

  hr.style1 {
    height: 1px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  .product-box .product-detail .rating i,
  .product-box .product-info .rating i,
  .product-wrap .product-detail .rating i,
  .product-wrap .product-info .rating i {
    padding-right: 3px;
  }

  .blog-details h4 {
    margin-top: 20px;
  }

  .blog-details p {
    font-size: 16px;
  }

  .service-block h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .banner-timer {
    background-position: right;
  }

  .banner-timer .banner-text h2 {
    font-size: 28px;
  }

  .collection-product-wrapper .product-wrapper-grid .product-five {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .subscribe-form .form-control {
    width: 215px;
  }

  .category-bg .image-block.even:after {
    bottom: 91%;
  }

  .category-bg .contain-block h2 {
    font-size: 30px;
  }

  .category-bg .contain-block.even:after {
    top: 91%;
  }

  .category-bg .contain-block .category-btn {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .category-bg .contain-block:after {
    bottom: 91%;
  }

  .full-banner .banner-contain h2 {
    font-size: 90px;
  }

  .full-banner .banner-contain h3 {
    font-size: 55px;
  }

  .full-banner .banner-contain h4 {
    font-size: 24px;
    padding-top: 8px;
  }

  .full-banner .banner-contain .color {
    padding-top: 0;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 30px;
  }

  .collection-banner .contain-banner.banner-4 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 28px;
  }

  .collection-banner .contain-banner h2 {
    font-size: 55px;
    letter-spacing: 0.01em;
  }

  .collection-banner .contain-banner h4 {
    letter-spacing: 0.01em;
  }

  .footer-theme2 .contact-details li {
    padding: 0 40px;
  }

  .footer-theme2 .footer-mobile-title {
    display: none !important;
  }

  .footer-theme .sub-title h4 {
    font-size: 16px;
  }

  .footer-theme .sub-title .contact-list li {
    line-height: 20px;
  }

  .footer-theme .sub-title .contact-list i {
    top: 17px;
  }

  .theme-card .offer-slider .media .media-body h4 {
    margin-top: 10px;
  }

  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 50px;
  }

  .full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
    margin-right: 0;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 160px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    margin-top: 15px;
  }

  .blog-page .blog-media .blog-right h4 {
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 5px;
  }

  .blog-page .blog-media .blog-right ul {
    margin-bottom: 10px;
  }

  .blog-page .blog-media .blog-right p {
    line-height: 1.4;
  }

  .pro_sticky_info {
    padding: 10px;
  }

  .is_stuck {
    margin-top: 30px;
  }
}

@media (max-width: 1199px) {
  .top-header .header-contact {
    display: none;
  }

  .form_search {
    display: none;
  }

  .pet-parallax .pet-decor {
    left: 45px;
    bottom: -105px;
  }

  .pet-parallax .pet-decor img {
    width: 200px;
  }

  header.header-6 .mobile-search {
    display: inline-block;
  }

  header.header-6.sticky.fixed .toggle-nav {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .sticky.fixed .pixelstrap>li>a,
  .sticky.fixed .pixelstrap>li>a:hover,
  .sticky.fixed .pixelstrap>li>a:active {
    padding: 10px 15px;
  }

  .sticky.fixed .sidenav .pixelstrap>li>a,
  .sticky.fixed .sidenav .pixelstrap>li>a:hover,
  .sticky.fixed .sidenav .pixelstrap>li>a:active {
    padding: 9px 23px;
  }

  .footer-light .subscribe p {
    line-height: 1.2;
  }

  .footer-social,
  .social-white {
    margin-top: 25px;
  }

  .timer span {
    width: 60px;
  }

  .collection-filter-block .product-service .media svg {
    height: 30px;
    width: 30px;
  }

  .section-t-space {
    padding-top: 60px;
  }

  .section-b-space {
    padding-bottom: 60px;
  }

  .partition1 {
    margin-bottom: -25px;
  }

  .partition1>div {
    margin-bottom: 25px;
  }

  .title1 h4 {
    padding-bottom: 8px;
  }

  .title1 .title-inner1 {
    padding-bottom: 12px;
    margin-bottom: 25px;
  }

  .title2 h4 {
    padding-bottom: 8px;
  }

  .title2 .title-inner2 {
    margin-bottom: 25px;
  }

  .title3 h4 {
    padding-bottom: 8px;
  }

  .title3 .title-inner3 {
    margin-bottom: 10px;
  }

  .title3 .line {
    margin-bottom: 25px;
  }

  .title4 .title-inner4 {
    padding-bottom: 15px;
  }

  .title4 .line {
    margin-bottom: 25px;
  }

  .title-borderless {
    margin-bottom: 25px;
  }

  .about-text p {
    margin-bottom: 25px;
  }

  .banner-timer .timer {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    bottom: 8px;
    margin-top: 38px;
  }

  .footer-social li {
    padding-right: 38px;
  }

  .footer-theme2 p {
    line-height: 25px;
  }

  .footer-theme2 .subscribe-block {
    padding: 10px 70px;
  }

  .footer-theme2 .footer-mobile-title {
    display: none !important;
  }

  .footer-theme2 .contact-details li {
    line-height: 25px;
    padding: 0 10px;
  }

  .footer-theme2 .footer-link li {
    padding-right: 25px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 50px;
  }

  .social-white li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .service-block svg,
  .service-block1 svg {
    width: 50px;
    height: 50px;
  }

  .service-block1 svg {
    margin-bottom: 15px;
  }

  .category-border div .category-banner .category-box h2 {
    padding: 13px 27px;
  }

  .category-bg .image-block.even:after {
    bottom: 87%;
  }

  .category-bg .contain-block.even:after {
    top: 87%;
  }

  .category-bg .contain-block:after {
    bottom: 87%;
  }

  .no-slider .product-box {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 30px);
    margin: 0 15px 30px;
  }

  .no-slider .product-box:nth-last-child(-n+4) {
    margin: 0 15px 30px;
  }

  .no-slider .product-box:nth-last-child(-n+3) {
    margin: 0 15px 0;
  }

  .theme-tab .tab-title2 {
    font-size: 22px;
  }

  .theme-tab .tab-title2:after {
    top: 17px;
  }

  .theme-tab .tab-content .product-tab .tab-box {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }

  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+2) {
    margin: 0 5px 0;
  }

  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+4) {
    margin: 0 5px 10px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 280px;
  }

  .full-banner .banner-contain h2 {
    font-size: 85px;
  }

  .full-banner .banner-contain h3 {
    font-size: 50px;
  }

  .full-banner .banner-contain h4 {
    font-size: 22px;
    padding-top: 5px;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 25px;
  }

  .collection-banner .contain-banner.banner-3 h4 {
    font-size: 12px;
  }

  .collection-banner .contain-banner.banner-4 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 24px;
  }

  .collection-banner .contain-banner.banner-4 h4 {
    font-size: 16px;
  }

  .collection-banner .contain-banner h2 {
    font-size: 40px;
  }

  .home-slider .home {
    height: 70vh;
  }

  .home-slider .slider-contain {
    height: 70vh;
  }

  .home-slider .slider-contain h1 {
    font-size: 50px;
  }

  .background .contain-bg {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 20px;
  }

  .theme-card .offer-slider .media .media-body .rating i {
    padding-right: 3px;
  }

  .theme-card.card-border .offer-slider {
    padding-top: 0;
    padding-bottom: 0;
  }

  .full-box .theme-card .offer-slider img {
    padding: 15px 0 15px 0;
  }

  .full-box .theme-card .offer-slider .product-box2 .media img {
    height: 250px;
  }

  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
    margin-top: 5px;
  }

  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-top: 5px;
  }

  .home-slider.fullpage .home .slider-contain h1 {
    font-size: 35px;
  }

  .home-slider.fullpage .home .slider-contain p {
    max-width: 450px;
    font-size: 14px;
  }

  .home-slider.fullpage .home .slider-contain .btn-solid {
    padding: 10px 15px;
  }

  .about-section h2 {
    font-size: 22px;
  }

  .about-section .about-text p {
    line-height: 24px;
  }

  .about-section .service .service-block1 svg {
    margin-bottom: 5px;
  }

  .about-section .service .service-block1 h5 {
    line-height: 18px;
  }

  .about-section .small-section {
    padding-top: 20px;
  }

  .layout7-product .product-box .details-product {
    background-color: rgba(255,255,255,0.5);
    padding: 5px;
  }

  .padding-bottom-cls {
    padding-bottom: 30px;
  }

  .layout-8 {
    padding: 0 40px;
  }

  .blog-detail-page .comment-section li {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #ddd;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
    display: none;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 30%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%;
  }

  .testimonial .testimonial-slider .media .media-body {
    padding: 30px;
  }

  .testimonial .testimonial-slider .media .media-body p {
    line-height: 1.5;
  }

  .search-product>div:nth-last-child(1),
  .search-product>div:nth-last-child(2),
  .search-product>div:nth-last-child(3) {
    margin-top: 30px;
  }

  .cart-section tbody tr td {
    min-width: 140px;
  }

  .cart-section tbody tr td .qty-box .input-group .form-control {
    width: 52px;
  }

  .cart-section tfoot tr td {
    padding-right: 25px;
  }

  .blog-page .blog-media {
    margin-bottom: 20px;
  }

  .blog-page .blog-media .blog-right {
    display: block;
    margin-top: 15px;
  }

  .blog-page .blog-media .blog-right p {
    line-height: 1.3;
  }

  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
    margin-top: 15px;
  }

  .collection-filter-block .product-service .media .media-body h4 {
    font-size: 16px;
  }

  .product-right h2 {
    font-size: 20px;
  }

  .tab-product .tab-content.nav-material .single-product-tables table {
    width: 50%;
  }

  .product-description-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .product-description-box .product-icon .product-social li {
    padding-right: 4px;
  }

  .product-description-box .product-icon .wishlist-btn span {
    padding-left: 4px;
  }

  .product-form-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .product-form-box .timer {
    padding-left: 17px;
  }

  .product-form-box .timer span {
    width: 55px;
  }

  .product-form-box .product-buttons .btn-solid,
  .product-form-box .product-buttons .btn-outline {
    padding: 7px 13px;
  }

  .product-accordion .single-product-tables table {
    width: 70%;
  }

  .tab-product .flex-column {
    flex-direction: unset !important;
    justify-content: center;
  }

  .tab-product .nav-material.nav-tabs {
    border-bottom: 1px solid #ddd;
    border-right: 0;
  }

  .tab-product .nav-material.nav-tabs .nav-item .nav-link.active {
    background-color: unset;
  }

  .tab-border {
    padding-top: 0;
  }

  .rtl header .main-navbar .nav-menu>li a {
    text-align: right;
  }

  .rtl header .main-navbar .nav-menu>li a .sub-arrow {
    left: 0;
    right: auto;
  }

  .rtl header .main-navbar .nav-menu>li .nav-submenu li a .sub-arrow {
    right: auto;
    left: 0;
  }

  .rtl header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow:before {
    right: auto;
    left: 0;
  }

  .rtl header .sidenav .sidebar-menu li a {
    text-align: right;
  }

  .rtl header .sidenav .sidebar-menu li a .sub-arrow {
    left: 0;
    right: auto;
  }

  .rtl header.video-header #main-nav {
    right: unset;
    left: 0;
  }

  .rtl header.video-header .main-menu .menu-right .icon-nav {
    padding-left: 40px;
    padding-right: 0;
  }

  .rtl .cart-section .cart-buttons>div:last-child,
  .rtl .wishlist-section .cart-buttons>div:last-child {
    padding-left: 17px;
  }

  .rtl .tab-border .nav-border {
    border-left: none;
  }

  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    padding-right: 20px !important;
  }

  .rtl .footer-social li {
    padding-right: 0;
    padding-left: 38px;
  }

  .rtl .footer-theme2 .social-white li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .rtl .tab-left .theme-tab .left-side .tab-title {
    text-align: center;
    margin-left: 0;
  }

  .dark .tab-product .nav-material.nav-tabs {
    background-color: #2b2b2b;
  }

  .dark header {
    background-color: #2b2b2b;
  }

  .dark header .main-navbar {
    background-color: #2b2b2b;
    border-color: #404040;
  }

  .dark header .main-navbar .nav-menu {
    background-color: #2b2b2b;
  }

  .dark header .main-navbar .nav-menu>li ul {
    background-color: #2b2b2b;
    box-shadow: none;
  }

  .dark header .main-navbar .nav-menu>li ul li a {
    color: #e7eaec;
  }

  .dark header .main-navbar .nav-menu>li .mega-menu-container {
    background-color: #2b2b2b;
    box-shadow: none;
  }

  .dark header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a {
    color: #e7eaec;
  }

  .dark header .main-navbar .nav-menu .back-btn .mobile-back {
    border-color: #404040;
    color: #e7eaec;
  }

  .dark header.left-header .pixelstrap ul {
    background-color: #232323;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  .partition-f>div+div+div {
    margin-top: 30px;
  }

  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 80px;
  }

  .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    line-height: 15px;
  }
}

@media (max-width: 991px) {
  .pet-parallax .pet-decor {
    left: 34px;
    bottom: -86px;
  }

  .pet-parallax .pet-decor img {
    width: 165px;
  }

  .pet-parallax .banner-contain p {
    max-width: 100%;
  }

  .m-sm-t-2 {
    margin-top: 2rem;
  }

  .service-block p {
    line-height: 1.2;
  }

  h2 {
    font-size: 28px;
  }

  .section-t-space {
    padding-top: 50px;
  }

  .section-b-space {
    padding-bottom: 50px;
  }

  .small-section {
    padding: 30px 0;
  }

  .product-full-tab {
    padding-top: 50px !important;
  }

  .product-slick .slick-slide img,
  .rtl-product-slick .slick-slide img,
  .product-right-slick .slick-slide img,
  .rtl-product-right-slick .slick-slide img {
    width: 100% !important;
  }

  hr.style1 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 16px;
  }

  .blog-details h4 {
    margin-top: 17px;
    font-size: 13px;
  }

  .blog-details p {
    font-size: 15px;
    line-height: 1.3;
    margin-top: 10px;
  }

  .service-block svg,
  .service-block1 svg {
    margin-bottom: 20px;
    height: auto;
    width: 46px;
  }

  .partition_3>div+div {
    margin-top: 30px;
  }

  .partition4>div+div+div {
    margin-top: 30px;
  }

  .banner-timer .banner-text h2 {
    font-size: 20px;
  }

  .banner-timer .timer span {
    width: 65px;
  }

  .banner-timer .timer p {
    font-size: 14px;
  }

  .about-text p {
    line-height: 24px;
  }

  .footer-light .subscribe {
    text-align: center;
    justify-content: center;
    border: none;
    margin-bottom: 5px;
  }

  .footer-light .subscribe p {
    display: none;
  }

  .footer-light .subscribe-form {
    justify-content: center;
    margin-bottom: 0;
  }

  .footer-theme .col {
    max-width: 50%;
    flex-basis: unset;
  }

  .footer-theme .footer-logo {
    margin-bottom: 30px;
  }

  .footer-theme .sub-title h4 {
    margin-bottom: 10px;
  }

  .footer-social {
    margin-top: 15px;
  }

  .subscribe-wrapper {
    order: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-theme2 .footer-mobile-title {
    display: none !important;
  }

  .footer-theme2 h4 {
    padding-bottom: 10px;
  }

  .footer-theme2 .col {
    flex-basis: unset;
    padding: 20px 0;
  }

  .footer-theme2.section-light .footer-block h4 {
    padding-bottom: 15px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    border: none;
  }

  .footer-theme2 .footer-logo {
    margin-bottom: 20px;
  }

  .footer-theme2 .subscribe-block {
    border: none;
  }

  .social-white li {
    padding-left: 10px;
    padding-right: 10px;
  }

  .category-border div .category-banner .category-box h2 {
    padding: 5px 15px;
    font-size: 30px;
  }

  .category-bg .image-block.even:after {
    bottom: 85%;
  }

  .category-bg .image-block:after {
    width: 35px;
  }

  .category-bg .image-block:hover:after {
    height: 70px;
  }

  .category-bg .contain-block h2 {
    font-size: 20px;
    margin-top: 8px;
  }

  .category-bg .contain-block:after {
    width: 35px;
  }

  .category-bg .contain-block.even:after {
    top: 85%;
  }

  .category-bg .contain-block .category-btn {
    margin-bottom: 15px;
    margin-top: 15px;
    letter-spacing: 0.2em;
    padding: 10px 15px;
  }

  .category-bg .contain-block:after {
    bottom: 85%;
  }

  .category-bg .contain-block:hover:after {
    height: 70px;
  }

  .no-slider .product-box {
    flex: 0 0 50%;
    max-width: calc(50% - 30px);
    margin: 0 15px 30px !important;
  }

  .no-slider .product-box:nth-last-child(-n+2) {
    margin: 0 15px 0 !important;
  }

  .full-banner {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .full-banner.parallax-layout {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .full-banner .banner-contain h2 {
    font-size: 60px;
  }

  .full-banner .banner-contain h3 {
    font-size: 35px;
  }

  .full-banner .banner-contain h4 {
    font-size: 20px;
    padding-top: 5px;
  }

  .collection-banner .contain-banner {
    padding-left: 60px;
    padding-right: 60px;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 20px;
  }

  .collection-banner .contain-banner.banner-4 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 18px;
  }

  .collection-banner .contain-banner.banner-4 h4 {
    font-size: 18px;
  }

  .collection-banner .contain-banner h2 {
    font-size: 25px;
  }

  .collection-collapse-block {
    border-bottom: 1px solid #ddd !important;
  }

  .home-slider .home {
    height: 65vh;
  }

  .home-slider .slider-contain {
    height: 65vh;
  }

  .home-slider .slider-contain h1 {
    font-size: 36px;
  }

  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline {
    margin-top: 17px;
  }

  .home-slider:hover .slick-next {
    right: 90px;
  }

  .home-slider:hover .slick-prev {
    left: 90px;
  }

  .background .contain-bg {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .background .contain-bg h4 {
    font-size: 16px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 210px;
  }

  .beauty-about .about-text p {
    text-align: center;
  }

  .theme-card .offer-slider img {
    padding: 10px 10px 10px 0;
  }

  .theme-card.card-border .offer-slider img {
    padding: 10px 10px 10px 30px;
  }

  .multiple-slider>div:nth-child(-n+2) {
    margin-bottom: 30px;
  }

  .full-box .center-slider .offer-slider .product-box .product-info h4 {
    padding-bottom: 5px;
  }

  .full-box .center-slider .offer-slider .product-box .product-info .btn-outline {
    padding: 7px 20px;
  }

  .full-box .theme-card .offer-slider .product-box2 .media img {
    height: 150px;
  }

  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-top: 0;
  }

  .layout-8 .layout-8-bg {
    padding: 0;
  }

  .alert {
    max-width: 45% !important;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
    margin-bottom: 20px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    margin-bottom: 18px;
  }

  .parallax-cls .banner-contain {
    background-color: rgba(255,255,255,0.5);
    padding: 35px;
  }

  .parallax-cls section .banner-contain {
    margin-top: 0;
  }

  .about-section {
    padding-top: 30px;
  }

  .about-section h2 {
    text-align: center;
  }

  .product-box .cart-info {
    bottom: 20px;
  }

  .absolute-banner {
    margin-top: -60px;
  }

  .product-right.product-form-box {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .testimonial .testimonial-slider .media .media-body {
    padding: 0 30px;
    height: 100%;
    align-self: center;
  }

  .testimonial .testimonial-slider .media .media-body p {
    margin-bottom: 0;
  }

  .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-left: none;
    padding-left: 0;
  }

  .blog-detail-page .blog-advance ul {
    margin-bottom: 20px;
  }

  .blog-detail-page .blog-advance p:last-child {
    margin-bottom: 0;
  }

  .collection-product-wrapper .product-top-filter {
    border-top: none;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    border-top: 1px solid #ddd;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    display: none;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    display: none;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 50%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
    right: 15px !important;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    border-left: 1px solid #ddd;
    padding: 21px 34px 21px 34px;
    border-top: none;
    border-bottom: none;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
    border-right: none !important;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    border-top: 1px solid #ddd;
  }

  .top-banner-content h4 {
    font-size: 20px;
  }

  .filter-main-btn {
    display: block;
  }

  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -370px;
    background-color: white;
    z-index: 99;
    overflow-y: scroll;
    padding: 30px 0 30px 15px;
    max-width: 300px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px #ddd;
  }

  .collection-filter .collection-sidebar-banner {
    text-align: center;
  }

  .collection-filter .theme-card {
    padding-left: 30px;
    padding-right: 30px;
  }

  .collection-filter .collection-sidebar-banner {
    padding: 0 30px;
  }

  .collection-filter-block {
    border: none;
  }

  .collection-mobile-back {
    display: block;
  }

  .collection .section-t-space {
    padding-top: 30px;
  }

  .collection .partition-collection>div:nth-last-child(1) {
    margin-top: 30px;
  }

  .collection .partition-collection>div:nth-last-child(2) {
    margin-top: 30px;
  }

  .right-login {
    margin-top: 30px;
  }

  .contact-page .map iframe {
    height: 350px;
  }

  .contact-page .contact-right {
    padding-bottom: 0;
  }

  .contact-page .contact-right ul li {
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    text-align: center;
    padding-bottom: 10px;
    margin-top: 15px;
  }

  .contact-page .contact-right ul li .contact-icon {
    position: relative;
    margin: 0 auto;
    border-right: 0;
  }

  .contact-page .contact-right ul li p {
    margin-bottom: 10px;
  }

  .cart-section .cart-table thead th:last-child {
    display: none;
  }

  .cart-section tbody tr td {
    min-width: 135px;
  }

  .cart-section tbody tr td:last-child {
    display: none;
  }

  .cart-section .cart-buttons>div:last-child {
    padding-right: 15px;
  }

  .cart-section tfoot tr td {
    padding-right: 0;
  }

  .wishlist-section tbody tr td {
    min-width: 138px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 55%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 20px;
    background: none #309345;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .open-popup {
    text-align: left;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup>a {
    color: #fff;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
    height: 100vh;
    left: -350px;
    transition: all 0.5s ease;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .collection-view,
  .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    display: none;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 50%;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    top: 13px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    left: unset;
    right: 35px;
  }

  .tab-product {
    padding-top: 0;
  }

  .product-right h2 {
    margin-top: 15px;
  }

  .pro_sticky_info {
    border: none;
    padding: 10px 0;
  }

  .product-right {
    text-align: center;
    margin: 20px 0 10px 0;
  }

  .product-right .detail-section,
  .product-right .product-icon {
    justify-content: center;
  }

  .product-right .product-description .qty-box {
    justify-content: center;
  }

  .product-right .size-text {
    text-align: left;
  }

  .product-right .timer {
    text-align: left;
  }

  .product-right .product-icon .product-social li {
    padding-right: 20px;
  }

  .product-form-box {
    margin-bottom: 10px;
  }

  .tab-product .nav-material.nav-tabs {
    justify-content: center;
  }

  .dashboard-left {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 #ddd;
  }

  .dashboard-left .block-title h2 {
    display: none;
  }

  .dashboard-left .block-content {
    border: none;
    padding: 0;
    margin-top: 20px;
  }

  .dashboard-left .block-content ul li:hover {
    padding-left: 0;
  }

  .account-sidebar {
    display: block;
  }

  .rtl .tools-parallax-product .tools-description .tools-form .search-button {
    text-align: center;
  }

  .rtl .tools-service .service-block+.service-block {
    border-right: none;
  }

  .rtl .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-right: none;
    padding-right: 0;
  }

  .rtl .contact-page .contact-right ul li {
    padding-right: 0;
  }

  .rtl .contact-page .contact-right ul li .contact-icon {
    border-left: none;
  }

  .rtl .contact-page .contact-right ul li p {
    text-align: center;
  }

  .rtl .rtl-text .product-right .color-variant {
    text-align: center;
  }

  .rtl .rtl-text .product-right .size-box {
    text-align: center;
  }

  .rtl .rtl-text .product-right .product-buttons {
    text-align: center;
  }

  .rtl .rtl-text .product-right .border-product {
    text-align: center;
  }

  .rtl .product-accordion .card-header h5 {
    text-align: center;
  }

  .rtl .image-swatch {
    text-align: center;
  }

  .rtl .product-right h4,
  .rtl .product-right h2,
  .rtl .product-right h3,
  .rtl .product-right p,
  .rtl .product-right .product-title {
    text-align: center;
  }

  .rtl .product-right .detail-section,
  .rtl .product-right .product-icon {
    justify-content: center;
  }

  .rtl .product-right .size-text {
    text-align: right;
  }

  .rtl .product-right .product-description .qty-box {
    justify-content: center;
  }

  .rtl .product-right .payment-card-bottom {
    text-align: center;
  }

  .rtl .product-right .rating {
    text-align: center;
  }

  .rtl .single-product-tables table tr td {
    text-align: center;
  }

  .rtl .tab-product {
    padding-top: 30px;
  }

  .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
    border-left: none;
  }

  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    padding-right: 20px !important;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    padding: 21px 34px 21px 34px;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-right: 1px solid #ddd;
  }

  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-right: none;
  }

  .rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count h5 {
    text-align: center;
  }

  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    padding: 10px 34px 10px 34px;
  }

  .rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    background: none #309345;
  }

  .rtl .banner-timer .timer {
    padding-left: 10px;
  }

  .rtl .banner-timer .timer span {
    width: 55px;
  }

  .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-left: 5px;
    margin-right: unset;
  }

  .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .rating i {
    padding: 0;
  }

  .rtl .beauty-about .text-center {
    text-align: center !important;
  }

  .rtl .beauty-about .about-text p {
    text-align: center;
  }

  .rtl .footer-light .subscribe {
    border-left: none;
  }

  .dark .collection-filter {
    background-color: #2b2b2b;
  }

  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    border-color: #404040;
  }

  .dark .collection-mobile-back {
    border-color: #404040;
  }

  .dark .collection-mobile-back span {
    color: #929292;
  }

  .dark .collection-collapse-block {
    border-color: #404040 !important;
  }

  .dark .collection-filter {
    box-shadow: none;
  }

  .dark .contact-page .contact-right ul li {
    border-color: #404040;
  }

  .dark .dashboard-left {
    background-color: #232323;
    box-shadow: none;
  }

  .dark header.header-tools .logo-menu-part {
    background-color: #2b2b2b;
  }
}

@media (max-width: 767px) {
  .pet-parallax .pet-decor {
    display: none;
  }

  .portfolio-section.metro-section .product-box .cart-wrap a i {
    opacity: 1;
    font-size: 16px;
    padding: 5px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap button i {
    font-size: 16px;
    padding: 5px;
  }

  .portfolio-section.metro-section .product-box .product-detail {
    opacity: 1;
    bottom: 15px;
    background-color: rgba(255,255,255,0.5);
  }

  .portfolio-section.metro-section .product-box .product-detail h6 {
    color: #000;
  }

  .portfolio-section.metro-section .product-box:hover .product-detail {
    opacity: 1;
  }

  .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i,
  .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i,
  .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
    -webkit-animation: none;
            animation: none;
  }

  .pet-parallax .full-banner {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pet-layout-footer .footer-theme2 .footer-link .social-white {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .pet-layout-footer .footer-theme2 .footer-link .social-white li {
    display: unset;
  }

  .j-box .product-box .cart-info {
    bottom: 0;
    position: relative;
    opacity: 1;
  }

  .j-box .product-box .cart-info a i {
    padding: 10px;
  }

  .pets-box .product-box .img-wrapper .cart-info {
    -webkit-transform: none;
            transform: none;
  }

  .pets-box .product-box .img-wrapper .cart-info i {
    font-size: 16px;
  }

  .addtocart_count .product-box:hover .cart-info a:nth-child(1) i,
  .addtocart_count .product-box:hover .cart-info a:nth-child(2) i,
  .addtocart_count .product-box:hover .cart-info a:nth-child(3) i {
    -webkit-animation: none;
            animation: none;
  }

  .breadcrumb-section .page-title,
  .breadcrumb-section .breadcrumb {
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: -4px;
  }

  .layout3-menu {
    max-width: 100%;
  }

  .layout3-menu .main-menu .brand-logo img {
    height: 25px;
  }

  .section-t-space {
    padding-top: 40px;
  }

  .section-b-space {
    padding-bottom: 40px;
  }

  .product-full-tab {
    padding-top: 30px !important;
  }

  .small-section {
    padding: 20px 0;
  }

  .theme-tab .tab-title a,
  .theme-tab .tab-title2 a {
    text-transform: capitalize;
  }

  .about-cls .service.border-section {
    border-bottom: none;
  }

  .about-cls .service.small-section {
    padding-bottom: 0;
  }

  .service-block .media {
    display: block;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .service-block svg {
    margin-right: 0;
  }

  .service-block+.service-block {
    border-left: 0;
    margin-top: 20px;
  }

  .service-block:last-child .media .media-body p {
    margin-bottom: 5px;
  }

  .partition2 {
    margin-bottom: -25px;
  }

  .partition2>div {
    margin-bottom: 25px;
  }

  .partition3>div+div {
    margin-top: 30px;
  }

  .partition4>div+div {
    margin-top: 30px;
  }

  .center-slider {
    border-left: none;
    border-right: none;
  }

  .about-text p {
    line-height: 25px;
  }

  .banner-timer {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .banner-timer .banner-text {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: rgba(255,255,255,0.65);
  }

  .banner-timer .banner-text h2 {
    font-size: 15px;
  }

  .banner-timer .timer-box {
    text-align: center;
  }

  .banner-timer .timer {
    margin-top: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
  }

  .banner-timer .timer p {
    font-size: 14px;
  }

  .banner-timer .timer span {
    width: 40px;
  }

  .banner-timer .timer span .padding-l {
    padding-left: 10px;
  }

  .darken-layout .footer-title,
  .dark-layout .footer-title {
    border-bottom: 1px solid #525252;
  }

  .darken-layout .subscribe-wrapper,
  .dark-layout .subscribe-wrapper {
    padding-bottom: 10px;
  }

  .footer-title {
    margin-top: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    position: relative;
  }

  .footer-title.footer-mobile-title {
    margin-top: 0;
  }

  .footer-title h4 {
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
  }

  .footer-title.active .according-menu {
    font: normal normal normal 14px/1 FontAwesome;
  }

  .footer-title.active .according-menu:before {
    content: "\f106";
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .footer-title .according-menu {
    font: normal normal normal 14px/1 FontAwesome;
  }

  .footer-title .according-menu:before {
    content: "\f107";
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .banner-slider .home-banner>div img {
    margin-top: 30px;
  }

  .banner-slider .home-banner>div:last-child img {
    margin-top: 30px;
  }

  .lookbook .row>div:last-child .lookbook-block {
    margin-top: 30px;
  }

  .lookbook-section .row>div:first-child .lookbook-img>div:last-child {
    margin-bottom: 25px;
  }

  .lookbook-section .lookbook-img>div:last-child img {
    margin-top: 0;
  }

  .full-scroll-footer .sub-footer p {
    padding: 0;
    line-height: 25px;
  }

  .box-layout-body .box-layout {
    margin-top: -40px;
  }

  .layout-20 {
    padding: 15px;
  }

  .home-slider.fullpage .slick-dots {
    display: flex;
    bottom: 70px;
    top: unset;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .home-slider.fullpage .slick-dots li button:before {
    color: #0a0100;
  }

  .footer-contant {
    text-align: left;
  }

  .footer-contant .footer-logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-contant li {
    text-align: left;
  }

  .footer-contant .footer-social ul {
    margin: unset;
  }

  .footer-contant .footer-social ul li {
    padding-right: 25px;
    padding-left: 0;
  }

  .social-white li:first-child {
    padding-left: 0;
  }

  .footer-theme {
    text-align: center;
  }

  .footer-theme .footer-mobile-title {
    display: block !important;
  }

  .footer-theme .col {
    max-width: 100%;
    flex-basis: unset;
  }

  .footer-theme .footer-social {
    margin-bottom: 15px;
  }

  .footer-theme .sub-title li {
    padding-top: 7px;
  }

  .footer-theme .sub-title .contact-list i {
    position: relative;
    top: 0;
    margin-right: 10px;
  }

  .footer-theme .sub-title .contact-list li {
    padding-left: 0;
  }

  .footer-social ul {
    margin: 0 auto;
  }

  .footer-social li {
    padding: 0 15px;
  }

  .footer-end {
    text-align: center;
  }

  .sub-footer .payment-card-bottom {
    justify-content: center;
    padding-bottom: 25px;
  }

  .subscribe-wrapper {
    margin: 10px 0 0 0;
    order: 0;
  }

  .footer-theme2 p {
    margin-bottom: 10px;
  }

  .footer-theme2 .footer-mobile-title {
    display: block !important;
  }

  .footer-theme2 .p-set {
    padding: 0 15px !important;
  }

  .footer-theme2 .col {
    padding: 0;
  }

  .footer-theme2 .footer-link li {
    display: flex;
    padding-top: 10px;
  }

  .footer-theme2 .footer-link h4 {
    padding-bottom: 10px;
  }

  .footer-theme2 .contact-details li {
    padding-left: 0;
    padding-top: 10px;
  }

  .footer-theme2 .footer-link-b li {
    display: flex;
    padding-top: 10px;
  }

  .footer-theme2 .footer-link-b h4 {
    margin-top: 0;
    padding-bottom: 10px;
  }

  .footer-theme2.section-light .footer-block h4 {
    padding-bottom: 10px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 40px 65px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white h2 {
    margin-bottom: 20px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white .form-group {
    display: flex;
  }

  .footer-theme2.section-light .footer-block .subscribe-white .form-group .btn-solid,
  .footer-theme2.section-light .footer-block .subscribe-white .form-group .btn-outline {
    margin-top: 0;
  }

  .footer-theme2 .subscribe-block h2 {
    margin-bottom: 20px;
  }

  .footer-theme2 h4 {
    padding-bottom: 10px;
  }

  .darken-layout .footer-theme .footer-mobile-title h4 {
    color: #fff;
  }

  .dark-layout .small-section {
    padding-bottom: 40px;
  }

  .dark-layout section {
    padding-top: 0;
  }

  .dark-layout .section-b-space {
    border: none;
    padding-bottom: 0;
  }

  .dark-layout .subscribe-wrapper {
    order: -1;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block {
    padding: 10px 60px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block h2 {
    display: none;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group {
    display: flex;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .btn-solid,
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .btn-outline {
    margin-top: 0;
  }

  .social-white {
    margin-bottom: 10px;
  }

  .category-border div .category-banner .category-box h2 {
    font-size: 25px;
  }

  .box-layout .small-section {
    padding-top: 0;
  }

  .white-layout .footer-theme2>div:nth-child(2) {
    order: -1;
  }

  .white-layout .footer-theme2.footer-border {
    padding: 0;
    border: none;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 540px;
    width: 100%;
  }

  .white-layout .footer-theme2 .footer-block .footer-container .footer-mobile-title {
    margin-top: 20px;
  }

  .white-layout .footer-theme2 .footer-block .subscribe-white h2 {
    display: none;
  }

  .category-bg {
    padding: 30px 40px;
  }

  .category-bg .image-block.even:after {
    bottom: 85%;
  }

  .category-bg .image-block:after {
    width: 25px;
    margin-top: -25px;
  }

  .category-bg .image-block:hover:after {
    height: 50px;
  }

  .category-bg .contain-block h2 {
    font-size: 18px;
    margin-top: 8px;
  }

  .category-bg .contain-block h6 {
    letter-spacing: 0.1em;
    font-size: 12px;
  }

  .category-bg .contain-block:after {
    width: 25px;
  }

  .category-bg .contain-block.even:after {
    top: 87%;
  }

  .category-bg .contain-block .category-btn {
    margin-bottom: 10px;
    margin-top: 10px;
    letter-spacing: 0.1em;
    padding: 5px 15px;
  }

  .category-bg .contain-block:after {
    bottom: 87%;
  }

  .category-bg .contain-block:hover:after {
    height: 50px;
  }

  .layout9-box .product-box .img-block {
    min-height: unset;
  }

  .product-box .cart-detail,
  .product-wrap .cart-detail {
    top: 5px;
    right: 10px;
  }

  .product-box .img-block {
    min-height: 350px;
  }

  .product-box .cart-info,
  .product-box .cart-detail {
    opacity: 1;
  }

  .product-box .img-wrapper .cart-box {
    opacity: 1;
  }

  .product-box:hover .img-wrapper .cart-box {
    -webkit-animation: none;
            animation: none;
  }

  .product-box:hover .cart-info button,
  .product-box:hover .cart-detail button {
    -webkit-animation: none;
            animation: none;
  }

  .product-box:hover .cart-info a i,
  .product-box:hover .cart-detail a i {
    -webkit-animation: none;
            animation: none;
  }

  .product-box:hover .cart-info a:nth-child(2) i,
  .product-box:hover .cart-detail a:nth-child(2) i {
    -webkit-animation: none;
            animation: none;
  }

  .product-box:hover .cart-info a:nth-child(3) i,
  .product-box:hover .cart-detail a:nth-child(3) i {
    -webkit-animation: none;
            animation: none;
  }

  .product-box:hover .cart-info a:nth-child(4) i,
  .product-box:hover .cart-detail a:nth-child(4) i {
    -webkit-animation: none;
            animation: none;
  }

  .theme-tab .tab-title2 {
    font-size: 20px;
  }

  .theme-tab .tab-title2 li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .theme-tab .tab-title2:after {
    top: 15px;
  }

  .multiple-slider>div:nth-child(-n+3) {
    margin-bottom: 30px;
  }

  .full-banner {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .full-banner.parallax-layout {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .full-banner .banner-contain h2 {
    font-size: 75px;
  }

  .collection-banner .contain-banner {
    padding-left: 60px;
    padding-right: 60px;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 20px;
  }

  .collection-banner .contain-banner.banner-3 h4 {
    font-size: 18px;
  }

  .collection-banner .contain-banner.banner-4 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 18px;
  }

  .collection-banner .contain-banner h2 {
    font-size: 35px;
  }

  .home-slider .home {
    height: 60vh;
  }

  .home-slider .slider-contain {
    height: 60vh;
  }

  .home-slider .slider-contain h1 {
    font-size: 35px;
    margin-top: 5px;
  }

  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline {
    margin-top: 10px;
    padding: 10px 25px;
  }

  .home-slider:hover .slick-next {
    right: 80px;
  }

  .home-slider:hover .slick-prev {
    left: 80px;
  }

  .background .col {
    flex-basis: unset;
  }

  .background .contain-bg {
    margin: 10px 0;
  }

  .full-box .center-slider .offer-slider {
    text-align: center;
  }

  .full-box .center-slider .offer-slider .product-box {
    border: none;
    display: inline-block;
  }

  .full-box .center-slider .offer-slider .product-box .img-wrapper {
    text-align: center;
  }

  .full-box .center-slider .offer-slider .product-box .img-wrapper img {
    height: 200px;
  }

  .full-box .center-slider .offer-slider .product-box:hover {
    box-shadow: none;
  }

  .full-box .theme-card .offer-slider .sec-1 {
    display: flex;
  }

  .full-box .theme-card .offer-slider .sec-1 .product-box2 .media .media-body .color-variant li {
    height: 18px;
    width: 18px;
    padding-right: 3px;
  }

  .full-box .theme-card .offer-slider .sec-1 .product-box2+.product-box2 {
    border-top: none;
  }

  .theme-tab .tab-title li,
  .theme-tab .tab-title2 li {
    padding-left: 5px;
    padding-right: 5px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 150px;
    padding: 5px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
    margin-bottom: 20px;
    font-size: 15px;
  }

  .filter-main-btn span {
    font-size: 16px;
  }

  .tap-top {
    bottom: 70px;
    right: 20px;
  }

  .footer-theme2 .footer-link li,
  .footer-theme2 .footer-link-b li {
    padding-left: 0;
  }

  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .product-pagination .pagination {
    justify-content: center;
    border-left: 1px solid #ddd;
  }

  .testimonial {
    background-color: #f7f7f7;
  }

  .testimonial .testimonial-slider .media .media-body {
    padding: 30px 30px 30px 30px;
  }

  .about-page {
    text-align: center;
  }

  .about-page p {
    line-height: 1.5;
  }

  .team h2 {
    font-size: 25px;
  }

  .layout2-logo {
    padding-right: 70px;
  }

  .collection .partition-collection>div:nth-last-child(1),
  .collection .partition-collection>div:nth-last-child(2),
  .collection .partition-collection>div:nth-last-child(3) {
    margin-top: 30px;
  }

  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 15px 18px;
  }

  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    justify-content: center;
    padding-right: 0;
  }

  .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
    padding: 10px 0;
  }

  .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important;
  }

  .faq-section .theme-accordion .card .card-header button {
    font-size: 14px !important;
    white-space: normal;
    width: 100%;
    padding: 16px 30px 16px 18px !important;
  }

  .register-page .theme-card {
    padding: 25px;
  }

  .register-page .theme-card .theme-form input {
    margin-bottom: 25px;
    padding: 13px 18px;
  }

  .search-product>div:nth-last-child(4) {
    margin-top: 30px;
  }

  .cart-section tbody tr td a,
  .wishlist-section tbody tr td a {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .cart-section .cart-table thead th:nth-last-child(-n+4) {
    display: none;
  }

  .cart-section tbody tr td:nth-last-child(-n+4) {
    display: none;
  }

  .cart-section tbody tr td .mobile-cart-content {
    display: flex;
  }

  .wishlist-section .cart-table thead th:nth-last-child(-n+3) {
    display: none;
  }

  .wishlist-section tbody tr td:nth-last-child(-n+3) {
    display: none;
  }

  .wishlist-section tbody tr td .mobile-cart-content {
    display: flex;
  }

  .blog-page .order-sec {
    order: -1;
  }

  .blog-page .blog-media:last-child {
    margin-bottom: 30px;
  }

  .blog-page .blog-sidebar ul li {
    display: block;
  }

  .tab-pane iframe {
    width: 100%;
  }

  .product-order .product-order-detail img {
    height: auto;
  }

  .rtl .service-block svg {
    margin-right: 0;
  }

  .rtl .service-block+.service-block {
    border-right: 0;
  }

  .rtl .service-block .media .media-body h4,
  .rtl .service-block .media .media-body p {
    text-align: center;
  }

  .rtl .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: none;
    border-left: 1px solid #ddd;
  }

  .rtl .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 5px;
    padding-right: 5px;
  }

  .rtl .footer-title.active .according-menu:before {
    right: unset;
    left: 2px;
  }

  .rtl .footer-title .according-menu:before {
    right: unset;
    left: 2px;
  }

  .rtl .footer-end p {
    text-align: center;
  }

  .rtl .sub-footer .payment-card-bottom li {
    padding-left: 7px;
    padding-right: 7px;
  }

  .rtl .footer-theme2.section-light .footer-block h4 {
    text-align: right;
  }

  .rtl .footer-theme2.section-light .footer-block .footer-logo {
    text-align: right;
  }

  .rtl .footer-theme2 .footer-title h4 {
    text-align: right;
  }

  .rtl .footer-contant li {
    text-align: right;
  }

  .rtl .footer-contant .footer-social ul li {
    padding-right: 0;
    padding-left: 25px;
  }

  .rtl .dark-layout .footer-contant .footer-logo {
    text-align: right;
  }

  .rtl .dark-layout .footer-contant p {
    text-align: right;
  }

  .rtl .footer-theme .sub-title .contact-list i {
    margin-right: 0;
    margin-left: 10px;
  }

  .rtl .footer-theme .sub-title .contact-list li {
    padding-right: 0;
  }

  .rtl .layout2-logo {
    padding-left: 70px;
    padding-right: 0;
  }

  .rtl .full-box .theme-card .offer-slider .sec-1 .product-box2+.product-box2 {
    border-left: none;
    border-right: none;
  }

  .rtl header.left-header .main-menu .menu-right .icon-nav li {
    padding-right: 15px;
    padding-left: 0;
  }

  .rtl .tools-grey .product-box .cart-info .mobile-quick-view {
    margin-left: 0;
    margin-right: 5px;
  }

  .dark .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-color: #404040;
  }

  .dark .product-pagination .pagination {
    border-color: #404040;
  }
}

@media (max-width: 577px) {
  .portfolio-section .isotopeSelector {
    margin-bottom: 15px;
  }

  .portfolio-section .filter-button {
    padding: 0 15px;
    font-size: 16px;
    line-height: 30px;
  }

  .service_slide .home-slider .slider-contain {
    height: 58vh;
  }

  .portfolio-section.metro-section .product-box .cart-wrap a i {
    font-size: 18px;
    padding: 7px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap button i {
    font-size: 18px;
    padding: 7px;
  }

  .about-section .small-section {
    padding-top: 0;
  }

  .mobile-cart img {
    display: none;
  }

  .mobile-cart-logged-in img {
    display: none;
  }

  .pet-parallax .full-banner {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .setting-sidebar {
    width: 35px;
    height: 35px;
  }

  .setting-sidebar i {
    font-size: 19px;
  }

  .dark-light {
    width: 35px;
    height: 35px;
  }

  #fb-root {
    display: none;
  }

  .section-b-space {
    padding-bottom: 30px;
  }

  .section-t-space {
    padding-top: 30px;
  }

  header.header-6 {
    height: 60px;
  }

  header.header-6 .main-menu .brand-logo {
    padding: 0 !important;
    top: 16px;
    left: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  header.header-6 .main-nav-center .toggle-nav {
    top: -5px;
    padding: 0;
  }

  header.header-6.sticky.fixed .toggle-nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    font-size: 24px;
  }

  .dashboard-right .dashboard {
    padding: 20px;
  }

  .dashboard-right .dashboard .page-title h2 {
    font-size: 18px;
  }

  .dashboard .box-head h2 {
    font-size: 18px;
  }

  .top-header .header-dropdown li i {
    padding-right: 0;
  }

  .layout3-menu .main-menu .menu-left {
    width: calc(100vw - 32px);
  }

  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: 15px;
  }

  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    display: none;
  }

  .layout3-menu .main-menu>div:nth-child(2) {
    position: absolute;
    left: 54%;
    right: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: 0;
  }

  .layout3-menu .main-menu>div:nth-child(3) {
    height: 0;
  }

  .layout3-menu .brand-logo {
    left: 35%;
  }

  .search-overlay>div .closebtn {
    top: 15px;
    right: 30px;
  }

  .btn-solid {
    padding: 7px 15px;
  }

  .product-box .product-detail .color-variant,
  .product-box .product-info .color-variant,
  .product-wrap .product-detail .color-variant,
  .product-wrap .product-info .color-variant {
    padding-top: 10px;
  }

  footer {
    margin-bottom: 60px;
  }

  footer .btn-solid {
    padding: 13px 29px;
  }

  footer p {
    line-height: 20px;
  }

  .video-section .video-img .play-btn span {
    height: 40px;
    width: 40px;
  }

  .video-section .video-img .play-btn i {
    font-size: 20px;
  }

  .partition1 {
    margin-bottom: -15px;
  }

  .partition1>div {
    margin-bottom: 15px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right {
    margin: 15px 0 10px 0;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right .color-variant li {
    height: 25px;
    width: 25px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right .border-product {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right .size-box {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right .product-description .qty-box {
    margin-top: 5px;
  }

  .dark-layout .footer-theme2 .col {
    padding: 0 15px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block {
    padding: 10px 20px 20px 10px;
  }

  .white-layout .small-section .footer-theme2 .col {
    padding: 0 15px;
  }

  .footer-theme2 .col {
    padding: 0 15px;
  }

  .footer-theme2 .form-p {
    padding: 0;
  }

  .footer-theme2.footer-border {
    padding: 15px 0;
  }

  .footer-theme2 .subscribe-block {
    padding: 0 75px;
  }

  .footer-theme2 .footer-link h4 {
    padding-bottom: 10px;
  }

  .footer-theme2 .footer-link li {
    padding-right: 20px;
  }

  .footer-theme2 .footer-link-b h4 {
    padding-bottom: 15px;
  }

  .footer-theme2 .footer-link-b li {
    padding-right: 20px;
  }

  .social-white {
    margin-top: 25px;
  }

  .category-border div .category-banner .category-box h2 {
    font-size: 30px;
    padding: 10px 20px;
  }

  .filter-main-btn span {
    font-size: 14px;
  }

  .category-bg .image-block:after {
    display: none;
  }

  .category-bg .contain-block:after {
    display: none;
  }

  .category-bg .order-section div {
    order: 1;
  }

  .category-bg .order-section div:nth-child(2) {
    order: 4;
    margin-bottom: 30px;
  }

  .category-bg .order-section div:nth-child(3) {
    order: 3;
  }

  .category-bg .order-section div:nth-child(4) {
    order: 2;
    margin-bottom: 30px;
  }

  .category-bg .order-section div:nth-child(5) {
    order: 5;
  }

  .category-bg .order-section div:nth-child(6) {
    order: 6;
  }

  .category-bg .contain-block {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .theme-tab .tab-title {
    margin-bottom: 25px;
  }

  .full-banner {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .full-banner.parallax-layout {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .full-banner .banner-contain {
    background-color: rgba(255,255,255,0.2);
    padding: 25px;
  }

  .full-banner .banner-contain .btn-solid,
  .full-banner .banner-contain .btn-outline {
    padding: 10px 20px;
  }

  .full-banner.p-left .banner-contain,
  .full-banner.p-right .banner-contain {
    float: none;
  }

  .full-banner .banner-contain h2 {
    font-size: 65px;
  }

  .full-banner .banner-contain h3 {
    font-size: 40px;
  }

  .full-banner .banner-contain h4 {
    font-size: 18px;
    padding-top: 0;
    letter-spacing: 0.3em;
  }

  .home-slider .home {
    height: 58vh;
  }

  .home-slider .slider-contain {
    height: 58vh;
  }

  .home-slider .slider-contain h1 {
    font-size: 30px;
  }

  .home-slider:hover .slick-next {
    right: 70px;
  }

  .home-slider:hover .slick-prev {
    left: 70px;
  }

  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  .home-slider.fullpage .slick-dots {
    display: flex;
    bottom: 60px;
    top: unset;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .home-slider.fullpage .home .slider-contain {
    text-align: center;
    justify-content: center;
    padding-left: 0;
  }

  .home-slider.fullpage .home .slider-contain>div {
    background-color: rgba(0,0,0,0.15);
    padding: 30px 50px;
  }

  .home-slider.fullpage .home .slider-contain h1,
  .home-slider.fullpage .home .slider-contain p {
    color: #fff;
  }

  .home-slider.fullpage .home .slider-contain h1 {
    font-size: 22px;
  }

  .home-slider.fullpage .home .slider-contain p {
    max-width: 330px;
    font-size: 12px;
  }

  .home-slider.fullpage .home .slider-contain .btn-solid {
    padding: 10px 15px;
  }

  .background .contain-bg {
    background-color: unset;
    padding-top: 0;
    padding-bottom: 0;
    margin: 10px 0;
  }

  .background .contain-bg:hover {
    background-color: unset;
  }

  .theme-tab .tab-content .product-tab .tab-box {
    flex: 0 0 100%;
    max-width: calc(100% - 10px);
  }

  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+2) {
    margin: 0 5px 10px;
  }

  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+1) {
    margin: 0 5px 0;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 150px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    margin-right: 10px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    margin-top: 7px;
    height: 18px;
    width: 18px;
  }

  .alert {
    right: 0 !important;
    left: 0;
    max-width: 90% !important;
  }

  .tap-top {
    width: 35px;
    height: 35px;
    padding: 2px;
    font-size: 19px;
  }

  .layout2-logo {
    padding-right: 0;
  }

  .absolute-banner {
    margin-top: 0;
  }

  .absolute-banner .absolute-bg {
    box-shadow: none;
    padding-bottom: 0;
  }

  .absolute-banner.banner-padding {
    padding-top: 0;
  }

  .box-layout-body .box-layout {
    margin-top: 0;
  }

  .full-scroll-menu .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout-8 {
    padding: 0 15px;
  }

  .layout-20 {
    padding: 0;
  }

  .addcart_btm_popup {
    width: 30px;
    height: 32px;
  }

  .addcart_btm_popup .fixed_cart {
    font-size: 14px;
  }

  .color-picker a.handle {
    width: 30px;
    height: 35px;
  }

  .color-picker a.handle i {
    margin-left: 7px;
    font-size: 17px;
  }

  .full-scroll-footer {
    display: none;
  }

  .error-section {
    padding: 100px 0;
  }

  .error-section h1 {
    font-size: 100px;
  }

  .error-section h2 {
    margin: 20px 0;
    font-size: 18px;
  }

  .about-page .about-head {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .about-page .about-head .theme-breadcrumb .breadcrumb {
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 0;
  }

  .breadcrumb-section .page-title {
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    display: unset;
  }

  .breadcrumb-section .breadcrumb {
    justify-content: center;
    padding: 0;
    line-height: 30px;
  }

  .blog-detail-page .blog-detail .post-social li {
    display: block;
  }

  .blog-detail-page .blog-detail .post-social li+li {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  .collection .collection-block .collection-content p {
    margin-bottom: 10px;
  }

  .collection .collection-block .collection-content h4,
  .collection .collection-block .collection-content h3 {
    margin-bottom: 5px;
  }

  .collection-product-wrapper .product-wrapper-grid .product-box {
    margin-top: 30px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    padding: 10px 20px 10px 20px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
    font-size: 14px;
    line-height: 20px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 100%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    padding: 10px 20px 10px 20px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
    top: 11px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-right: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    border-right: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
    top: 14px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 100%;
    border-top: none;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    left: unset;
    right: 35px !important;
    top: 14px;
  }

  .search-product>div:nth-last-child(5) {
    margin-top: 30px;
  }

  .cart-section .cart-buttons>div:last-child {
    padding-right: 15px;
  }

  .cart-section .cart-buttons .btn-solid {
    padding: 7px 8px;
  }

  .wishlist-section .btn-solid {
    padding: 7px 6px;
  }

  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 12px;
    padding: 13px 8px;
  }

  .product-box .img-block .lable-wrapper .lable1,
  .product-box .img-block .lable-wrapper .lable2 {
    padding: 6px 9px 8px 15px;
    font-size: 12px;
  }

  .tab-product .tab-content.nav-material p,
  .product-full-tab .tab-content.nav-material p {
    padding: 15px 0;
  }

  .tab-product .tab-content.nav-material p {
    line-height: 1.6;
  }

  .slider-right-nav .slick-slide:first-child>div {
    margin-top: 15px;
  }

  .collection-wrapper .order-up {
    order: -1;
  }

  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 90px;
    top: -70px;
  }

  .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    line-height: 16px;
  }

  .layout7-product .product-box .details-product {
    padding: 5px;
  }

  .layout7-product .product-box:hover .details-product {
    -webkit-animation: none;
            animation: none;
  }

  .checkout-page .checkout-form .checkout-details {
    padding: 15px;
  }

  .padding-cls {
    padding-left: 15px;
    padding-right: 15px;
  }

  .success-text i {
    font-size: 40px;
  }

  .success-text p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  .success-text h2 {
    margin-bottom: 10px;
  }

  .order-success-sec>div {
    margin-top: 15px;
  }

  .delivery-sec {
    padding: 15px;
    margin-top: 15px;
  }

  .delivery-sec h3,
  .delivery-sec h2 {
    font-size: 20px;
  }

  .product-order h3 {
    font-size: 20px;
  }

  .product-order .product-order-detail .order_detail h4,
  .product-order .product-order-detail .order_detail h5 {
    font-size: 14px;
    line-height: 1.2;
  }

  .product-order .total-sec ul li {
    font-size: 16px;
  }

  .product-order .final-total h3 {
    font-size: 16px;
  }

  .beauty-about .service .service-block1 {
    margin-bottom: 20px;
  }

  .beauty-about .service .service-block1:last-child {
    margin-bottom: 0;
  }

  .main-menu .menu-left .navbar i {
    font-size: 24px;
  }

  .rtl .breadcrumb-section .page-title h2 {
    text-align: center;
  }

  .rtl .blog-detail-page .blog-detail .post-social li+li {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #ddd;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    padding: 10px 34px 10px 34px;
  }

  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-left: none;
  }

  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
    left: 25px;
  }

  .rtl .home-slider .slick-next,
  .rtl .center-slider .slick-next {
    right: unset;
    left: 1px;
  }

  .rtl .home-slider .slick-prev,
  .rtl .center-slider .slick-prev {
    left: unset;
    right: 1px;
  }

  .rtl .home-slider:hover .slick-next,
  .rtl .center-slider:hover .slick-next {
    right: unset;
    left: 30px;
  }

  .rtl .home-slider:hover .slick-prev,
  .rtl .center-slider:hover .slick-prev {
    left: unset;
    right: 30px;
  }

  .rtl .layout2-logo {
    padding-left: 0;
  }

  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important;
  }

  .rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
    right: unset;
    left: 0 !important;
  }

  .rtl .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    left: 15px;
  }

  .rtl .layout3-menu .brand-logo {
    -webkit-transform: translateX(-15%);
            transform: translateX(-15%);
  }

  .rtl .top-header .header-dropdown li i {
    padding-left: 0;
  }

  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div {
    right: unset;
    left: 0;
  }

  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-color: #404040 !important;
  }

  .dark .tools-service.absolute-banner .absolute-bg {
    box-shadow: none;
  }

  .dark .full-banner .banner-contain {
    background-color: rgba(0,0,0,0.2);
  }
}

@media (min-width: 577px) {
  .rtl .onhover-dropdown:before {
    right: unset;
    left: 3px;
  }

  .rtl .onhover-dropdown .onhover-show-div {
    right: unset;
    left: 0;
  }

  .rtl .main-menu .menu-left .navbar {
    padding: 40px 0 40px 45px;
  }

  .rtl .main-menu .category-nav-right .navbar {
    padding: 40px 45px 40px 0;
  }

  .rtl .main-menu .menu-right .icon-nav li {
    padding-left: 0;
    padding-right: 20px;
  }

  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart,
  .rtl .main-menu .menu-right .onhover-div .show-div.setting {
    left: 0;
    right: unset;
  }

  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart li .close-circle {
    right: unset;
    left: 0;
  }

  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart li .total h5 span {
    float: left;
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 90vw;
    margin: 0 auto;
  }

  .pet-parallax .full-banner {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .quick-view-modal .product-buttons .btn {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px;
  }

  .title1 .title-inner1 {
    margin-bottom: 20px;
  }

  .title2 .title-inner2 {
    margin-bottom: 20px;
  }

  .title3 .line {
    margin-bottom: 20px;
  }

  .title4 .line {
    margin-bottom: 20px;
  }

  .title-borderless {
    margin-bottom: 20px;
  }

  .subscribe-form.classic-form .form-control {
    margin-left: 0;
  }

  .product-box .img-block {
    min-height: 290px;
  }

  .theme-tab .tab-title li {
    padding-left: 5px;
    padding-right: 5px;
  }

  .theme-tab .tab-title2 {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
  }

  .theme-tab .tab-title2 li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .theme-tab .tab-title2:after {
    display: none;
  }

  .blog-details {
    padding-left: 0;
    padding-right: 0;
  }

  .full-banner {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .full-banner.parallax-layout {
    padding-top: 4px;
    padding-bottom: 40px;
  }

  .full-banner .banner-contain h2 {
    font-size: 60px;
  }

  .full-banner .banner-contain h4 {
    font-size: 18px;
  }

  .collection-banner .contain-banner {
    padding-left: 30px;
    padding-right: 30px;
  }

  .collection-banner .contain-banner h2 {
    font-size: 24px;
  }

  .home-slider .home {
    height: 55vh;
  }

  .home-slider .slider-contain {
    height: 55vh;
  }

  .home-slider .slider-contain h1 {
    font-size: 28px;
  }

  .home-slider .slider-contain h4 {
    letter-spacing: 0.1em;
  }

  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline {
    margin-top: 5px;
  }

  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .home-slider:hover .slick-next {
    right: 60px;
  }

  .home-slider:hover .slick-prev {
    left: 60px;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 448px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 30px 30px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white .form-control {
    letter-spacing: 3px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 35px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
    margin-bottom: 10px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    margin-bottom: 15px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
    height: 40px;
    padding: 0;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .btn-solid,
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .btn-outline {
    padding: 8px 15px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
    font-size: 15px;
  }

  .tab-bg,
  .bg-block {
    padding: 35px 25px 30px 25px;
  }

  .about-page p {
    line-height: 1.5;
  }

  .testimonial .testimonial-slider .media {
    display: inline-block;
  }

  .testimonial .testimonial-slider .media img {
    margin: 0 auto;
    height: 100px;
    width: 100px;
  }

  .testimonial .testimonial-slider .media .media-body {
    padding: 0;
    text-align: center;
  }

  .blog-detail-page .blog-contact h2 {
    font-size: 30px;
    margin-bottom: 25px;
  }

  .blog-detail-page .blog-contact .theme-form input,
  .blog-detail-page .blog-contact .theme-form textarea {
    padding: 12px 20px;
    margin-bottom: 20px;
  }

  .blog-detail-page .blog-detail h3 {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .blog-detail-page .blog-detail img {
    margin-bottom: 25px;
  }

  .blog-detail-page .blog-advance ul {
    line-height: 1.5;
    font-size: 14px;
  }

  .blog-detail-page .blog-advance img {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .blog-detail-page .blog-advance p {
    line-height: 1.5;
  }

  .blog-detail-page .comment-section li {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog-detail-page .comment-section li p {
    line-height: 1.5;
  }

  .blog-detail-page .comment-section li h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .blog-detail-page .comment-section li h6 span {
    display: inherit;
    margin-left: 0;
  }

  .blog-detail-page .comment-section .media {
    display: inline-block;
    text-align: center;
  }

  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important;
  }

  .faq-section .theme-accordion .card .card-header button {
    line-height: 1.3;
    padding: 12px 35px 12px 12px !important;
  }

  .faq-section .accordion.theme-accordion .card .card-header button:before {
    top: 20px;
  }

  .pwd-page {
    padding: 70px 0;
  }

  .pwd-page h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .login-page .authentication-right h6 {
    margin-bottom: 10px;
  }

  .login-page .theme-card {
    padding: 25px;
  }

  .register-page .theme-card {
    padding: 18px;
  }

  .register-page .theme-card .theme-form input {
    margin-bottom: 20px;
    padding: 12px 15px;
  }

  .search-block .btn-solid {
    padding: 10px 15px;
  }

  .contact-page .map iframe {
    height: 275px;
  }

  .contact-page .theme-form input {
    padding: 12px 15px;
    margin-bottom: 18px;
  }

  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 0;
  }

  .blog-page .blog-media .blog-right p {
    line-height: 1.3;
  }

  .blog-page .blog-media .blog-right h4 {
    margin-bottom: 5px;
  }

  .blog-page .blog-media .blog-right ul {
    margin-bottom: 5px;
  }

  .blog-page .blog-media .blog-right ul li {
    display: block;
  }

  .blog-page .blog-media .blog-right ul li+li {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  .account-sidebar {
    width: 45%;
  }

  .success-text h2 {
    font-size: 20px;
  }

  .success-text p {
    font-size: 14px;
  }

  .delivery-sec h3,
  .delivery-sec h2 {
    font-size: 18px;
  }

  .rtl .theme-tab .tab-title2 {
    -webkit-padding-start: 0;
  }

  .rtl .blog-page .blog-media .blog-right ul li+li {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }

  .rtl .blog-detail-page .comment-section li p,
  .rtl .blog-detail-page .comment-section li h6 {
    text-align: center;
  }

  .rtl .product-right .product-icon .product-social li {
    padding-left: 16px;
  }

  .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link {
    text-align: right;
  }

  .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link,
  .rtl .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    padding: 15px;
  }

  .rtl .testimonial .testimonial-slider .media .media-body p {
    text-align: center;
  }
}

@media (max-width: 420px) {
  h2 {
    font-size: 22px;
  }

  .subscribe h4 {
    display: block;
  }

  footer .btn-solid {
    padding: 7px 8px;
  }

  footer p {
    line-height: 25px;
  }

  .blog-details p {
    font-size: 14px;
    line-height: 1.3;
  }

  .product-box .img-wrapper .cart-box i,
  .product-wrap .img-wrapper .cart-box i {
    font-size: 16px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .addtocart_count .full-box .center-slider .offer-slider .product-box .cart-info {
    display: none;
  }

  .addtocart_count .full-box .center-slider .offer-slider .product-box .addtocart_btn {
    display: none;
  }

  .addtocart_count .full-box .center-slider .offer-slider .product-box .product-detail {
    display: flex;
    align-items: center;
  }

  .timer span .padding-l {
    padding-left: 5px;
  }

  .about-text p {
    line-height: 22px;
  }

  .footer-theme .sub-title h4 {
    margin-bottom: 5px;
  }

  .footer-theme .sub-title .contact-list li {
    line-height: 25px;
  }

  .footer-theme2 p {
    line-height: 25px;
  }

  .breadcrumb-section {
    padding: 15px 0;
  }

  .footer-light .subscribe-form .form-control {
    width: 280px;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 388px;
  }

  .white-layout .footer-theme2 .footer-block .subscribe-white .btn-solid,
  .white-layout .footer-theme2 .footer-block .subscribe-white .btn-outline {
    padding: 13px 8px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .btn-solid,
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .btn-outline {
    padding: 13px 8px;
  }

  .footer-theme2 .subscribe-block .form-control {
    letter-spacing: 3px;
  }

  .subscribe-form {
    display: inline-block;
  }

  .subscribe-form .form-group {
    margin-bottom: 10px;
  }

  .category-border div .category-banner .category-box h2 {
    font-size: 25px;
  }

  .no-slider .product-box {
    flex: 0 0 100%;
    max-width: calc(100% - 30px);
    margin: 0 15px 30px !important;
  }

  .no-slider .product-box:nth-last-child(1) {
    margin: 0 15px 0 !important;
  }

  .no-slider .product-box:nth-last-child(2) {
    margin: 0 15px 30px !important;
  }

  .full-banner .banner-contain h2 {
    font-size: 55px;
  }

  .full-banner .banner-contain h3 {
    font-size: 36px;
  }

  .home-slider .slider-contain h1 {
    font-size: 25px;
    margin-top: 10px;
  }

  .home-slider .slider-contain h4 {
    letter-spacing: 0.01em;
  }

  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline {
    margin-top: 10px;
    padding: 5px 18px;
  }

  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  .home-slider:hover .slick-next {
    right: 50px;
  }

  .home-slider:hover .slick-prev {
    left: 50px;
  }

  .theme-card.center-align {
    display: unset;
    height: unset;
    align-items: unset;
  }

  .theme-card.card-border .offer-slider img {
    padding: 0 15px 0 15px;
  }

  .full-box .center-slider .offer-slider .product-box {
    display: flex;
    border-bottom: 1px solid #f1f5f4;
    border-top: 1px solid #f1f5f4;
  }

  .full-box .center-slider .offer-slider .product-box .img-wrapper img {
    height: 120px;
  }

  .full-box .center-slider .offer-slider .product-box .product-info {
    text-align: left;
  }

  .full-box .center-slider .offer-slider .product-box .product-info h6 {
    padding-top: 5px;
  }

  .full-box .center-slider .offer-slider .product-box .product-info .color-variant {
    padding-top: 2px;
  }

  .full-box .center-slider .offer-slider .product-box .product-info .color-variant li {
    height: 15px;
    width: 15px;
    padding-right: 3px;
  }

  .full-box .center-slider .offer-slider .product-box .product-detail {
    padding-left: 0;
  }

  .full-box .center-slider .offer-slider .product-box .product-detail h4 {
    font-size: 20px;
  }

  .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
    padding-top: 0;
  }

  .full-box .center-slider .offer-slider .product-box:hover .product-info .btn-outline {
    display: none;
  }

  .full-box .center-slider .offer-slider .product-box:hover .product-info .color-variant {
    opacity: 1;
  }

  .full-box .theme-card .offer-slider .sec-1 {
    display: inline;
  }

  .full-box .theme-card .offer-slider .sec-1 .product-box2 {
    border-bottom: 1px solid #f1f5f4;
  }

  .full-box .theme-card .offer-slider .sec-1 .product-box2:last-child {
    border-bottom: none;
  }

  .full-box .theme-card .offer-slider .sec-1 .product-box2+.product-box2 {
    border-left: none;
  }

  .center-slider .offer-slider .product-box .product-detail {
    text-align: left !important;
  }

  .center-slider .offer-slider .product-box .product-detail .rating i {
    padding-right: 3px;
  }

  .center-slider .offer-slider .product-box .product-detail h4 {
    padding-bottom: 0.5rem;
  }

  .center-slider .offer-slider .product-box .product-detail .color-variant li {
    height: 18px;
    width: 18px;
    padding-right: 3px;
  }

  .center-slider .offer-slider .product-box .img-wrapper .cart-box {
    display: none;
  }

  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 13px;
    padding: 15px 10px;
  }

  .product-box .img-block .lable-wrapper .lable1,
  .product-box .img-block .lable-wrapper .lable2 {
    padding: 10px 14px 10px 20px;
    font-size: 12px;
  }

  .team h6 {
    line-height: 15px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 30px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    font-size: 30px;
  }

  .login-page .authentication-right p {
    line-height: 1.5;
  }

  .login-page .theme-card .theme-form input {
    padding: 15px 20px;
    margin-bottom: 25px;
  }

  .tab-product .nav-material.nav-tabs {
    display: block;
  }

  .tab-product .nav-material.nav-tabs .nav-item {
    width: 100%;
  }

  .tab-product .nav-material.nav-tabs .nav-item .nav-link {
    padding: 10px 20px;
  }

  .vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
  .vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    padding: 10px 20px;
  }

  .product-right .product-buttons .btn-solid,
  .product-right .product-buttons .btn-outline {
    padding: 7px 13px;
  }

  .product-right.product-form-box .timer {
    padding-left: 29px;
  }

  .product-right.product-form-box .timer span {
    width: 45px;
  }

  .product-right .timer {
    padding-left: 35px;
  }

  .product-right .timer span {
    width: 45px;
  }

  .product-right .product-icon .product-social {
    margin-top: 0;
  }

  .product-right .product-icon .product-social li {
    padding-right: 5px;
  }

  .product-right .product-icon .product-social li a i {
    font-size: 14px;
  }

  .product-right .product-icon .wishlist-btn i {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 5px;
  }

  .product-right .product-icon .wishlist-btn span {
    font-size: 14px;
  }

  .tab-product .theme-form input,
  .tab-product .theme-form textarea {
    font-size: 12px;
    padding: 8px 15px;
  }

  .order-box .sub-total .shipping {
    width: unset;
    float: unset;
    display: flex;
  }

  .order-box .sub-total .shipping .shopping-option:last-child {
    padding-left: 20px;
  }

  .checkout-page .checkout-form .form-group {
    margin-bottom: 15px;
  }

  .checkout-page .checkout-form input[type="text"],
  .checkout-page .checkout-form input[type="email"],
  .checkout-page .checkout-form input[type="password"],
  .checkout-page .checkout-form input[type="tel"],
  .checkout-page .checkout-form input[type="number"],
  .checkout-page .checkout-form input[type="url"] {
    height: 40px;
  }

  .checkout-page .checkout-form select,
  .checkout-page .checkout-form textarea {
    height: 40px;
  }

  .rtl .full-box .center-slider .offer-slider .product-box .product-detail .rating,
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail h4,
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail h6,
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
    text-align: right;
  }

  .rtl .order-box .sub-total .shopping-option label {
    padding-right: 0;
  }
}

@media (max-width: 360px) {
  .btn-solid,
  .btn-outline {
    padding: 10px 15px;
  }

  .pet-parallax .full-banner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  h2 {
    font-size: 25px;
  }

  .category-button .col {
    flex-basis: unset;
  }

  .footer-theme2 .subscribe-block {
    padding: 0 30px;
  }

  .white-layout .small-section .footer-theme2 .col {
    padding: 0 15px;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 330px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 20px 20px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .form-control {
    border: none;
  }

  .footer-end p {
    font-size: 13px;
  }

  .category-border div .category-banner .category-box h2 {
    padding: 7px 15px;
  }

  .full-banner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .full-banner.parallax-layout {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .full-banner .banner-contain h2 {
    font-size: 55px;
  }

  .full-banner .banner-contain h3 {
    font-size: 30px;
  }

  .home-slider .home {
    height: 50vh;
  }

  .home-slider .slider-contain {
    height: 50vh;
  }

  .home-slider .slider-contain h1 {
    font-size: 25px;
  }

  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline {
    margin-top: 8px;
    padding: 5px 15px;
  }

  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .home-slider:hover .slick-next {
    right: 40px;
  }

  .home-slider:hover .slick-prev {
    left: 40px;
  }

  .layout-7 .home .slider-contain {
    height: 50vh;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    font-size: 13px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    height: 12px;
    width: 12px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 115px;
  }

  .blog-detail-page .blog-detail p {
    line-height: 1.5;
  }

  .blog-detail-page .blog-contact h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .about-page p {
    line-height: 1.2;
  }

  .theme-modal .modal-dialog .modal-content .modal-body {
    padding: 10px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 20px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
    right: 10px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 15px 13px;
  }

  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 10px;
  }

  .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
    line-height: 15px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 80%;
  }

  .login-page .theme-card {
    padding: 18px;
  }

  .search-block .btn-solid {
    padding: 10px 5px;
  }

  .search-block .form-header .input-group input {
    padding: 10px 12px;
  }

  .contact-page .map iframe {
    height: 200px;
  }

  .cart-section tbody tr td,
  .wishlist-section tbody tr td {
    min-width: 115px;
  }

  .cart-section tbody tr td .mobile-cart-content .col-xs-3,
  .wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .cart-section tbody tr td a img,
  .wishlist-section tbody tr td a img {
    height: 80px;
  }

  .cart-section tfoot tr td,
  .wishlist-section tfoot tr td {
    padding-right: 27px;
  }

  .cart-section tfoot tr td h2,
  .wishlist-section tfoot tr td h2 {
    font-size: 21px;
  }

  .wishlist-section .wishlist-buttons:last-child a {
    margin-left: 3PX;
  }

  .product-buttons .btn-solid,
  .product-buttons .btn-outline {
    padding: 7px 16px;
  }

  .product-description .qty-box .input-group {
    width: 100%;
  }

  .single-product-tables.detail-section table {
    width: 70%;
  }

  .product-right .timer {
    padding-left: 25px;
  }

  .product-right .timer span .padding-l {
    padding-left: 10px;
  }

  .product-form-box .timer span {
    width: 47px;
  }

  .product-form-box .timer span .padding-l {
    padding-left: 9px;
  }

  .product-form-box .product-buttons .btn-solid,
  .product-form-box .product-buttons .btn-outline {
    padding: 5px 5px;
  }

  .product-form-box .timer span,
  .border-product .timer span {
    width: 45px;
  }

  .product-form-box .timer span .padding-l,
  .border-product .timer span .padding-l {
    padding-right: 5px;
  }

  .rtl .product-right .product-icon .product-social li {
    padding-left: 7px;
  }

  .rtl .banner-timer .timer {
    padding-left: 0;
    padding-right: 20px;
  }

  .rtl .banner-timer .timer span {
    width: 50px;
  }
}

@media (max-width: 320px) {
  .footer-theme .footer-logo {
    margin-bottom: 20px;
  }

  .footer-theme2 .col {
    padding: 10px 5px;
  }

  .white-layout .footer-theme2 .col {
    padding: 0 15px;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 290px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .form-control {
    letter-spacing: 1px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white .form-control {
    letter-spacing: 1px;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 15px;
  }

  .collection-banner .contain-banner.banner-4 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 15px;
  }
}

.col-cat-home,
.col-cat-home-2 {
  flex: 0 0 14.2857%;
  max-width: 14.2857%;
}

@media (max-width: 575px) {
  .row.home-main-category {
    justify-content: center;
    margin-left: -3px;
    margin-right: -3px;
  }
}

@media (max-width: 991px) {
  .col-cat-home {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 3px;
    padding-right: 3px;
  }

  .col-cat-home.col-cat-home-2 {
    flex: 0 0 33%;
    max-width: 33%;
  }
}

.background {
  background: #309345;
}

.button-text-color {
  color: #fff;
}

h2 {
  color: green;
}

.onhover-dropdown .onhover-show-div {
  top: 50px;
  position: absolute;
  z-index: 15;
  background-color: #309345;
  transition: all linear 0.3s;
  width: 160px;
  text-align: left;
  box-shadow: 0 0 1px 0 #ebebeb;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  border-radius: 0 !important;
  padding: 0;
}

.onhover-dropdown:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: -15px;
}

.onhover-dropdown .onhover-show-div a:hover {
  background-color: #ffffff;
  border: 0.5px solid #309345;
}

.onhover-dropdown .onhover-show-div a {
  display: block;
  padding: 0.5rem 1rem;
  border: 0.5px solid white;
  width: 160px;
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #ffffff;
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li a:hover {
  color: #309345;
}

.cat-onhover-dropdown {
  position: relative;
  font-size: 14px;
}

.cat-onhover-dropdown:before {
  font-family: FontAwesome;
  position: absolute;
  right: 3px;
}

.cat-onhover-dropdown .onhover-show-div {
  top: 75px;
  position: absolute;
  z-index: 10;
  background-color: #309345;
  transition: all linear 0.3s;
  width: 230px;
  text-align: left;
  box-shadow: 0 0 1px 0 #ebebeb;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 20px;
  border-radius: 0 !important;
}

.cat-onhover-dropdown .onhover-show-div.product-page-full {
  z-index: 99999;
}

.cat-onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
}

.cat-onhover-dropdown .onhover-show-div a:hover {
  background-color: #ffffff;
  color: #309345;
  border: 0.5px solid #309345;
}

.cat-onhover-dropdown .onhover-show-div a {
  text-transform: uppercase;
  display: block;
  padding: 0.5rem 1rem;
  color: #ffffff;
  width: 230px;
  border: 0.5px solid white;
  font-size: 14px;
}

.login-text {
  color: #999;
  padding: 15px 0px 15px 25px;
  font-size: 14px;
}

.login-text a:hover {
  color: #309345 !important;
}

